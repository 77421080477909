<template>
  <div class="infoShow">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="infoShowTitle">HOW TO USE</p>
    </div>
    <div class="contentWrap how_to_use">
      <div class="list">
        <div><a href="#A">How to submit request?</a></div>
        <div><a href="#B">How to group addresses in PC mode?</a></div>
        <div><a href="#C">How to use it by driver?</a></div>
      </div>
      <div class="content">
        <div class="detail" id="A">
          <p class="detail_p">How to submit request</p>
          <div class="center_detail">
            <p class="p1">A. Submit requests in mobile phone, in simple mode</p>
            <p class="p2">1. Click 'Submit delivery request' at home page</p>
            <p class="p2">2. Add pick up address</p>
            (1): "Add a stop"<br>
            (2) In 'Pickup' window, fill "Address", & Phone# in 'Contact'<br>
            (3) If any comment, fill 'Comment<br>
            (4) You can specify all the packages that the driver needs to pick. One package per row. <br>
            (5) Click 'Add'<br>
            <p class="p2">3. Add drop addresses</p>
            (1) Click: Add a stop<br>
            (2) Fill "Address", and contact cell phone number <br>
            (3) Add comment if any, like packing info or other reminder<br>
            (4) Add package info for this drop, like package number etc.<br>
            <p class="p2">4. Fill all delivery address.</p>
            <p class="p2">5. Click 'Optimize route'</p>
            <p class="p2">6. New route link will be generated. </p>
            Click 'Item summary', and it will show you all the items for this route.<br>
            <p class="p2">7. Send the link to your driver.</p>
            Ask your driver open this link in cell phone browser. Then follow the following on how to deliver<br>
            Reminer: Keep this link confidential, and do not open to public.<br>
            <p class="p1">B. Import excel file for request in PC mode.</p>
            1. Prepare an excel file, with column names as "address", "room#", "phone", "comment", "package list".<br>
            The 1st address will be considered as pickup address.<br>
            Check here xxxxxxxxxxxx as an example.<br>
            2. At home page, click "Import & group & pay" button, to professinal import page.<br>
            3. "Upload file" button for uploading excel file. <br>
            4. You can add new address by clicking "Add stop" button.<br>
            5. Click "Set as pickup" if you want to set one address as pickup address.<br>
            6. If you just need one driver, click "Optimize all in one route" button to generate route url.<br>
            If you need more than one driver, then you need to group addresses for drivers<br>
          </div>
        </div>
        <div class="detail" id="B">
          <p class="detail_p">How to group addresses in PC mode.</p>
          <div class="center_detail">
            <p class="p1">1. In professonal mode page, click "Group manually"</p>
            <p class="p1">2. Click "add group" to add new groups. One group per driver.</p>
            <p class="p1">3. Click "Enable set-group mode" in top right corner of map</p>
            <p class="p1">Allocate addresses to one group:</p>
            (1) Click one target group id in the group list. The selected group will change background color to be light blue.<br>
            (2) Now click on all the addresses that are allocated for this group.<br>
            (3) If finishing one group, click on another group id to work on it.<br>
            (4) If finishing grouping, click on "Disable set-group mode"<br>
            <p class="p1">4. Click on "Save" button</p>
            <p class="p1">5. To get route link of each group, click on its 'Route' button.</p>
            "Summary" button is to show the summary of packages for this group.
            <p class="p1">6. Send the route link to assigned driver. Driver needs to open the link in cell phone browser.</p>

            Reminder: "Save" when reminded.<br>
            "Overall item summary" button: show the stats of all groups/drivers.<br>
          </div>
        </div>
        <div class="detail" id="C">
          <p class="detail_p">How to use it by driver</p>
          <div class="center_detail">
            <p class="p1">1. Open the route link in browser of cell phone.</p>
            <p class="p1">2. If the order of stops look not right, click the "..." button on the right corner below the map, then "Re-optimize route".</p>
            <p class="p1">3. "Item summary" button: to check all the items for all drop addresses.</p>
            "Set end location" button: set the home address, then re-route.<br>
            <p class="p1">4. Pick up packages for all addresses:</p>
            (1) Click on each drop address. It will show package information. Make sure you pick the right packages from pick up address, and click on "Picked?" button once you have picked that package. This address will have a "P" mark in the right order.<br>
            (2) Click on other drop addresses and pick and mark all packages.<br>
            <p class="p1">5. Deliver</p>
            Deliver addresses are ordered already. Finish one by one.<br>
            (1) On each address, click "Go" to route. <br>
            It will ask you if you want to send SMS to recipient. <br>
            Click "Call" & "Text", to call & SMS to the customer, if cell phone number is provided.<br>
            (2) When arriving at drop location, drop every specified package and click 'Dropped?' to mark it as dropped.<br>
            When all packages dropped, the "Final step of delivery" window will show up. You can also get this by clicking "Done" button.<br>
            Take picture and upload it. Wait until uploading is completed.<br>
            Fill comment if any.<br>
            Click "Done"<br>
            Then this address will be marked as completed, and its background will become grey.<br>
            (3) If for some reason, you can not deliver, click "Fail", and specify the reason. <br>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'aboutUs',
    components: {},
    data() {
      return {
        infoTitle:"",
        userType:"",
        infoContent:"sss",
        infoUrl:""
      }
    },
    mounted() {
      this.userType = this.$route.query.type;
      let info = this.$route.query.info;
      if(info == "protocol"){
        if(this.userType == "user"){
          this.infoTitle = "User protocol"
          this.infoUrl = "/protocol_user.pdf"
        }else if(this.userType == "driver"){
          this.infoTitle = "Driver protocol"
          this.infoUrl = "/protocol_driver.pdf"
        }
      }else{
        this.infoTitle = "Privacy Policy"
        if(this.userType == "user"){
          this.infoUrl = "/privacy_user.pdf"
        }else if(this.userType == "driver"){
          this.infoUrl = "/privacy_driver.pdf"
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
<style lang="scss">
  .infoShow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .infoShowTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  .contentWrap {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  .infoContent{
    padding: 0.4rem;
    text-align: left;
    text-indent: 0.5rem;
    font-size: 0.28rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 0.5rem;
  }
  .how_to_use {
    .list {
      text-align: left;
      margin-bottom: 0.2rem;
      a {
        border-bottom: 1px solid #00C85E;
        font-size: 0.3rem;
        font-family: Helvetica;
        line-height: 0.6rem;
        padding-bottom: 0.02rem;
        color: #00C85E;
      }
    }
    a {
      color: #000;
    }
    .detail_p {
      padding-top: 2.4rem !important;
    }
    .content {
      text-align: left;
      font-size: 0.28rem;
      font-family: Helvetica;
      line-height: 0.5rem;
      p {
        margin-bottom: 0.1rem;
        color: #000000;
        font-size: 0.32rem;
      }
      .p2 {
        font-size: 0.3rem;
      }
    }
  }
</style>