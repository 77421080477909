<!--
 * @Author: your name
 * @Date: 2021-04-14 13:20:26
 * @LastEditTime: 2021-04-21 15:13:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shiptal-frontend\src\views\multiDelivery\allocateCargo.vue
-->
<template>
  <div class="allocate_cargo">
    <div class="header">
      <p class="center_info">{{orderTitle}} - <span v-if="!type">{{groupName}} -</span> Summary</p>
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
    </div>
    <div class="content" v-loading="loading">
      <div class="summary_list">
        <p class="title">Package list<span class="total" v-if="!type">Date: {{publishTime}}</span></p>
        <el-table
          :data="summaryList"
          empty-text="No data"
          stripe
          style="width: 100%">
          <el-table-column
            prop="name"
            label="Name">
          </el-table-column>
          <el-table-column
            prop="num"
            label="Quantity"
            align="center"
            width="80">
          </el-table-column>
        </el-table>
      </div>
      <div class="address_list">
        <p class="title">Address lists<span class="total">Total: {{addressList.length}}</span></p>
        <div class="address_item" v-for="(item,index) in addressList" :key="index">
          <div class="address">
            <p class="address_detail">
              <span class="order_no">
                <i >{{index+1}}</i>
              </span>
              <span><span v-if="item.room">{{item.room}}, </span>{{item.address}}</span>
            </p>
            <p class="comment" v-if="item.comment">Comment: {{item.comment}}</p>
          </div>
          <div class="package">
            <div class="tag_list" v-if="source">
              <el-tag class="tag_style can_update" :class="packItem.status == 1? 'change_status':''"
               v-for="(packItem,packIndex) in item.package_list" :key="packIndex"
                @click="goUpdate(item, packItem,packIndex)">{{packItem.name}}</el-tag>
            </div>
            <div class="tag_list" v-else>
              <el-tag class="tag_style" v-for="(packItem,packIndex) in item.package_list" :key="packIndex">{{packItem.name}}</el-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from "moment"
  export default {
    name: 'Allocate_cargo',
    components: {},
    data() {
      return {
        summaryList: [],
        addressList: [],
        publishTime: '',
        orderTitle: '',
        groupName: '',
        loading: false,
        type: '',
        source: '',
        orderId: '',
        groupId: ''
      }
    },
    created() {
      let type = this.$route.query.type
      let source = this.$route.query.source
      let order_id = this.$route.query.id
      let group_id = this.$route.query.groupId?Number(this.$route.query.groupId):0
      this.orderId = order_id
      this.groupId = group_id
      this.type = type
      this.source = source
      if (type == 'all') {
        this.getOrderInfo(order_id)
      } else {
        this.getSummaryInfo(order_id, group_id)
      }
    },
    mounted() {},
    methods: {
      goBack(){
        this.$router.go(-1);
      },
      goUpdate(item, packItem, packIndex) {
        var package_status = 1
        var package_id_list = [packIndex]
        var sourceType = item.type == 'receiver'?2:1
        if (packItem.status == 1) {
          package_status = 0
        }
        this.$http({
          url: "/api/batch/order/update",
          method: "POST",
          data: {
            id: this.orderId,
            group_id: this.groupId,
            sub_order_id: item.id,
            type: sourceType,
            package_status,
            package_id_list
          }
        }).then(res => {
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            packItem.status = package_status
            this.$message({
              message: "Updated success",
              type: 'success'
            });
          }
        })
      },
      getSummaryInfo(order_id,group_id) {
        this.loading = true
        this.$http({
          url: "/api/batch/order/get/detail",
          method: "GET",
          params: {
            order_id: order_id,
            group_id
          },
        }).then(res => {
          this.$message.closeAll();
          this.loading = false
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            var data = res.data.data
            this.initDataList(data)
            var time = data.created_at
            if (time) {
              this.publishTime = moment(time).format('MM/DD')
            } else {
              this.publishTime = ''
            }
            this.orderTitle = data.order_name
          }
        })
      },
      getOrderInfo(order_id) {
        this.loading = true
        this.$http({
          url: "/api/batch/order/info/v2",
          method: "GET",
          params: {
            id: order_id,
          },
        }).then(res => {
          this.$message.closeAll();
          this.loading = false
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            var data = res.data.data
            this.orderTitle = data.order_name
            var groupList = data.group
            var addressList = []
            groupList.forEach(list => {
              addressList = addressList.concat(list.address_list)
            })  
            this.setSummaryInfo(addressList)
          }
        })
      },
      initDataList(data) {
        let dropoff_list = data.dropoff_list
        let pickup_list = data.pickup_list
        var groupName = ''
        dropoff_list.forEach(item => {
          item.type = 'receiver'
          groupName = item.group_name
        })
        pickup_list.forEach(item => {
          item.id = item.order_index
          item.type = 'sender'
        })
        this.groupName = groupName
        var newList = pickup_list.concat(dropoff_list)
        newList.forEach((item, index) => {
          var newAdd = item.address
          newAdd = newAdd.replace(', USA','')
          item.address = newAdd
        })
        this.setSummaryInfo(newList)
      },
      setSummaryInfo(list) {
        var packageList = []
        if (list && list.length) {
          list.forEach((item, index) => {
            this.addressList.push(item)
            packageList = packageList.concat(item.package_list)
          })
        }
        var newPackList = [], packNameList = []
        if (packageList && packageList.length) {
          var mapStorage = {}
          packageList.forEach((item,index)=>{
            var newName = item.name
            if (!mapStorage[newName]) {
              mapStorage[newName] = {
                total: 1,
              } 
            } else {
              mapStorage[newName].total++;
            }
          })
          for(var name in mapStorage) {
            var item = mapStorage[name]
            newPackList.push({
              name: name,
              num: item.total
            })
            packNameList.push(name)
          }
        }
        var newArr = packNameList.sort(function(a,b){return a.localeCompare(b,'zh-CN')})
        var sortPackList = []
        newArr.forEach(name => {
          newPackList.forEach((obj)=>{
            if (obj.name == name) {
              sortPackList.push(obj)
            }
          })
        })
        this.summaryList = sortPackList
      }
    }
  }
</script>
<style lang="scss">
  .allocate_cargo {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #f4f5f7;
    .content {
      position: absolute;
      top: 1.4rem;
      width: 100%;
      // width: 7.5rem;
      padding: 0.2rem 0;
      margin: auto;
      box-sizing: border-box;
      background: #f4f5f7;
      .title {
        width: 100%;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.35rem;
        font-weight: 700;
        text-align: left;
        box-sizing: border-box;
        padding: 0 0.2rem;
        border-bottom: 1px dashed #eee;
        .total {
          float:right;
          // font-weight: normal;
          // color: #888;
        }
      }
      .box-style {
        width: calc(100% - 0.4rem);
        background: #ffffff;
        margin: 0.2rem;
        border-radius: 10px;
        padding: 0.1rem;
        box-sizing: border-box;
      }
      .summary_list {
         width: 7.5rem;
        // width: 100%;
        margin: auto;
        margin-bottom: 0.2rem;
        background: #ffffff;
      }
      .address_list {
        background: #fff;
        padding: 0.1rem;
        box-sizing: border-box;
        width: 7.5rem;
        // width: 100%;
        margin: auto;
        .address_item {
          width: 100%;
          padding: 0.1rem;
          box-sizing: border-box;
          border-bottom: 1px solid #eee;
          .address_detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .order_no {
            // margin-right: 0.1rem;
            font-size: 0.3rem;
            display: flex;
            width: 0.38rem;
            justify-content: space-around;
            margin-right: 0.1rem;
            i {
              height: 0.38rem;
              line-height: 0.36rem;
              border: 1px solid teal;
              width: 0.38rem;
              color: teal;
              text-align: center;
              box-sizing: border-box;
              border-radius: 50%;
              font-style: normal;
              font-size: 0.25rem;
            }
          }
          .address {
            flex: 1;
            font-size: 0.28rem;
            line-height: 0.6rem;
            color: #888;
            p {
              text-align: left;
            }
            display: flex;
            flex-direction: column;
          }
          .comment {
            margin-left: 0.48rem;
          }
          .package {
            line-height: 0.5rem;
            font-size: 0.3rem;
            p {
              text-align: left;
              // margin-left: 0.48rem;
              color: #888;
            }
            .tag_list {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              flex-wrap: wrap;
              width: 100%;
            }
            .el-tag {
              font-size: 0.25rem;
              height: 0.5rem;
              line-height: 0.5rem;
              padding:0 0.1rem;
            }
            .tag_style {
              margin: 0.1rem;
              // margin-top: 0;
            }
            .can_update {
              padding: 0.1rem 0.2rem;
              line-height: 0.5rem;
              height: 0.7rem;
            }
            .change_status {
              background: #ccc;
              border: 1px solid #ccc;
              color: #fff;
            }
          }
        }
      }
    }
  }
</style>