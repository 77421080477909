<template>
  <div>
    <div class="group_info">
      <div class="group_title">
        <!-- <p><span class="title">Groups:</span> {{unGroupNum}} ungrouped / {{tableDataNum-1>0?(tableDataNum-1):0}} total   </p> -->
        <div class="group_sum">
          <p>{{groupList.length}} groups, 1 pickup, {{tableDataNum-1>0?(tableDataNum-1)+' deliveries':0+' delivery'}}, total payment: {{totalMoney}}</p>
          <div class="summary" @click="goSummary" v-if="parOrderId">Overall item summary</div>
        </div>
        <span class="add_group" @click="addGroup">Add group</span>
      </div>
      <div class="group_list">
        <div class="group_table" ref="groupTable">
          <el-table
           class="com_group_list"
            :data="groupList"
            :row-class-name="tableRowClassName"
            @row-click="rowClick"
            empty-text="No data"
            stripe border style="width: 100%">
            <el-table-column type="index" header-align="center"  align="center" label="Group id" width="80">
              <template slot-scope="scope">
                <div :style="'background:'+scope.row.groupColor+';color:#fff;'">{{scope.$index + 1}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="Name" label="Group name" header-align="center" align="center" width="120">
              <template slot-scope="scope">
                <el-input class="table_input" :disabled="scope.row.disablededit"
                v-model="scope.row.groupName" placeholder=""></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="groupNum" label="Stops" header-align="center" width="60" align="center" >
            </el-table-column>
            <el-table-column prop="status" label="Status" header-align="center" width="80" align="center" >
              <template slot-scope="scope">
                <p>{{scope.row.status | statusFilter}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="groupMoney" label="Money" header-align="center" width="70" align="center" >
              <template slot-scope="scope">
                <p>${{scope.row.groupMoney}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="total_time" label="Time" header-align="center" width="60" align="center" 
               column-key="time">
              <template slot-scope="scope">
                  <div class="time" v-if="scope.row.time">{{scope.row.time}}</div>
                  <div class="time" v-else>{{scope.row.total_time | timeFilter}}</div>
              </template>
            </el-table-column>
            <el-table-column prop="Handle" label="Operation" header-align="center" align="center" >
              <template slot-scope="scope">
                <div class="handle_btn">
                   <!-- <div class="show_btn delete" @click="deleteGroup(scope.row, scope.$index)">
                     <img src="./../assets/image/delete.png" alt="">
                   </div>
                   <div class="show_btn arrange" v-if="scope.row.groupNum >= 1" @click="arrageCourier(scope.row, scope.$index)">Text</div> -->
                   <div class="btn">
                     <div class="show_btn route" v-if="scope.row.groupNum >= 1" @click="goOptPage(scope.row, scope.$index)">Route</div>
                     <div class="show_btn route" v-if="scope.row.groupNum >= 1" @click="goItemSummary(scope.row, scope.$index)">Summary</div>
                   </div>
                     <div class="more_btn" @click="showActionSheetGroup(scope.row, scope.$index)">
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="group_item group_item_un" v-if="unGroupNum">
          <p>Ungrouped：{{unGroupNum}} stops</p>
        </div>
      </div>
    </div>
    <el-dialog
      title="Delete"
      :visible.sync="deleteGroupDialog"
      :close-on-click-modal="false"
      width="60%"
      :show-close="true"
      class="add_stop_dialog delete_dialog"
      center>
        <div class="delete_title">
          <i class="el-icon-warning"></i>
          <span>Do you want to delete this group?</span>
        </div>
        <div class="dialog_btn">
          <div class="btn cancel" @click="deleteCancel()">Cancel</div>
          <div class="btn add" @click="deleteConfirm()">Continue</div>
        </div>
    </el-dialog>
    <AddDriver :driverDialog="driverDialog" :orderId="orderId" :groupId="groupId" :width="width"
     @driverClose="driverClose" @confirmDriver="confirmDriver"></AddDriver>
    <Action :show="actionSheet" v-on:hide="hideActionSheet" :showGroupInfo="true" :groupItem="groupItem"
     v-on:deleteGroup="deleteGroup"  v-on:arrageCourier="arrageCourier" :showPreviewsRoute="true"
     v-on:previewRoute="previewRoute" ></Action>

  </div>
</template>

<script>
  // @ is an alias to /src
  let GroupColors=['#46baef','#4eef46','#141814','#8e6dec','#f1836d',
  '#eaea3d', '#eedcdc','#bca968','#dceee9','#065b44','#e38bd6','#00a6ac','#867892','#f7acbc']
  import AddDriver from '@/components/addDriver.vue'
  import Action from '@/components/actionSheet.vue'
  export default {
    name: 'groupList',
    components: {AddDriver, Action},
    props: {
      groupList: {
        default: function() {
          return []
        },
        type: Array
      },
      tableDataNum: {
        default: function() {
          return 0
        },
        type: Number
      },
      unGroupNum: {
        default: function() {
          return 0
        },
        type: Number
      },
      orderId: {
        default: function() {
          return ''
        },
        type: String
      },
      needSave: {
        default: function() {
          return false
        },
        type: Boolean
      },
      setGroupStatus: {
        default: function() {
          return false
        },
        type: Boolean
      },
      setGroupId: {
        default: function() {
          return 0
        },
        type: Number
      }
    },
    data() {
      return {
        deleteGroupDialog: false,
        deleteObjIndex: {},
        driverDialog: false,
        groupId: 0,
        width: '400px',
        showRoute: false,
        parOrderId: '',
        totalMoney: 0,
        actionSheet: false,
        groupItem: {}
      }
    },
    watch: {
      'groupList': {
        handler(){
          this.summaryTotal()
          this.$emit('groupListChange')
        },
        deep: true    //深度监听
      }
    },
    mounted() {
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if (row.groupId == this.setGroupId) {
          return 'show_row'
        }
        return '';
      },
      rowClick(row, column, event) {
        if (this.setGroupStatus) {
          this.$emit('selectGroupId', row.groupId)
        }
      },
      goSummary() {
        const { href } = this.$router.resolve({
            name: "allocateCargo",
            query: {
              id: this.parOrderId,
              type: 'all'
            }
        });
        window.open(href, '_blank');
      },
      showActionSheetGroup(item, index) {
        var info = {...item}
        info.index = index
        this.groupItem = info

        this.actionSheet = true
      },
      hideActionSheet() {
        this.actionSheet = false
      },
      summaryTotal() {
        var money = 0
        this.groupList.forEach(item => {
          money += Number(item.groupMoney)
        })
        this.totalMoney = money.toFixed(0)
      },
      goItemSummary(item) {
        if (this.orderId) {
          if (this.needSave) {
            this.$message({
              message: 'You need to save first before continue. ',
              type: 'warning'
            });
            return false
          }
          const { href } = this.$router.resolve({
              name: "allocateCargo",
              query: {
                id: this.parOrderId,
                groupId: item.groupId
              }
          });
          window.open(href, '_blank');
        } else {
          this.$message({
            message: 'You need to save first before continue. ',
            type: 'warning'
          });
        }
      },
      cellStyle({row, column, rowIndex, columnIndex}) {
        console.log(row)
      },
      showRouteChange(val,parOrderId) {
        // val 1 代表能展示 0代表不能展示
        val == 1?this.showRoute = true:this.showRoute = false
        if (parOrderId) {
          this.parOrderId = parOrderId
        }
      },
      addGroup() {
        var index = 0
        var len = this.groupList.length
        if (len > 0) {
          index = (this.groupList[len-1].groupId)+1
        }
        this.groupList.push({
          groupName: 'group'+(index+1),
          groupNum: 0,
          groupId: index,
          groupColor: GroupColors[index],
          time:'',
          groupMoney: 0,
          status: 0
        })
        localStorage.setItem('groupList', JSON.stringify(this.groupList))
        this.$nextTick(() => {
          let tablePar = this.$refs["groupTable"];
          tablePar.scrollIntoView(tablePar.scrollTop)
        })
      },
      deleteGroup(item, index) {
        this.deleteObjIndex = index
        this.deleteGroupDialog = true
        this.hideActionSheet()
      },
      deleteCancel() {
        this.deleteGroupDialog = false
      },
      deleteConfirm() {
        var id = this.groupList[this.deleteObjIndex].groupId
        this.groupList.splice(this.deleteObjIndex,1)
        localStorage.setItem('groupList', JSON.stringify(this.groupList))
        this.$emit('groupDeleteConfirm', id)
        if (this.setGroupStatus) {
          this.$emit('selectGroupId', this.groupList[0].groupId)
        }
      },
      arrageCourier(item, index) {
        if (this.orderId) {
          if (this.needSave) {
            this.$message({
              message: 'You need to save first before continue. ',
              type: 'warning'
            });
            return false
          }
          this.groupId = item.groupId
          this.driverDialog = true
          this.hideActionSheet()
        } else {
          this.$message({
            message: 'You need to save first before continue. ',
            type: 'warning'
          });
        }
      },
      
      goOptPage(item) {
        console.log(item.groupNum)
        if (item.groupNum > 60) {
          this.$confirm('Too many stops in this group. Suggest to have <= 60 stops in one group.', 'Warning', {
            confirmButtonText: 'Regroup',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            // this.goOpt(item)
            return false
          }).catch(() => {
            return false
          });
        } else {
          this.goOpt(item)
        }
      },
      goOpt(item) {
        if (this.orderId) {
          if (this.needSave) {
            this.$message({
              message: 'You need to save first before continue. ',
              type: 'warning'
            });
            return false
          }
          const { href } = this.$router.resolve({
              name: "multiOptimizedRoute",
              query: {
                id: this.parOrderId,
                groupId: item.groupId
              }
          });
          window.open(href, '_blank');
          this.getGroupTime(this.parOrderId, item.groupId)
        } else {
          this.$message({
            message: 'You need to save first before continue. ',
            type: 'warning'
          });
        }
      },
      previewRoute(item) {
        if (this.orderId) {
          if (this.needSave) {
            this.$message({
              message: 'You need to save first before continue. ',
              type: 'warning'
            });
            return false
          }
          const { href } = this.$router.resolve({
              name: "previewOptimizedRoute",
              query: {
                id: this.parOrderId,
                groupId: item.groupId
              }
          });
          window.open(href, '_blank');
          this.getGroupTime(this.parOrderId, item.groupId)
        } else {
          this.$message({
            message: 'You need to save first before continue. ',
            type: 'warning'
          });
        }
      },
      getGroupTime(id, groupId) {
        this.$http({
          url: "/api/batch/order/get/v2",
          method: "GET",
          params: {
            id: id,
            group_id: groupId
          },
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            var time = res.data.data.total_travel_time
            var needHour = 0
            if (time) {
              if (time < 60) {
                needHour =time + 'm'
              } else {
                needHour = Math.floor(time/60)+"h "+((time%60)==0?"":(time%60)+"m")
              }
            }
            this.groupList.forEach(item => {
              if (item.groupId == groupId) {
                item.time = needHour
              }
            })
          }
        }).catch(err => {
        })
      },
      driverClose() {
        this.driverDialog = false
      },
      confirmDriver() {
        this.driverClose()
      }
    },
    filters: {
      timeFilter(time) {
        if (time) {
          if (time < 60) {
            return time + 'm'
          } else {
            return Math.floor(time/60)+"h "+((time%60)==0?"":(time%60)+"m")
          }
        } else {
          return ''
        }
      },
      statusFilter(val) {
        if (val == 0) {
          return 'unpaid'
        } else if (val == 1) {
          return 'matching'
        } else if (val == 2) {
          return 'paired'
        } else if (val == 6) {
          return 'completed'
        } else if (val == 8) {
          return 'in progress'
        } else {
          return 'issues'
        } 
      }
    }
  }
</script>
<style lang="scss">
.group_title {
  p {
    line-height: 25px;
  }
  .title {
    color: teal;
    font-weight: bolder;
  }

}
.summary {
  color: teal;
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: left;
}
.group_table {
  .el-table {
    margin-bottom: 10px;
    .handle_btn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .btn {
        display: flex;
        flex-direction: row;
      }
      .show_btn {
        height: 25px;
        line-height: 25px;
        color: #fff;
        background: teal;
        border-radius: 5px;
        font-size: 12px;
        margin: 0 3px;
        padding: 0 5px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .delete {
        // background: tomato;
        background: transparent;
        width: 25px;
        height: 25px;
        padding: 0;
        img {
          width: 100%;
        }
      }
      .more_btn {
        line-height: 6px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        width: 10px;
        margin-bottom: 5px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  .el-table th, .el-table td {
    padding: 3px 0 !important;
  }
  .el-input {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border: none;
    border-radius: 3px;
    padding: 0 3px;
    color: #606266;
    font-size: 12px;
    outline: none !important;
    background: transparent;
    input {
      font-size: 12px;
      width: 100%;
      height: 30px;
      line-height: 30px;
      color: #606266;
      outline: none !important;
      // background: transparent;
      background: #eee;
    }
  } 
}
</style>