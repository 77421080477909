import { render, staticRenderFns } from "./dayDeliveryTask.vue?vue&type=template&id=53aaae88&scoped=true&"
import script from "./dayDeliveryTask.vue?vue&type=script&lang=js&"
export * from "./dayDeliveryTask.vue?vue&type=script&lang=js&"
import style0 from "./dayDeliveryTask.vue?vue&type=style&index=0&id=53aaae88&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53aaae88",
  null
  
)

export default component.exports