<template>
  <div class="verifyStepThree verifyStep">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="stepTwoTitle">Step2</p>
    </div>
    <div class="stepTwoForm">
      <div class="formWrap">
        <div class="singleFormItem">
          <p class="formItemTitle">
            FirstName
          </p>
          <div class="inputWrap">
            <el-input type="text" v-model="formObj.first" placeholder="FirstName"></el-input>
          </div>
        </div>

        <div class="singleFormItem">
          <p class="formItemTitle">
            Middle Name
          </p>
          <div class="inputWrap middleName">
            <el-input type="text" v-model="formObj.middle" placeholder="Middle Name"></el-input>
          </div>
        </div>
        <p class="haveNoMiddleName">
          <cusCheckbox :checkedObj="middleNameObj"></cusCheckbox>
          <span>I do not have a middle name</span>
        </p>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Last Name
          </p>
          <div class="inputWrap">
            <el-input type="text" v-model="formObj.last" placeholder="Last Name"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Zip code
          </p>
          <div class="inputWrap">
            <el-input type="text" v-model="formObj.zip" placeholder="The zip code area where you like to do delivery"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Home adderss
          </p>
          <div class="inputWrap">
            <!--<el-input type="text" v-model="formObj.address" placeholder=" Home address for receiving activation kit later"></el-input>-->
            <div class="el-input">
              <input type="text" id="homeAddress" class="autocompleteFiled" placeholder="" v-model="formObj.address" />
            </div>
          </div>
        </div>
      </div>
      <div class="actionCommon" @click="stepTwoDealData">Continue</div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'verifyStepThree',
    components: {
      cusCheckbox
    },
    data() {
      return {
        formObj: {
          first: "",
          middle: "",
          last: "",
          zip: "",
          address: "",
          position: undefined,
          hasMiddle: true
        },
        middleNameObj: {
          checked: false,
          changeable: false
        }
      }
    },
    watch: {
      'middleNameObj.checked': {
        handler(value) {
          this.formObj.hasMiddle = !value;
        },
        immediate: true,
        deep: true
      }
    },
    mounted() {
      this.initInputField();
      let stepTwoData = localStorage.getItem("stepTwoData");
      if(stepTwoData) {
        this.formObj = JSON.parse(stepTwoData);
      }
    },
    methods: {
      initInputField() {
        let _this = this;
        this.loader.load().then(() => {
          let homeAddressEle = document.getElementById('homeAddress');
          let autohomeAddress = new google.maps.places.Autocomplete(homeAddressEle);
          autohomeAddress.addListener("place_changed", () => {
            let homeAddress = autohomeAddress.getPlace();
            if(!homeAddress.geometry) {
              this.$message.closeAll();
              this.$message({
                showClose: false,
                message: 'Please enter the specific address',
                type: 'warning'
              });
            } else {
              console.log(homeAddressEle.value)
              console.log(homeAddress)
              _this.formObj.address = homeAddressEle.value;
              _this.formObj.position = {
                lat: homeAddress.geometry.location.lat(),
                lng: homeAddress.geometry.location.lng()
              }
            }
          })
        });
      },
      stepTwoDealData() {
        let inputPass = true;
        for(let key in this.formObj) {
          if(!this.formObj[key]) {
            if(key == "middle" || key == 'hasMiddle' || key == 'position') {
              if(!this.middleNameObj.checked) {
                inputPass = false;
              }
            } else {
              inputPass = false;
            }
          }
        }
        if(inputPass) {
          localStorage.setItem("stepTwoData", JSON.stringify(this.formObj))
          this.jumpRoute('verifyStepThree')
        } else {
          this.$message.closeAll();
          this.$message({
            message: 'Please complete input field',
            type: 'warning'
          });
        }

      },
      goBack() {
        this.$router.go(-1);
      },
      jumpRoute(path) {
        this.$router.push({
          path: "/" + path,
          name,
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      }
    }
  }
</script>
<style scoped="scoped">
  .verifyStepThree {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .stepTwoTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  
  .stepTwoForm {
    width: 7.5rem;
    position: relative;
    top: 1.4rem;
    left: 0;
    height: calc(100% - 1.4rem);
    margin: auto;
    padding: 0.2rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .verifyStepThree .actionCommon {
    margin: auto;
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
  }
  
  .formWrap {
    margin-top: 0.3rem;
  }
  
  .singleFormItem {
    font-size: 0;
  }
  
  .formItemTitle {
    font-size: 0.42rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.9rem;
    text-align: left;
    padding: 0 0.2rem;
    box-sizing: border-box;
  }
  
  .haveNoMiddleName {
    position: relative;
    padding-left: 0.2rem;
    height: 0.58rem;
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.48rem;
    margin-bottom: 0.4rem;
  }
  
  .middleName.inputWrap {
    margin-bottom: 0.24rem;
  }
  
  .haveNoMiddleName>span {
    padding-left: 0.4rem;
    vertical-align: top;
  }
  
  #homeAddress {
    background: #F8F8F8;
  }
</style>