<template>
  <div class="salaryWater">
    <div class="header">
      <div class="center_info">
        Transaction
      </div>
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
    </div>
    <div class="taskContainer">
      <div class="singleOperation" v-for="(item,key) in waterList">
        <div>
          <p>{{getText(item.status)}}</p>
          <p>{{formatdate(item.created_at)}}</p>
        </div>
        <div>-${{item.amount}}</div>
      </div>
      <p class="noFound" v-if="!waterList.length">No transaction found.</p>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'salaryWater',
    components: {},
    data() {
      return {
        id: "",
        waterList: []
      }
    },
    mounted() {

      let userType = localStorage.getItem("userType")
      if(userType == "driver") {
        this.id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).id : "";
        if(!this.id) {
          this.jumpRoute("loginDriver")
          return;
        }
      } else if(userType == "user") {
        this.id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).user_id : "";
        if(!this.id) {
          this.jumpRoute("loginUser")
          return;
        }
      }
      this.getwithdrawal();
    },
    methods: {
      getText(status){
        switch (status){
        	case 1:
          return "operating" 
          break;
      	  case 1:
      	    return "withdraw succeed" 
          break;
        case 1:
          return "withdraw failed" 
          break;
        	default:
        	  return "operating" 
        		break;
        }
      },
      getwithdrawal() {
        this.$http({
          url: "/api/driver/get/withdrawal",
          method: "GET",
          params: {
            driver_id: this.id
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.waterList = res.data.data;
          }
        })
      },
      goBack() {
        this.$router.go(-1);
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          query: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
    }
  }
</script>
<style scoped="scoped">
  .salaryWater {
    width: 100%;
    height: 100%;
  }
  
  .taskContainer {
    width: 7.18rem;
    height: calc(100% - 1.8rem);
    top: 1.4rem;
    position: relative;
    z-index: 10;
    background: #F8F8F8;
    border-radius: 0.2rem;
    margin: auto;
    padding: 0.2rem 0.24rem;
    box-sizing: border-box;
  }
  
  .singleOperation {
    width: 100%;
    height: 2rem;
    background: #E5F3FA;
    border-radius: 0.12rem;
    border: 0.02rem solid #D9EEF9;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.6rem;
    padding-left: 0.2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    margin-bottom: 0.4rem;
    overflow: hidden;
  }
  
  .noFound {
    line-height: 3rem;
  }
</style>