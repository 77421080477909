<!--
 * @Author: your name
 * @Date: 2021-04-13 10:06:56
 * @LastEditTime: 2021-04-26 11:53:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shiptal-frontend\src\views\deliverer\completedTask.vue
-->
<template>
  <div class="completedTask">
    <div class="header">
      <div class="center_info">
        {{completedTaskArr.length}} tasks completed, get ${{income}}
      </div>
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
    </div>
    <div class="content" v-loading="loading">
      <div class="completed_list">
        <el-card class="box-card complete_item" v-for="(item,index) in completedTaskArr" :key="index">
          <div class="item_title">
            <span>Completion time: {{item.completed | timeFilter}} </span>
            <span class="optimize" @click="optimizeRoute(item)">Optimize route</span>
          </div>
          <div class="item_center">
            <p>{{item.group.order_name}}-{{item.group.pickup_list[0].group_name}}</p>
            <div class="detail">
              <span>{{item.group.pickup_list.length}}pickup, {{item.group.dropoff_list.length}}drops</span>
              <span>${{item.money}}</span>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from "moment"
  export default {
    name: 'completedTask',
    components: {},
    data() {
      return {
        completedTaskArr: [],
        income: 0,
        loading: false
      }
    },
    mounted() {
      this.getCompleteList()
    },
    filters: {
      timeFilter(val) {
        if (val) {
          return moment().format('YYYY/MM/DD')
        } else {
          return ''
        }
      }
    },
    methods: {
      optimizeRoute(item) {
        var id = item.group.order_id.split('_')[0]
        var groupId = item.group.order_id.split('_')[1]
        this.jumpRoute(`multiOptimizedRoute?id=${id}&groupId=${groupId}`)
      },
      getCompleteList(){
        this.loading = true
        this.$http({
          url: "/api/driver/complete",
          method: "GET",
          params: {
          }
        }).then(res => {
          this.$message.closeAll();
          this.loading = false
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            var data = res.data.data
            var income = 0
            if (data && data.length) {
              data.forEach(item=>{
                item.money = (item.cost+item.tip)/100
                income += item.money
              })
            }
            this.income = income
            this.completedTaskArr = data
          }
        })
      },
      goBack() {
        this.jumpRoute("mainTaskPage")
      },
      jumpRoute(path) {
        this.$router.push({
          path: "/" + path,
          name,
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      },
    }
  }
</script>
<style lang="scss">
  .completedTask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .content {
      width: 100%;
      background: #f4f5f7;
      position: absolute;
      top: 1.4rem;
      min-height: calc(100vh - 1.4rem);
      .completed_list {
        width: 100%;
        padding: 0.2rem;
        box-sizing: border-box;
      }
      .el-card__body {
        padding: 0;
      }
      .complete_item {
        width: 100%;
        background: #fff;
        padding: 0.2rem;
        margin-bottom: 0.2rem;
        .item_title {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          height:0.8rem;
          line-height: 0.6rem;
          border-bottom: 1px solid #e5e5e5;
          color: #999;
          font-size: 0.3rem;
          .optimize {
            padding: 0 .16rem;
            height: 0.6rem;
            line-height: 0.6rem;
            color: #fff;
            border-radius: 4px;
            font-size: 0.3rem;
            text-align: center;
            background: linear-gradient(180deg, rgb(101, 221, 221) 0%, teal 100%);
            transition: all .3s ease;
          }
        }
        .item_center {
          width: 100%;
          p {
            width: 100%;
            font-size: 0.35rem;
            height: 1rem;
            line-height: 1rem;
            text-align: left;
            font-weight: 600;
          }
          .detail {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 0.6rem;
            line-height: 0.6rem;
            color: #666;
            span:nth-child(1) {
              margin-right: 0.2rem;
            }
            span:nth-child(2) {
              font-weight: 700;
              color: teal;
            }
          }
        }
      }
    }
  }
</style>