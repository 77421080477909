<template>
  <div class="packageInfo">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="packageTitle">Package info</p>
    </div>
    <div class="packageForm">
      <div class="infoLine">
        <span v-for="(item,key) in packageInfo.weightInfo">
    	    <span v-text="item.title"></span>
        <cusCheckbox :checkedObj="item" :hasCall="true" @changeCallback="weightChange"></cusCheckbox>
        </span>
      </div>
      <div class="infoLine line2">
        <span v-for="(item,key) in packageInfo.sizeInfo">
          <span v-text="item.title"></span>
        <cusCheckbox :checkedObj="item" :hasCall="true" @changeCallback="sizeChange"></cusCheckbox>
        </span>
      </div>
      <div class="valueLine">
        <span>
      	  <span>Value > $300 ?</span>
        <cusCheckbox :checkedObj="packageInfo.valueObj"></cusCheckbox>
        </span>
      </div>
      <div class="formWrap">
        <div class="singleFormItem">
          <p class="formItemTitle">Item list，if not packed in 1 box</p>
          <div class="inputWrap">
            <el-input type="text" v-model="packageInfo.formObj.items" placeholder="Such as two pizzas, three beers"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">Total item count, if not packed in 1 box</p>
          <div class="inputWrap">
            <el-input type="text" v-model="packageInfo.formObj.count"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">Comment</p>
          <div class="inputWrap">
            <el-input type="textarea" :rows="4" resize="none" :autosize="true" v-model="packageInfo.formObj.Comment"></el-input>
          </div>
        </div>
      </div>
      <div class="driverLine">
        <span>
          <span>I want to choose drivers</span>
        <cusCheckbox :checkedObj="packageInfo.driverObj"></cusCheckbox>
        </span>
      </div>

      <div class="actionCommon" @click="formatPackage">Continue</div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'packageInfo',
    components: {
      cusCheckbox
    },
    data() {
      return {
        packageInfo: {
          weightInfo: [{
              title: "",
              checked: false,
            },
            {
              title: "",
              checked: false,
            },
            {
              title: "",
              checked: false,
            }
          ],
          sizeInfo: [{
              title: "",
              checked: false,
            },
            {
              title: "",
              checked: false,
            }
          ],
          valueObj: {
            checked: false
          },
          driverObj: {
            checked: false
          },
          formObj: {
            items: "",
            count: "",
            Comment: ""
          },
        },
        checkedWeightInfo: "",
        checkedSizeInfo: ""
      }
    },
    watch: {
      'packageInfo.valueObj.checked': {
        handler(value) {
          if(value) {
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Sorry, we do not deliver package with value > $300 now. Will change this policy later',
              type: 'error'
            });
          }
        },
        immediate: true,
        deep: true
      }
    },
    mounted() {
      this.ruleFormat();
    },
    methods: {
      ruleFormat() {
        if(localStorage.getItem("packageInfo")) {
          let tempIDArr = JSON.parse(localStorage.getItem("packageInfo"))
          this.tempPackageInfo(tempIDArr)
        }else{
          if(localStorage.getItem("priceRule")) {
            let priceRule = JSON.parse(localStorage.getItem("priceRule"))
            this.packageInfo.weightInfo = [{
                title: priceRule.weightInfoArr[0].title,
                checked: true,
              },
              {
                title: priceRule.weightInfoArr[1].title,
                checked: false,
              },
              {
                title: priceRule.weightInfoArr[2].title,
                checked: false,
              }
            ]
            this.packageInfo.sizeInfo = [{
                title: priceRule.sizeInfoArr[0].title,
                checked: true,
              },
              {
                title: priceRule.sizeInfoArr[1].title,
                checked: false,
              }
            ]
          }
        }
      },
      goBack() {
        this.$router.go(-1);
      },
      tempPackageInfo(data) {
        this.packageInfo = data.packageInfo;
        this.checkedWeightInfo = data.checkedWeightInfo;
        this.checkedSizeInfo = data.checkedSizeInfo;
        this.packageInfo.sizeInfo = data.packageInfo.sizeInfo;
        this.packageInfo.weightInfo = data.packageInfo.weightInfo;
      },
      weightChange(item) {
        if(item.checked) {
          this.checkedWeightInfo = item.title;
          this.packageInfo.weightInfo.map((temp) => {
            if(temp.title != item.title) {
              temp.checked = false;
            }
          })
        }
      },
      sizeChange(item) {
        if(item.checked) {
          this.checkedSizeInfo = item.title;
          this.packageInfo.sizeInfo.map((temp) => {
            if(temp.title != item.title) {
              temp.checked = false;
            }
          })
        }
      },
      jumpRoute(path) {
        this.$router.push({
          path: "/" + path,
          name,
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      },
      formatPackage() {
        if(this.packageInfo.valueObj.checked) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Sorry, we do not deliver package with value > $300 now. Will change this policy later',
            type: 'error'
          });
          return;
        }
        let tempInfo = {
          packageInfo: this.packageInfo,
          checkedWeightInfo: this.checkedWeightInfo,
          checkedSizeInfo: this.checkedSizeInfo
        }
        localStorage.setItem("packageInfo", JSON.stringify(tempInfo));
        if(this.packageInfo.driverObj.checked) {
          this.jumpRoute("chooseDeliveryman")
        } else {
          this.jumpRoute("deliverSummary")
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .packageInfo {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .packageTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  
  .packageForm {
    width: 7.5rem;
    position: relative;
    top: 1.4rem;
    left: 0;
    height: calc(100% - 1.4rem);
    margin: auto;
    padding: 0.5rem 0.2rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .packageInfo .actionCommon {
    margin: auto;
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
  }
  
  .infoLine {
    width: 7.1rem;
    height: 1.28rem;
    background: #F8F8F8;
    border-radius: 0.12rem;
    border: 0.02rem solid #C6C6C6;
    margin-bottom: 0.6rem;
    font-size: 0;
    text-align: left;
    display: flex;
    justify-content: space-around;
  }
  
  .line2.infoLine {
    margin-bottom: 0.3rem;
  }
  
  .infoLine>span {
    display: inline-block;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.28rem;
    padding-right: 0.6rem;
    position: relative;
  }
  
  .infoLine>span>.cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .valueLine,
  .driverLine {
    width: 7.1rem;
    height: 1.28rem;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.28rem;
    text-align: left;
  }
  
  .driverLine {
    margin-top: -0.3rem;
  }
  
  .valueLine>span,
  .driverLine>span {
    position: relative;
    padding-right: 0.6rem;
  }
  
  .valueLine .cusCheckbox,
  .driverLine .cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .formWrap {
    margin-top: 0.3rem;
  }
  
  .singleFormItem {
    font-size: 0;
  }
  
  .formItemTitle {
    padding-bottom: 0.2rem;
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D3D3D3;
    line-height: 0.5rem;
    text-align: left;
  }
</style>