<template>
  <div class="day-delivery-task">
    <div class="header">
      <div class="center_info">
        <i class="el-icon-d-arrow-left" @click="getDay(-1)"></i>
        Tasks of {{dayText}} {{weekText}}
        <i class="el-icon-d-arrow-right" @click="getDay(1)"></i>
      </div>
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
    </div>
    <div class="TaskListContainer" v-loading="loading">
      <div id="mapWrap"></div>
      <div class="summary">
        <div class="time">{{dayText}}</div>
        <div class="total">Total: {{groupNum}}groups,{{pickupNum}}pickups,{{dropoffNum}}drops</div>
      </div>
      <template v-if="orderList.length>0">
        <div class="btn-wrapper">
          <div class="show-all" @click="renderMapmark(orderList)">Show all addresses</div>
          <div class="optimize" @click="optimizeRoute">Optimize route</div>
        </div>
        <div class="task-wrapper">
          <div class="order" v-for="item in orderList" :key="item.order_id">
            <el-collapse >
              <el-collapse-item :name="item.order_name" v-for="(subItem, key, groupIdx) in item.grouped_dropoff_list" :key="groupIdx">
                <template slot="title">
                  <div class="title" @click="showGroupMark(item, subItem)">
                    <div class="title-head">
                      <span>Group{{item.drop_start_index + groupIdx + 1}}</span><span>{{item.pickup_list.length}}pickup, {{subItem.length}}drops</span>
                    </div>
                    <div class="title-abstract">
                      <div class="group-name">Title:{{item.created_at | timeFilter}} {{item.order_name}} - {{key}}</div>
                      <div class="pick-comment" v-if="item.pickup_list[0].comment">{{item.pickup_list[0].comment}}</div>
                    </div>
                  </div>
                </template>
                <div class="address-list">
                  <div class="address-list-item" v-for="(address, index) in [...item.pickup_list, ...subItem]"
                    :class="(selectedId == address.unid ? 'select_item' : '')"
                    @click="showHandle(address)">
                    <div class="order_no">
                      <i >{{index + 1}}</i>
                    </div>
                    <div :class="['type', index + 1 <= item.pickup_list.length ? 'pickup' : 'drop']">{{index + 1 <= item.pickup_list.length ? 'Pickup' : 'Drop' }}</div>
                    <div class="address">
                      <p><span v-if="address.room">{{address.room}}, </span>{{address.address}}</p>
                      <p class="comment" v-if="selectedId == address.unid && address.comment">Comment: {{address.comment}}</p>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </template>
      <div class="no-order" v-else>
        There are no tasks to send in the day
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment"

  const groupBy = require('lodash/groupBy');
  const weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let markersList = [];
  export default {
    name: 'dayDeliveryTask',
    data() {
      return {
        currentLocation: {},
        map: null,
        dayTime: '',
        dayText: '',
        weekText: '',
        searchTime: '',
        orderList: [],
        loading: false,
        selectedId: -1,
      }
    },
    computed: {
      groupNum() {
        if (!this.orderList.length) return 0;
        return this.orderList.reduce((pre, cur) => {
          return Object.keys(cur.grouped_dropoff_list).length + pre;
        }, 0);
      },
      pickupNum() {
        if (!this.orderList.length) return 0;
        return this.orderList.reduce((pre, cur) => {
          return Object.keys(cur.pickup_list).length + pre;
        }, 0); 
      },
      dropoffNum() {
        if (!this.orderList.length) return 0;
        return this.orderList.reduce((pre, cur) => {
          return Object.keys(cur.dropoff_list).length + pre;
        }, 0); 
      }
    },
    watch: {
      dayTime(val) {
        const day = new Date(val)
        const date = day.getDate();
        const month = day.getMonth() + 1;
        const year = day.getFullYear();
        this.dayText = `${month}/${date}`;
        this.weekText = weeks[day.getDay()];
        this.searchTime = `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date}`;
        this.getTaskList()
      },
    },
    mounted() {
      this.driver_id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).id : "";
      if(!this.driver_id) {
        this.jumpRoute("loginDriver")
        return;
      }
      this.getDay();
      this.initMap();
    },
    filters:{
      timeFilter(val) {
        if (val) {
          return moment(val).format('MM/DD')
        } else {
          return ''
        }
      },
    },
    methods: {
      // 获取日期信息
      getDay(flag) {
        if (!flag) {
          this.dayTime = new Date(new Date().toLocaleDateString()).getTime();
          return;
        }
        this.dayTime = this.dayTime + (24 * 60 * 60 * 1000 * flag);
      },
      initMap() {
        let _this = this;
        let positionObj = localStorage.getItem("position");
        if(positionObj) {
          this.currentLocation = JSON.parse(positionObj)
        }
        this.loader.load().then(() => {
          _this.map = new google.maps.Map(document.getElementById("mapWrap"), {
            center: {
              lat: 37.382767,
              lng: -122.023248
            },
            zoom: 10,
          });
        });
      },
      // 处理订单数据
      dealData(arr) {
        arr.forEach((l, i) => {
          // unid 每个地址的唯一标识
          l.pickup_list.forEach(v => {
            v.type = 'sender';
            v.order_id = l.order_id;
            v.unid = `${v.order_id}${v.type}${v.order_index}`;
          })
          l.dropoff_list.forEach(v => {
            v.type = 'receiver';
            v.unid = `${v.order_id}${v.type}${v.order_index}`;
          })
          // 将dropoff_list根据group_name分组
          l.grouped_dropoff_list = groupBy(l.dropoff_list, 'group_name');
          l.drop_start_index = i === 0 ? 0 : Object.keys(arr[i - 1].grouped_dropoff_list).length;
        })
        return arr;
      },
      getTaskList() {
        this.loading = true;
        this.$http({
          url: "/api/driver/order/list",
          method: "GET",
          params: {
            search_time: this.searchTime,
            // search_time: '2021-04-06',
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            	if(res.data.data.length) {
                this.orderList = this.dealData(res.data.data);
                this.renderMapmark(this.orderList);
            	}else{
                this.orderList = [];
                this.renderMapmark([]);

            		this.$message({
  	              message: "There are no orders to do",
  	              type: 'info'
  	            });
            	}
          }
        }).finally(() => {
          this.loading = false;
        })
      },
      // 组点击，地图标记切换
      showGroupMark(item, subItem) {
        const arr = [{
          pickup_list: item.pickup_list,
          dropoff_list: subItem,
        }]
        this.renderMapmark(arr);
      },
      // 标记渲染
      renderMapmark(arr) {
        this.setMapOnAll(null);
        markersList = [];
        if (!arr.length) return;

        const addressList = arr.reduce((pre, cur) => {
          return [...pre, ...cur.pickup_list, ...cur.dropoff_list];
        }, []);

        this.loader.load().then(() => {
          for (let i = 0; i < addressList.length; i++) {
            const address = addressList[i];
            const popEl = document.createElement("div");
            popEl.innerText = i + 1;
            popEl.setAttribute("unid", address.unid);

            if (address.type === 'sender') {
              popEl.classList.add('sender');
              popEl.setAttribute("type", "sender");
              const popupSender = new this.Popup(
                new google.maps.LatLng(address.latitude, address.long),
                popEl,
                this.bubbleClick
              );
              popupSender.setMap(this.map);
              if (markersList[i]) {
                markersList[i].setMap(null);
              }
              markersList[i] = popupSender;
            } else {
              popEl.classList.add('receiver');
              popEl.setAttribute("type", "receiver");
              const popupReceiver = new this.Popup(
                new google.maps.LatLng(address.latitude, address.long),
                popEl,
                this.bubbleClick
              );
              popupReceiver.setMap(this.map);
              if (markersList[i]) {
                markersList[i].setMap(null);
              }
              markersList[i] = popupReceiver;
            }
          }
        })
      },
      bubbleClick(e) {
        const ele = e.target;
        const unid = ele.getAttribute("unid");
        const type = ele.getAttribute("type");
        let item = null;
        const k = type === 'sender' ? 'pickup_list' : 'dropoff_list';
        for(let i = 0; i < this.orderList.length; i++) {
          item = this.orderList[i][k].find(l => l.unid === unid);
          if (item) {
            break;
          }
        }
        this.changeMarkClass(unid);
        this.selectedId = unid;
        item && this.centerMap(item);
      },
      showHandle(handleItem) {
        const {type, order_index, order_id } = handleItem;
        const unid = `${order_id}${type}${order_index}`;
        this.selectedId = unid;
        this.changeMarkClass(unid);
        this.centerMap(handleItem);
      },
      // 修改点标记的状态class
      changeMarkClass(unid) {
        const domArr = document.getElementsByClassName('popup-bubble')
        for (let i = 0; i < domArr.length; i++) {
          const domItem = domArr[i];
          const domUnid = domItem.getAttribute('unid');
          domItem.classList.remove('nowhandle');
          if (domUnid === unid) {
            domItem.classList.add('nowhandle');
          }
        }
      },
      // 地图居中
      centerMap(currentTask) {
        let centerPos;
        centerPos = {
          lat: currentTask.latitude,
          lng: currentTask.long
        }
        this.map.setCenter(centerPos)
      },
      setMapOnAll(map) {
        for (let i = 0; i < markersList.length; i++) {
          markersList[i].setMap(map);
        }
      },
      optimizeRoute() {
        if (!this.orderList.length) return;
        const params = [];
        for(let i = 0; i < this.orderList.length; i++) {
          const grouped = this.orderList[i].grouped_dropoff_list;
          for(let k in grouped) {
            params.push({
              order_id: grouped[k][0].order_id,
              group_id: grouped[k][0].group_id,
            });
          }
        }
        localStorage.setItem('routeParams',JSON.stringify(params))
        localStorage.setItem('routeTitle',`Tasks of ${this.dayText} ${this.weekText}`)
        this.$router.push({
          path: '/dayOptimizedRoute'
        });
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          params: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
<style scoped="scoped" lang="scss">
.TaskListContainer {
  width: 7.18rem;
  height: calc(100% - 1.4rem);
  top: 1.4rem;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  z-index: 10;
  border-radius: 0.2rem;
  text-align: left;
  overflow: hidden;

  .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 0 .1rem;
    height: 1rem;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .2rem;

    >div {
      padding: 0 .16rem;
      height: 0.6rem;
      line-height: 0.6rem;
      color: #fff;
      border-radius: 4px;
      font-size: 0.3rem;
      text-align: center;
      background: linear-gradient(180deg, #33DDFF 0%, #009FFD 100%);
      transition: all .3s ease;

      &:hover, &:active, &:focus {
        background: #009FFD;
      }

    }

  }

  .no-order {
    padding-top: 20%;
    height: calc(100% - 5.5rem);
    text-align: center;
  }

  .task-wrapper {
    height: calc(100% - 6.3rem);
    overflow: auto;

    /deep/.el-collapse {

      .el-collapse-item__header {
        height: unset;
        min-height: 48px;
      }

      .el-collapse-item__content {
        padding: 0;
      }

    }

    .order {
      margin-bottom: .3rem;

      &-name {
        height: .72rem;
        line-height: .72rem;
      }
      .title {
        width: 100%;
        line-height: normal;
        padding: 0 .2rem;

        &-head {
          display: flex;
          align-items: center;
          font-size: .28rem;
          // justify-content: space-between;

          span:first-child {
            margin-right: 10px;
            width: 55px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #00AAFD;
            color: #fff;
          }

        }

        &-abstract {
          .pick-comment {
            font-size: 13px;
            margin-top: 5px;
          }
        }

      }


      .address-list {

        &-item {
          border-bottom: 1px dashed #ccc;
          background: rgba(0, 200, 94, 0.1);
          width: 100%;
          display: flex;
          padding: 0.25rem 0.2rem;
          font-size: 0.3rem;
          line-height: 0.4rem;
          box-sizing: border-box;

          &:last-child {
            border: none;
          }

          &.select_item {
            background: rgba(255, 194, 7, 0.15);
          }

          .type {
            margin: 0 0.1rem;
            width: 50px;
            text-align: center;
            height: 20px;
            line-height: 20px;
            background: #00AAFD;
            color: #fff;

            &.pickup {
              background: #f9ac2b;
            }

          }

          .order_no {
            font-size: 0.25rem;
            display: flex;
            justify-content: space-around;

            i {
              height: 0.38rem;
              line-height: 0.36rem;
              border: 1px solid #00C85E;
              width: 0.38rem;
              color: #00C85E;
              text-align: center;
              box-sizing: border-box;
              border-radius: 50%;
              font-style: normal;
            }

          }

          .address {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: left;
            .comment {
              font-size: 13px;
            }
          }

        }

      }

    }
  }

}
  
#mapWrap {
  height: 4.5rem;
}

/deep/.popup-bubble {
  text-align: center;
  font-size: 16px;
}
  

</style>
