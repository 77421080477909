<template>
  <div class="pc_excel_import" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="header">
      <!-- <div class="header_handle" v-if="orderId">
        <span class="header_btn" @click="goSummary">Summary</span>
      </div> -->
      {{editInfo.title}}
      <span @click="editTitle" class="title_edit_title"></span>
      <div class="help">
        <el-button class="add_btn" @click="goHelp" type="primary">Help</el-button>
      </div>
    </div>
    <div class="content_center">
      <div class="mapInfoContainer">
        <div class="map_center">
          <GroupList ref="groupLists" v-if="groupStatus" :tableDataNum="tableData.length"
          :unGroupNum="unGroupNum" :groupList="groupList" @groupListChange="groupListChange" 
          :needSave="needSave" :orderId="orderId" :setGroupStatus="setGroupStatus" :setGroupId="setGroupId"
          @selectGroupId="selectGroupId"
          @groupDeleteConfirm="groupDeleteConfirm"></GroupList>
          <div id="mapWrap">

          </div>
          <div id="setGroup" v-if="groupStatus && groupList.length">
            <el-button class="set_group" :class="setGroupStatus?'set_group_active':''"
            @click="setGroupFun" type="primary">{{setGroupStatus?'Disable set-group mode':'Enable set-group mode'}}</el-button>
          </div>
        </div>
        <p class="content_tips" ref="tablescroll">
          <span class="error_tips error_right" v-if="orderStatus">Paid orders cannot be saved!</span>
          <span v-if="groupStatus">Click on "Group#" field of each address to set group id.</span>
          <span v-else>* You can edit the content directly in the table below.</span>
          <span class="error_tips" v-if="showErrorTips">Please correct the highlighted address first.</span>
          <span class="error_tips error_right" v-if="noCompleteNum"
          >{{tableData.length}} imported   /  {{noCompleteNum}} unresolved addresses.</span>
          <template v-else>
            <el-button class="save_btn" v-if="groupStatus && !orderStatus" @click="goSave('auto')" type="primary">Save</el-button>
            <span v-if="groupStatus && needSave && !orderStatus" class="error_tips error_right plase_save">You need to save first before continue.</span>
          </template>
          <span class="same_address" v-if="sameAddressArr">! {{sameAddressArr}} have the same addresses!</span>
          <span class="pay-tips">{{payTips}}</span>
        </p>
      </div> 
      <div class="content_list" ref="tableDataContent" >
        <el-table
          class="upload_table"
          :data="tableData"
          ref="excelTable"
          empty-text="No data"
          :element-loading-text="'Loading progress: '+countNum+'/'+tableData.length"
          v-loading="countNum == tableData.length?false:true"
          :height="fixedHeight"
          :row-class-name="tableRowClassName"
          @row-click="rowClick"
          stripe border style="width: 100%">
          <el-table-column
            type="index"
            header-align="center"
            label="Index"
            width="50">
          </el-table-column>
          <el-table-column
            header-align="center"
            column-key="group"
            label="Group id" width="105">
            <template slot-scope="scope">
              <div v-if="!groupStatus" class="error-group-row">
                <div class="un_group">
                  ungrouped
                </div>
              </div>
              <div v-else>
                <div v-if="scope.row.newIndex" :class="scope.row.groupId == 'ungrouped'?'error-group-row':''">
                  <div class="un_group" v-if="scope.row.warnIndex" @click="groupClick()">
                    ungrouped
                  </div>
                  <div class="group_class" v-else>
                    <div class="group_bg" :style="{'backgroundColor':scope.row.groupColor}"></div>
                    <el-select v-model="scope.row.groupId" placeholder="" no-data-text="no-data"
                    :disabled="scope.row.disablededit"
                    @change="groupChange(scope.row.newIndex, scope.row.groupId)">
                      <el-option v-for="(ele,i) in groupList"
                        :key="i" :label="ele.groupName" :value="ele.groupId"></el-option>
                      <!-- <el-option label="ungrouped" :value="-1"></el-option> -->
                    </el-select>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="Address" header-align="center" column-key="address">
            <template slot-scope="scope">
              <div class="form_input" :class="scope.row.warnIndex?'error-row':''">
                <div class="input_item el-input-address">
                  <input type="text" :id="'senderAddress'+scope.row.startIndex"
                   class="autocompleteFiled" placeholder="" :disabled="scope.row.disablededit"
                   v-model="scope.row.address"/>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="room" label="Room#" width="80" header-align="center">
            <template slot-scope="scope">
              <el-input class="table_input" v-model="scope.row.room"
              :disabled="scope.row.disablededit" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="comment" label="Comment" header-align="center" width="120">
            <template slot-scope="scope">
              <div class="package_textarea">
                <el-input class="table_input" type="textarea" :autosize="{ minRows: 1, maxRows: 10}"
                v-model="scope.row.comment" :disabled="scope.row.disablededit" placeholder=""></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="packages_list" label="Package list" header-align="center" align="center" width="140">
            <template slot-scope="scope">
              <div class="package_textarea">
                <el-input class="table_input" type="textarea" :autosize="{ minRows: 1, maxRows: 10}"
                 v-model="scope.row.packageList" :disabled="scope.row.disablededit"
                  @change="packageAreaChange(scope.row)" placeholder=""></el-input>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="Phone" header-align="center" width="100">
            <template slot-scope="scope">
              <el-input class="table_input" v-model="scope.row.phone"
              :disabled="scope.row.disablededit" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="email" label="Email" header-align="center" width="100">
            <template slot-scope="scope">
              <el-input class="table_input" :disabled="scope.row.disablededit"
              v-model="scope.row.email" placeholder=""></el-input>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="pickupTime" label="Pickup time" width="110" header-align="center">
            <template slot-scope="scope">
              <el-select v-model="scope.row.pickupTime"
              :disabled="scope.row.disablededit" placeholder="">
                <el-option v-for="(ele,i) in pickupTimeOptions"
                  :key="i" :label="ele.label" :value="ele.label"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="weight" label="Weight" width="70" header-align="center">
            <template slot-scope="scope">
              <el-select v-model="scope.row.weight"
              :disabled="scope.row.disablededit" placeholder="">
                <el-option v-for="(ele,i) in checkWeight"
                  :key="i" :label="ele.title" :value="ele.rule"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="size" label="Size" width="70" header-align="center">
            <template slot-scope="scope">
              <el-select v-model="scope.row.size"
              :disabled="scope.row.disablededit" placeholder="">
               <el-option v-for="(ele,i) in checkSize"
                  :key="i" :label="ele.title" :value="ele.rule"></el-option>
              </el-select>
            </template>
          </el-table-column> -->
          <el-table-column prop="handle" label="Operations" width="100" header-align="center" align="center">
            <template slot-scope="scope" v-if="!scope.row.disablededit">
              <div class="handle_table">
                <div>
                  <p v-if="scope.row.newIndex == 0" class="start_top">pickup</p>
                  <el-button v-else @click="goStarting(scope.row)" class="start_btn" type="text" size="small">Set as pickup</el-button>
                </div>
                <el-button @click="goDelete(scope.row.newIndex)" type="text" size="small">Delete</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="handle_center">
        <div class="group-left"  v-if="tableData.length>0">
          <el-button class="add_btn group_btn_man"
           @click="autoGroup" type="primary">Group manually</el-button>
          <el-button class="opt_btn" @click="goOneRoute()" type="primary">Optimize all in one route</el-button>
          <!-- <el-button class="add_btn"
           @click="submit" type="primary">Submit</el-button> -->
        </div>
        <div class="group-right">
          <el-button class="add_again_btn" v-if="tableData.length>0" @click="uploadAgain" type="primary">Upload again</el-button>
          <div class="upload_btn" v-else>
            <UploadExcel :pickupTimeOptions="pickupTimeOptions" @handleList="handleList"></UploadExcel>
          </div>
          <el-button class="add_btn" @click="addStop" type="primary">Add stop</el-button>
          <el-button class="more_btn" v-if="tableData.length>0"
          type="primary" @click="showActionSheet">...</el-button>
        </div>
      </div>
    </div>
    <!-- <div class="use_request">
      <div>
        <el-checkbox v-model="userSaveReq">Save this request</el-checkbox>
      </div>
      <div class="btn_right">
        <p class="view_tem_ins" @click="()=>{showTemplateDialog = true}">Template instructions</p>
        <a class="download" href="/template_excel.xlsx">Download the template</a>
      </div>
    </div> -->
    <Action :show="actionSheetHelp" v-on:hide="hideActionSheetHelp" :showHelpBtn="true"
      @showTemplateDialogFun="showTemplateDialogFun"></Action>
    <Action :show="actionSheet" v-on:hide="hideActionSheet" :showClearRoute="true"
     v-on:goDeleteAll="goDeleteAll"></Action>
    <MultiDelete :deleteDialog="deleteDialog" :deleteType="deleteType"
     @deleteCancel="deleteCancel" @deleteConfirm="deleteConfirm"></MultiDelete>
    <PcMultiAdd ref="addChild" :addAStopDialog="addAStopDialog"
     @addHandleClose="addHandleClose" @confirmAdd="confirmAdd"></PcMultiAdd>
    <EditTitle :editTitleDialog="editTitleDialog"
     @editClose="editClose" @confirmEdit="confirmEdit"></EditTitle>
    <AddCreditCard :dialogShow.sync="addCreditDialog"
     @addCardSuccess="addCardSuccess"></AddCreditCard>
    <SeTipAndPay :dialogShow.sync="setTipDialog" :tableData="payTableData"
     @paySuccess="paySuccess"></SeTipAndPay>

    <!-- show template -->
    <el-dialog
      title="Template instructions"
      class="view_template_dialog"
      :visible.sync="showTemplateDialog"
      width="800px"
      center
      :close-on-click-modal="false"
      :before-close="()=>{showTemplateDialog = false}">
      <div class="template_img"><img src="./../../assets/image/template_img.png" alt="" srcset=""></div>
    </el-dialog>
    <el-dialog
      title="Again upload"
      class="again_dialog"
      :visible.sync="againDialog"
      width="400px"
      center
      :before-close="()=>{againDialog = false}">
      <div class="dialog_title">
        This will clear all the settings. Are you sure to continue?
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="againDialog = false">取 消</el-button>
        <div class="upload_btn">
          <UploadExcel :pickupTimeOptions="pickupTimeOptions" @closeAgainDialog="closeAgainDialog"
           @handleList="handleList"></UploadExcel>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from "moment"
  import Action from './../../components/actionSheet.vue'
  import MultiDelete from './../../components/multiDelete.vue'
  import PcMultiAdd from './../../components/pcMultiAdd.vue'
  import AddCreditCard from './../../components/addCreditCard.vue'
  import SeTipAndPay from './../../components/seTipAndPay.vue'
  import UploadExcel from '../../components/uploadExcel.vue'
  import GroupList from '../../components/groupList.vue'
  import EditTitle from '../../components/editTitle.vue'
  import {stripePay} from '../../mixin/stripePay.js'
  const groupBy = require('lodash/groupBy');
  const placeholder = 'Package A1-A10\nOne package per row'
  let GroupColors=['#46baef','#4eef46','#141814','#8e6dec','#f1836d',
  '#eaea3d', '#eedcdc','#bca968','#dceee9','#065b44','#e38bd6','#00a6ac','#867892','#f7acbc']
  let markersList = []
  export default {
    name: 'addMultiDelivery',
    mixins: [stripePay],
    data() {
      return {
        changeLock: false,
        map: null,
        fixedHeight: 0,
        needSave: false,
        tableData: [],
        errorIndex: 0,
        orderId: 0,
        showErrorTips: false,
        userSaveReq: true,
        noCompleteNum: 0,
        // dialog
        setGroupId: -1,
        setMarkIndex: '',

        actionSheet: false,
        actionSheetHelp: false,
        deleteDialog: false,
        deleteType: 'all', //all 代表全部删除 single 代表单条删除

        pickupTimeOptions: [],
        checkWeight: [],
        checkSize: [],
        addAStopDialog: false,
        addCreditDialog: false,
        setTipDialog: false,
        countNum: 0,
        loadingData: false,
        showTemplateDialog: false,

        groupStatus: false,
        manualDialog: false,
        manualRadio: 1,
        groupList: [],
        groupArr: [],
        unGroupNum: 0,

        editTitleDialog: false,
        editInfo: {
          title: 'Import & group',
          date:  moment().format('MM/DD')
        },
        sender_id: '',
        addListTemp: false,
        sameAddressArr: '',
        fullscreenLoading: false,
        payTips: '',
        payStatus: false,
        setGroupStatus: false,
        payTableData: [],
        orderStatus: 0,
        againDialog: false
      }
    },
    watch: {
      'tableData': {
        handler(val,oldVal){
          if (oldVal.length == 0) {
            return
          }
          this.needSave = true
          if (val.length > 0 && this.groupStatus) {
            this.$nextTick(()=>{
              this.$refs.groupLists.showRouteChange(0)
            })
          }
        },
        deep: true    //深度监听
      }
    },
    components: { Action, MultiDelete, PcMultiAdd, UploadExcel, GroupList, EditTitle, AddCreditCard, SeTipAndPay },
    created() {
      var allHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      // 通过捕获系统的onresize事件触发我们需要执行的事件
      this.fixedHeight = (allHeight - 75)*0.7 - 110
      let orderId = this.$route.query.id
      this.orderId = orderId
      if (this.orderId) {
        this.addListTemp = true
      }
      window.addEventListener('beforeunload', this.updateHandler)
      this.updatePayMothod();
    },
    mounted() {
      window.onresize = ()=>{
        var allHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
        // 通过捕获系统的onresize事件触发我们需要执行的事件
        this.fixedHeight = (allHeight - 75)*0.7 - 110
      }
      this.initMap()
      if(localStorage.getItem("loginInfo")){
        this.sender_id = JSON.parse(localStorage.getItem("loginInfo")).user_id;
      }
      if (!this.sender_id) {
        this.$router.push({
          path: "/loginUser",
          name,
          params: {
            info: "null"
          }
        })
        this.$message({
          message: "Please login.",
          type: 'info'
        });
        return;
      }
      if (this.orderId) {
        this.getRouteInfo()
      } else {
        if (localStorage.getItem("tableDataGroup")) {
          let localMultiList = JSON.parse(localStorage.getItem("tableDataGroup"))
          localMultiList.forEach(item => {
            item.showRow = 0
          })
          this.tableData = localMultiList
          this.handleSameAddress()
          if (localStorage.getItem('groupList')) {
            this.groupList = JSON.parse(localStorage.getItem("groupList"))
            if (this.groupList.length && this.tableData.length) {
              this.groupStatus = true
              this.countGroupNum()
            }
          }
          setTimeout(()=>{
            this.setMapOnAll(null)
            this.batchAddMark()
          },200)
        }
      }
      this.getPriceRule()
      this.initPageInfo()
    },
    beforeDestroy () {
      window.removeEventListener('beforeunload', this.updateHandler)
    },
    methods: {
      goHelp() {
        this.actionSheetHelp = true
      },
      showTemplateDialogFun() {
        this.showTemplateDialog = true
      },
      uploadAgain() {
        this.againDialog = true

      },
      goSummary() {
        const { href } = this.$router.resolve({
            name: "allocateCargo",
            query: {
              id: this.orderId,
              type: 'all'
            }
        });
        window.open(href, '_blank');
      },
      selectGroupId(id) {
        this.setGroupId = id
      },
      tableScrollMove(refName,index){
        if(!refName || !this.$refs[refName]) return//不存在表格的ref vm 则返回
        let vmEl = this.$refs[refName].$el
        if(!vmEl) return
        //计算滚动条的位置
        const targetTop = vmEl.querySelectorAll('.el-table__body tr')[index].getBoundingClientRect().top
        const containerTop = vmEl.querySelector('.el-table__body').getBoundingClientRect().top
        const scrollParent = vmEl.querySelector('.el-table__body-wrapper')
        scrollParent.scrollTop = targetTop - containerTop
      },
      groupListChange() {
        this.needSave = true
      },
      getRouteInfo() {
        this.fullscreenLoading = true
        this.$http({
          url: "/api/batch/order/info/v2",
          method: "GET",
          params: {
            id: this.orderId
          },
        }).then(res => {
          this.$message.closeAll();
          this.fullscreenLoading = false
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            var data = res.data.data
            var group = data.group
            var orderInfo = data.order[0]
            // this.orderStatus = orderInfo.status
            var order_name = data.order_name
            var groupList = []
            var addressList = []
            group.forEach((item,index)=>{
              var money = Number(item.cost + item.tip)?Number(item.cost + item.tip).toFixed(0):0
              money = money / 100
              var groupItem = {
                groupId: item.group_id,
                group_id: item.group_id,
                groupName: item.group_name,
                groupColor: GroupColors[item.group_id],
                total_time: item.total_time?item.total_time:0,
                groupNum: item.address_list.length,
                groupMoney: money,
                status: item.status
              }
              groupList.push(groupItem)
              addressList = addressList.concat(item.address_list)
              if (item.status == 1) {
                this.orderStatus = 1
              }
            })
            addressList.unshift(orderInfo)
            addressList.forEach((ele,i) => {
              ele.newIndex = i
              ele.startIndex = i
              ele.warnIndex = 0
              ele.showRow = 0
              var groupId = ele.group_id
              ele.groupBgIndex = groupId
              ele.groupId = groupId
              ele.groupName = ele.group_name
              ele.groupColor = GroupColors[groupId]
              var packs = ele.package_list
              var newPack = ''
              var pacListArr = []
              if (packs && packs.length) {
                packs.forEach(p => {
                  if (newPack) {
                    newPack = newPack + '\n'+p.name
                  } else {
                    newPack = p.name
                  }
                  pacListArr.push(p.name)
                })
              }
              ele.packageList = newPack
              ele.packages_list = pacListArr
              if (ele.status >= 5) {
                ele.disablededit = true
                groupList[groupId].disablededit = true
              }
            })
            this.groupList = groupList
            this.tableData = addressList
            this.handleSameAddress()
            this.editInfo = {
              title: order_name
            }
            this.groupStatus = true
            this.setMapOnAll(null)
            this.batchAddMark()
            this.$nextTick(()=>{
              this.$refs.groupLists.showRouteChange(1, this.orderId)
            })
          }
        }).catch(err => {
        })
      },
      editTitle() {
        this.editTitleDialog = true
      },
      editClose() {
        this.editTitleDialog = false
      },
      confirmEdit(info) {
        this.editInfo = info
        this.needSave = true
        this.editClose()
      },
      groupClick() {
        this.$message.closeAll();
        this.$message({
          showClose: false,
          message: 'Please correct the highlighted addresses first',
          type: 'warning'
        });
        return false
      },
      autoGroup() {
        if (this.groupStatus == false) {
          this.tableData.forEach((item,index) => {
            if (index != 0 && item.warnIndex == 0 && item.groupId == 0) {
              this.groupList[0].groupNum += 1
            }
          })
        }
        this.groupStatus = true
      },
      groupChange(cumlonIndex, groupId) {
        if (groupId == -1) {
          this.tableData[cumlonIndex].groupColor = ''
          this.tableData[cumlonIndex].groupBgIndex = -1
          this.tableData[cumlonIndex].groupId = -1
          this.tableData[cumlonIndex].groupName = 'ungrouped'
          if (this.tableData[cumlonIndex].latitude) {
            var domArr = document.getElementsByClassName('popup-bubble')
            for (var i= 0;i<domArr.length; i++) {
              var domItem = domArr[i]
              var domIndex = domItem.getAttribute("order") - 0
              if (domIndex == cumlonIndex) {
                domItem.setAttribute('groupbg',-1)
                domItem.style.backgroundImage = "";
                this.changeMarkClass(domItem)
                break
              }
            }
          }
        } else {
          var groupIndex = 0, groupName = ''
          this.groupList.forEach((ele,i)=>{
            if (ele.groupId == groupId) {
              groupIndex = i
              groupName = ele.groupName
            }
          })
          this.tableData[cumlonIndex].groupColor = this.groupList[groupIndex].groupColor
          this.tableData[cumlonIndex].groupBgIndex = groupId
          this.tableData[cumlonIndex].groupId = groupId
          this.tableData[cumlonIndex].groupName = groupName
          if (this.tableData[cumlonIndex].latitude && groupIndex >= 0) {
            var domArr = document.getElementsByClassName('popup-bubble')
            for (var i= 0;i<domArr.length; i++) {
              var domItem = domArr[i]
              var domIndex = domItem.getAttribute("order") - 0
              if (domIndex == cumlonIndex) {
                domItem.setAttribute('groupbg',groupId)
                this.changeMarkClass(domItem)
                break
              }
            }
          }
        }
        this.countGroupNum()
      },
      countGroupNum() {
        this.groupList.forEach(group => {
          group.groupNum = 0
        })
        var unNum = 0
        this.tableData.forEach(item=>{
          this.groupList.forEach((group,groupIndex) => {
            if(item.groupId == group.groupId) {
              group.groupNum += 1
              unNum++
            }
          })
        })
        var unN =  this.tableData.length - unNum - 1
        this.unGroupNum = unN >= 0?unN:0
      },
      deletegroup(index) {
        // 等确定向后台传参在加
      },
      packageAreaChange(item) {
        var pacList = []
        var oldList = item.packageList
        if (oldList) {
          pacList = oldList.split('\n')
        }
        var pacListArr = []
        pacList.forEach(ele => {
          var str = ele.replace(/\s+/g,"");   
          if (str) {
            pacListArr.push(ele)
          }
        })
        item.packages_list = pacListArr
      },
      rowClick(row, column, event) {
        this.tableData.forEach(item => {
          item.showRow = 0
        })
        if (row.latitude) {
          var domArr = document.getElementsByClassName('popup-bubble')
          for (var i= 0;i<domArr.length; i++) {
            var domItem = domArr[i]
            var domIndex = domItem.getAttribute("order") - 0
            if (domIndex == row.newIndex) {
              this.changeMarkClass(domItem)
              break
            }
          }
          this.centerMap(row)
        }
      },
      // 修改点标记的状态class
      changeMarkClass(domItem) {
        var lastDomArr = document.getElementsByClassName('nowhandle')
        if (lastDomArr && lastDomArr.length) {
          var lastDom = lastDomArr[0]
          var lastIndex = lastDom.getAttribute('order')
          this.tableData[lastIndex].showRow = 0
          lastDom.classList.remove('nowhandle')
        }
        var groupbg = domItem.getAttribute('groupbg')
        if (groupbg && groupbg >= 0) {
          domItem.style.backgroundImage = "url(" + require("../../assets/image/posi/posi"+groupbg+".png") + ")";
        }
        domItem.classList.add('nowhandle')
      },
      updateHandler() {
        if (this.tableData && this.tableData.length) {
          this.tableData.forEach((item,index) => {
            item.startIndex = item.newIndex
          })
          localStorage.setItem('tableDataGroup', JSON.stringify(this.tableData))
        }
        if (this.groupList && this.groupList.length) {
          localStorage.setItem('groupList', JSON.stringify(this.groupList))
        } 
      },
      initPageInfo() {
        let priceRule = JSON.parse(localStorage.getItem("priceRule"))
        this.checkWeight = priceRule.weightInfoArr
        this.checkSize = priceRule.sizeInfoArr
        const nowTime = moment().format('a');
        let timeArr = []
        if (nowTime == 'am') {
          timeArr = [moment().format('MM/DD')+' morning',moment().format('MM/DD')+' afternoon',
          moment().add(1, 'days').format('MM/DD')+' morning',moment().add(1, 'days').format('MM/DD')+' afternoon',
          moment().add(2, 'days').format('MM/DD')+' morning',moment().add(2, 'days').format('MM/DD')+' afternoon',]
        } else {
          timeArr = [moment().format('MM/DD')+' afternoon',
          moment().add(1, 'days').format('MM/DD')+' morning',moment().add(1, 'days').format('MM/DD')+' afternoon',
          moment().add(2, 'days').format('MM/DD')+' morning',moment().add(2, 'days').format('MM/DD')+' afternoon',]
        }
        var nowTimeArr = []
        timeArr.forEach((item,index) =>{
          nowTimeArr.push({
            label: item,
            value: index
          })
        })
        this.pickupTimeOptions = nowTimeArr
      },
      initAddressId(index) {
        let _this = this;
        this.loader.load().then(() => {
          let senderAddressEle = document.getElementById('senderAddress'+index);
          let autosenderAddress = new google.maps.places.Autocomplete(senderAddressEle);
          autosenderAddress.addListener("place_changed",()=>{
            let senderAddress = autosenderAddress.getPlace();
            if(!senderAddress.geometry){
              this.$message.closeAll();
              this.$message({
                showClose: false,
                message: 'Please enter the specific address',
                type: 'warning'
              });
            }else{
              _this.tableData[index].address = senderAddressEle.value
              _this.tableData[index].lastAddress = senderAddressEle.value
              _this.tableData[index].latitude = senderAddress.geometry.location.lat()
              _this.tableData[index].long = senderAddress.geometry.location.lng()
              _this.tableData[index].warnIndex = 0
              _this.changeWarnFun()
              _this.setMarkMap(index)
              _this.centerMap(_this.tableData[index])
            }
          })
          senderAddressEle.addEventListener('change', (val) => {
            if (_this.tableData[index].address != _this.tableData[index].lastAddress) {
              _this.tableData[index].latitude = ''
              _this.tableData[index].long = ''
              _this.tableData[index].warnIndex = 1
              _this.changeWarnFun()
              if (markersList[index]) {
                markersList[index].setMap(null)
              }
            }
          })
        });
      },
      changeWarnFun() {
        var list = this.tableData
        var temp = false
        var noNum = 0
        for (var i= 0; i<list.length;i++){
          var item = list[i]
          if (item.warnIndex == 1) {
            temp = true
            noNum ++
          }
        }
        this.showErrorTips = temp
        this.noCompleteNum = noNum
      },
      initMap() {
        let _this = this;
        this.loader.load().then(() => {
          _this.map = new google.maps.Map(document.getElementById("mapWrap"), {
            center: {
              lat: 37.382767,
              lng: -122.023248
            },
            zoom: 10,
          });
        });
      },
      centerMap(centerPiont) {
        let centerPos;
        centerPos = {
          lat: centerPiont.latitude,
          lng: centerPiont.long
        }
        this.map.setCenter(centerPos)
      },
      tableRowClassName({row, rowIndex}) {
        if (row.showRow == 1) {
          return 'show_row'
        }
        if (rowIndex === 0) {
          return 'warning-row';
        }
        return '';
      },
      handleList(newLists) {
        this.orderId = ''
        if (this.$route.query.id) {
          this.$router.push({
            path: this.$route.path,
            query: {
            }
          })
        }
        this.groupList = []
        this.groupList.push({
          groupName: 'default',
          groupNum: 0,
          groupId: 0,
          groupColor: GroupColors[0],
          time:'',
          groupMoney: 0,
          status: 0
        })
        localStorage.setItem('groupList', JSON.stringify(this.groupList))
        newLists.forEach((item, index) => {
          if (index > 0) {
            item.groupId = this.groupList[0].groupId
            item.groupName = this.groupList[0].groupName
            item.groupBgIndex = this.groupList[0].groupId
            item.groupColor = GroupColors[0]
          } else {
            item.groupId = 'ungrouped',
            item.groupName = 'ungrouped'
            item.groupBgIndex = -1
            item.groupColor = ''
          }
        })
        this.tableData = newLists
        this.handleSameAddress()
        this.groupStatus = false
        this.setMapOnAll(null)
        this.initMap()
        localStorage.setItem('tableDataGroup', JSON.stringify(this.tableData))
        this.batchAddMark()
      },
      closeAgainDialog() {
        this.againDialog = false
      },
      handleSameAddress() {
        var mapStorage = {};
        this.sameAddressArr = ''
        this.tableData.forEach((item, index) => {
          var address = item.address
          if (!mapStorage[address]) {
            mapStorage[address] = {
              total: 1,
              indexArr: [index+1]
            } 
          } else {
            mapStorage[address].total++;
            mapStorage[address].indexArr.push(index+1);
          }
        })
        for(var i in mapStorage) {
          var item = mapStorage[i]
          if (item.total > 1) {
            if (this.sameAddressArr) {
              this.sameAddressArr += ', ' + '(' + item.indexArr + ')'
            } else {
              this.sameAddressArr += '('+item.indexArr+')'
            }
          }
        }
      },
      batchAddMark() {
        markersList = []
        this.countNum = 0
        this.tableData.forEach((item,index)=>{
          this.initAddressId(index)
          if (item.latitude) {
            item.warnIndex = 0
            this.setMarkMap(index)
            this.countNum += 1
            this.changeWarnFun()
          } else {
            setTimeout(() => {
              this.addressTransformLat(item.address, index)
            }, 300*index);
          }
        })
        // if (this.countNum > 0) {
        //   if (this.countNum == this.tableData.length) {
        //     var latlngbounds = new google.maps.LatLngBounds();
        //     for (var i = 0; i < markersList.length; i++) {
        //       if (markersList[i]) {
        //         latlngbounds.extend(markersList[i].position);
        //       }
        //     }
        //     this.map.fitBounds(latlngbounds)
        //   }
        // }
      },
      addressTransformLat(address, index) {
        var geocoder = new google.maps.Geocoder();//创建geocoder服务
        /* 调用geocoder服务完成转换 */
        var that = this
        geocoder.geocode( { 'address': address}, function(results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results && results.length == 1) {
              that.tableData[index].latitude = results[0].geometry.location.lat()
              that.tableData[index].long = results[0].geometry.location.lng()
              that.tableData[index].warnIndex = 0
              that.setMarkMap(index)
            } else {
              that.tableData[index].warnIndex = 1
              that.tableData[index].latitude = ''
              that.tableData[index].long = ''
            }
            that.countNum += 1
            that.changeWarnFun()
          } else {
            // alert('Geocode was not successful for the following reason: ' + status);
            // 清空address
            if (status == 'OVER_QUERY_LIMIT'){
              setTimeout(()=>{
                that.addressTransformLat(address, index)
              },400)
            } else {
              that.tableData[index].warnIndex = 1
              that.tableData[index].latitude = ''
              that.tableData[index].long = ''
              that.countNum += 1
              that.changeWarnFun()
            }
          }
          if (that.countNum > 0) {
            if (that.countNum == that.tableData.length) {
              var latlngbounds = new google.maps.LatLngBounds();
              for (var i = 0; i < markersList.length; i++) {
                if (markersList[i]) {
                  latlngbounds.extend(markersList[i].position);
                }
              }
              that.map.fitBounds(latlngbounds)
            }
          }
        })  
      },
      setMarkMap(i) {
        var item = {...this.tableData[i]}
        var _this = this
        if (i == 0) {
          let contentSender = document.createElement("div");
          let sender = this.tableData[i]
          contentSender.innerText = (i+1)
          contentSender.classList.add('sender')
          contentSender.setAttribute("type", "sender")
          contentSender.setAttribute("order", i)
          let popupSender = new this.Popup(
            new google.maps.LatLng(sender.latitude, sender.long),
            contentSender,
            _this.bubbleClick
          );
          popupSender.setMap(this.map);
          if (markersList[i]) {
            markersList[i].setMap(null);
          }
          markersList[i] = popupSender
        } else {
          let contentReceiver = document.createElement("div");
          let receiver = this.tableData[i]
          contentReceiver.innerText = (i+1)
          contentReceiver.classList.add('receiver')
          contentReceiver.setAttribute("order", i)
          if (receiver.groupBgIndex || receiver.groupBgIndex == 0) {
            contentReceiver.setAttribute("groupbg", receiver.groupBgIndex)
            if (receiver.groupBgIndex >= 0) {
              contentReceiver.style.backgroundImage = "url(" 
              + require("../../assets/image/posi/posi"+receiver.groupBgIndex+".png") + ")";
            }
          }
          let popupReceiver = new this.Popup(
            new google.maps.LatLng(receiver.latitude, receiver.long),
            contentReceiver,
            _this.bubbleClick
          );
          popupReceiver.setMap(this.map);
          if (markersList[i]) {
            markersList[i].setMap(null);
          }
          markersList[i] = popupReceiver
        }
      },
      bubbleClick(e) {
        let ele = e.target;
        let index = ele.getAttribute("order")
        this.setMarkIndex = index
        if (this.setGroupStatus) {
          if (this.groupList.length == 0 && index == 0) {
          } else {
            var groupIndex = 0, groupName = ''
            this.groupList.forEach((ele,i)=>{
              if (ele.groupId == this.setGroupId) {
                groupIndex = i
                groupName = ele.groupName
              }
            })
            this.tableData[index].groupColor = this.groupList[groupIndex].groupColor
            this.tableData[index].groupBgIndex = this.setGroupId
            this.tableData[index].groupId = this.setGroupId
            this.tableData[index].groupName = groupName
            ele.setAttribute('groupbg',this.setGroupId)
            this.countGroupNum()
         }
        }
        this.tableData[index].showRow = 1
        this.changeMarkClass(ele)
        this.tableScrollMove('excelTable',index)
      },
      setGroupFun() {
        this.setGroupStatus = !this.setGroupStatus
        if (!this.setGroupStatus) {
          this.setGroupId = -1
        } else {
          this.setGroupId = this.groupList[0].groupId
          this.$notify({
            title: 'Reminder',
            duration: 2000,
            message: 'Click to select one group on the left, then click on addresses on the map to set as the same group.'
          })
        }
      },
      // delete
      groupDeleteConfirm(groupId) {
        this.$http({
          url: "/api/order/group/del/v2",
          method: "POST",
          data: {
            order_id: this.orderId,
            group_id: groupId,
          },
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            var domArr = document.getElementsByClassName('popup-bubble')
            for (let i = 0; i < domArr.length; i++) {
              var domItem = domArr[i]
              var groupbg = domItem.getAttribute('groupbg')
              if (groupbg == groupId) {
                domItem.setAttribute('groupbg',-1)
                domItem.style.backgroundImage = ''
              }
            }
            this.tableData.forEach(item => {
              if (item.groupId == groupId) {
                item.groupName = 'ungrouped'
                item.groupId = 'ungrouped'
                item.group_id = this.groupList[0].groupId
                item.groupBgIndex = -1
                item.groupColor = ''
              }
            })
            this.$refs.groupLists.deleteCancel()
          }
        }).catch(err => {
        })
      },
      goDelete(index) {
        // 清除数组中指定对象并更新缓存
        this.deleteType = 'single'
        this.errorIndex = index
        this.deleteDialog = true
      },
      goDeleteAll() {
        // 清除multi list和缓存 pickupNum deliveryNum
        this.deleteType = 'all'
        this.deleteDialog = true
      },
      deleteCancel() {
        this.deleteDialog = false
        this.hideActionSheet()
      },
      deleteConfirm() {
        if (this.deleteType == 'all') {
          this.tableData = []
          this.groupList = []
          this.unGroupNum = 0
          this.groupStatus = false
          this.countNum = 0
          this.noCompleteNum = 0
          this.showErrorTips = false
          localStorage.setItem('groupList', JSON.stringify(this.groupList))
          this.setMapOnAll(null);
          this.orderId = ''
          if (this.$route.query.id) {
            this.$router.push({
              path: this.$route.path,
              query: {
              }
            })
          }
        } else {
          this.tableData.splice(this.errorIndex,1)
          this.countNum -= 1
          if (markersList[this.errorIndex]) {
            markersList[this.errorIndex].setMap(null)
          }
          markersList.splice(this.errorIndex, 1)
        }
        if (this.tableData.length >0) {
          this.tableData.forEach((item, index) => {
            item.newIndex = index
          })
          var domArr = document.getElementsByClassName('popup-bubble')
          for (let i = 0; i < domArr.length; i++) {
            var domItem = domArr[i]
            var domIndex = domItem.getAttribute("order") - 0
            if (this.errorIndex == 0 && domIndex == 1) {
              domItem.classList.remove('receiver')
              domItem.classList.add('sender')
            }
            if (domIndex > this.errorIndex) {
              domItem.innerText = domIndex
              domItem.setAttribute("order", domIndex-1)
            }
          }
        }
        this.countGroupNum()
        localStorage.setItem('tableDataGroup', JSON.stringify(this.tableData))
        this.$message.closeAll();
        this.$message({
          showClose: false,
          message: 'Successfully deleted',
          type: 'warning'
        });
        this.deleteCancel()
      },
      setMapOnAll(map) {
        for (let i = 0; i < markersList.length; i++) {
          if (markersList[i]) {
            markersList[i].setMap(map);
          }
        }
      },
      showActionSheet() {
        this.actionSheet = true
      },
      hideActionSheet() {
        this.actionSheet = false
      },
      hideActionSheetHelp() {
        this.actionSheetHelp = false
      },
      goStarting(item) {
        var index = item.newIndex
        var startItem = {...item}
        startItem.groupName = 'ungrouped'
        startItem.groupId = 'ungrouped'
        startItem.groupBgIndex = -1
        startItem.groupColor = ''
        this.tableData.splice(index, 1)
        this.tableData.unshift(startItem)
        this.tableData.forEach((ele, i) => {
          ele.newIndex = i
        })
        var markers = markersList[index]
        markersList.splice(index, 1)
        markersList.unshift(markers)
        var domArr = document.getElementsByClassName('popup-bubble')
        for (let i = 0; i < domArr.length; i++) {
          var domItem = domArr[i]
          var domIndex = domItem.getAttribute("order") - 0
          if (domIndex == index) {
            domItem.innerText = 1
            domItem.setAttribute("order", 0)
            domItem.setAttribute("groupbg",-1)
            domItem.setAttribute("style",'')
            domItem.classList.remove('receiver')
            domItem.classList.add('sender')
          } else if (domIndex < index){
            domItem.innerText = domIndex+2
            domItem.setAttribute("order", domIndex+1)
            if (domIndex == 0) {
              domItem.classList.remove('sender')
              domItem.classList.add('receiver')
            }
          }
        }
        this.countGroupNum()
      },
      addStop() {
        this.addAStopDialog = true
        this.$refs.addChild.addAddressSearch()
      },
      addHandleClose() {
        this.addAStopDialog = false
      },
      confirmAdd(addForm) {
        addForm.pickupTime = this.pickupTimeOptions[0].label
        var newIndex = this.tableData.length
        addForm.startIndex = newIndex
        addForm.newIndex = newIndex
        this.tableData.push(addForm)
        localStorage.setItem('tableDataGroup', JSON.stringify(this.tableData))
        this.unGroupNum += 1
        this.initAddressId(newIndex)
        this.addressTransformLat(addForm.address, newIndex)
        this.addHandleClose()
        this.$nextTick(() => {
          let container = this.$el.querySelector('.upload_table .el-table__body-wrapper');
          container.scrollTop = container.scrollHeight;
        })
      },
      goSave(source) {
        this.goOpt(source)
      },
      goOneRoute() {
        if (this.groupStatus && this.groupList.length > 0) {
          this.$confirm('This will clear all the existing group configurations. Are you sure to continue?', 'Warning', {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            this.groupStatus = false
            this.goOpt()
          }).catch(() => {         
          });
        } else {
          this.goOpt()
        }
      },
      goOpt(source) {
        if (this.tableData.length < 2) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please add at least 2 delivery',
            type: 'warning'
          });
          return false
        } 
        var warnTips = false
        this.tableData.forEach((item,index) => {
          if (!item.address) {
            item.latitude = ''
            item.long = ''
          }
          if (!item.latitude) {
            item.warnIndex = 1
            warnTips = true
          } else {
            item.warnIndex = 0
          }
        })
        if (warnTips) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please select the address from the address list',
            type: 'warning'
          });
          return false
        }
        let allList = this.tableData
        let pickup_list = []
        let dropoff_list = []
        allList.forEach((item,index) => {
          var pacList = item.packages_list
          var pacListArr = []
          if (pacList && pacList.length) {
            pacList.forEach(ele => {
              var str = ele.replace(/\s+/g,"");   
              var packItem = {}
              if (str) {
                packItem.status = 0
                packItem.name = str
                pacListArr.push(packItem)
              }
            })
          }
          var submitObj = {...item}
          submitObj.package_list = pacListArr
          submitObj.image_info = []
          submitObj.status = 1
          submitObj.phone = item.phone+''
          submitObj.email = item.email+''
          submitObj.comment = item.comment+''
          submitObj.room = item.room+''
          submitObj.cost = 0
          submitObj.pick_time=item.pickupTime
          submitObj.third_id=item.third_id
          if (this.groupList && this.groupList.length) {
            this.groupList.forEach((group,i)=>{
              if (item.groupId == group.groupId) {
                submitObj.group_id = group.groupId
                submitObj.group_name = group.groupName
              }
            })
          }
          if (submitObj.groupId == 'ungrouped') {
            submitObj.group_id = this.groupList[0].groupId
            submitObj.group_name = 'default'
          }
          if (!this.groupStatus) {
            submitObj.group_id = 0
            submitObj.group_name = 'default'
          }
          if (index == 0) {
            pickup_list.push(submitObj)
          } else {
            dropoff_list.push(submitObj)
          }
        })
        this.goSubmit(pickup_list, dropoff_list, source)
      },
      goSubmit(pickup_list, dropoff_list, source) {
        if (this.groupStatus) {
          var newDropList = []
          var ungroupedList = []
          this.groupList.forEach((element, i) => {
            element.list = []
            dropoff_list.forEach(ele => {
              if (i == ele.group_id) {
                element.list.push(ele)
              }
            })
            newDropList = newDropList.concat(element.list)
          });
          dropoff_list.forEach(ele => {
            if (ele.group_id == this.groupList.length) {
              ungroupedList.push(ele)
            }
          })
          newDropList = newDropList.concat(ungroupedList)
          dropoff_list = newDropList
        }
        if(this.changeLock) {
          return;
        }
        var time_value = 0, group_count = 0
        if (source == 'manual') {
          if (this.manualRadio == 1) {
            time_value = 1 
          } else{
            group_count = 1
          }
        }
        this.changeLock = true;
        this.fullscreenLoading = true
        this.$http({
	        url: "/api/batch/order/create/v2",
	        method: "POST",
          data: {
            pickup_list: pickup_list,
            dropoff_list: dropoff_list,
            order_name: this.editInfo.title,
            group_count: group_count,
            time_value: time_value,
            id: this.orderId
          }
	      }).then(res => {
          this.changeLock = false;
          this.fullscreenLoading = false
	        this.$message.closeAll();
	        if(res.data.code){
	          this.$message({
	            message: res.data.message,
	            type: 'error'
	          });
	        }else{
            this.orderId = res.data.data.id
            if (this.groupStatus) {
              this.$nextTick(()=>{
                this.$refs.groupLists.showRouteChange(1,this.orderId)
              })
            }
            this.needSave = false
            this.$message({
              message: 'Save success',
              type: 'success'
            });
            if (this.$route.query.id) {
            } else {
              this.$router.push({
                path: this.$route.path,
                query: {
                  id: this.orderId
                }
              })
            }
            if (!source) {
              this.tableData = []
              this.groupList = []
              this.$router.push({
                path: "/multiOptimizedRoute",
                query: {
                  id: this.orderId,
                  groupId: 0
                }
              }).catch(err => {err})
            }
	        }
	      }).catch(()=>{
          this.changeLock = false;
        })
      },
      checkGroupCount(arrLen) {
        // 任何一组数量超过40个提示 More than xx addresses in one group. Need to split first (group mannually).
        // More than 40 stops in group-default. Please reduce to less than 40 stops per group.
        var list = JSON.parse(JSON.stringify(this.tableData))
        list = list.slice(1,list.length)
        const groupedData = groupBy(list, 'groupName');
        let flag = true;
        for ( let k in groupedData) {
          const ln = groupedData[k].length;
          if (ln > arrLen) {
            flag = false;
            const msg = `More than ${arrLen} stops in group-${k}. Please reduce to less than 40 stops per group.`;
            this.$alert(msg, 'Warning', {
              confirmButtonText: 'OK',
            });
            break
          }
        }
        if (flag) {
          return true
        } else {
          return false
        }
      },
      /**
       * 判断是否有creditcard,有=》设置小费tip和支付
       * 无=》设置信用卡
       */
      submit() {
        // 以groupName将数据分组
        if (!this.checkGroupCount(40)) {
          return false
        }
        var list = JSON.parse(JSON.stringify(this.tableData))
        list = list.slice(1,list.length)
        // 分组数量验证通过
        if (this.needSave) {
          this.$message({
            message: 'You need to save first before continue.',
            type: 'warning'
          });
          return false
        }
        this.judgeBindOrPay(list);
      },
      async judgeBindOrPay(list) {
        this.payTableData = list
        if (!this.payMethods || !this.payMethods.length) {
          // 绑卡
          this.addCreditDialog = true;
        } else {
          // 设置小费 支付
          this.setTipDialog = true;
        }
      },
      // 支付成功
      paySuccess(payObj) {
        console.log(payObj)
        this.payTips = payObj.msg;
        var group_list = payObj.params.group_list
        group_list.forEach((obj,index) => {
          this.groupList[index].groupMoney = (obj.tip+obj.item)/100
        })
        this.payStatus = true
        this.orderStatus = 1
      }
    }
  }
</script>
<style lang="scss">
@import './../../assets/css/pc_import.scss';
</style>