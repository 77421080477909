//actionSheet.vue   

<template>
<div>
  <el-dialog class="end_location_dialog" title="Set end location (e.g. home)" :visible.sync="locationVisible"
   :show-close="false" :close-on-click-modal="false" width="83%">
    <p class="re_title">Enter your end location, then reoptimize the route. </p>
    <div class="singleInfoInput">
      <div class="el-input">
        <input type="text" id="senderAddress" class="autocompleteFiled"
         :placeholder="endLocation.end_location_name" />
      </div>
    </div>
    <span slot="footer" class="dialog-footer custom-footer">
      <div class="handle_class">
        <el-button @click="hideAction">Cancel</el-button>
        <el-button class="actionSummary" :loading="btnLoading" @click="correntAction">Confirm</el-button>
      </div>
      <div class="remove">
        <el-button class="remove_end_location" @click="removeLocation">Remove end location</el-button>
      </div>
    </span>
 
  </el-dialog>
</div>

</template>
<script>
    export default {
      props: {
        locationVisible: {
          default: function() {
            return false
          },
          type: Boolean
        },
        parEndLocation: {
          default: function() {
            return {}
          },
          type: Object
        }
      },
      data() {
        return {
          endLocation: {},
          btnLoading: false
        }
      },
      watch: {
        locationVisible(val) {
          if (val) {
            this.$nextTick(() => {
              this.initInputField()
            })
          }
        },
        parEndLocation(val) {
          if (val) {
            this.endLocation = val
            this.endLocation.end_location_name = val.address
          }
        }
      },
      mounted() {
      },
      methods: {
        hideAction(){
          this.endLocation = {}
          document.getElementById('senderAddress').value = ''
          this.$emit('hideEndLocation')
        },
        removeLocation() {
          document.getElementById('senderAddress').value = ''
          this.$emit('removeLocation')
        },
        correntAction() {
          if (this.endLocation && this.endLocation.lng) {
            this.btnLoading = true
            this.$emit('saveEndLoction',this.endLocation)
            this.btnLoading = false
          } else {
            this.$message({
              showClose: false,
              message: 'Please enter the specific address',
              type: 'warning'
            });
          }
        },
        initInputField() {
          let _this = this;
          this.loader.load().then(() => {
            let senderAddressEle = document.getElementById('senderAddress');
            let autosenderAddress = new google.maps.places.Autocomplete(senderAddressEle);
            autosenderAddress.addListener("place_changed", () => {
              let senderAddress = autosenderAddress.getPlace();
              const address = senderAddress.formatted_address
              if(!senderAddress.geometry) {
                this.$message.closeAll();
                this.$message({
                  showClose: false,
                  message: 'Please enter the specific address',
                  type: 'warning'
                });
              } else {
                let positionObj = {
                  lat: senderAddress.geometry.location.lat(),
                  lng: senderAddress.geometry.location.lng(),
                  end_location_name: address
                }
                this.endLocation = positionObj
              }
            })
          });
        },
      }
    }
</script>
<style lang="scss">
.end_location_dialog {
  .el-dialog__body {
    padding: 15px;
    .re_title {
      width: 100%;
      line-height: 0.5rem;
      margin-bottom: 0.3rem;
      // text-align: left;
    }
    .singleInfoInput {
      .el-input {
        width: 100%;
        border-radius: 0.1rem;
      }
    }
  }
  .custom-footer {
    display: flex;
    flex-direction: column;
    .handle_class {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
    }
  }
  .remove_end_location {
    color: red;
  }
}

</style>