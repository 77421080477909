<template>
  <div class="deliveryInfo">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="deliveryTitle">Delivery info</p>
    </div>
    <div class="deliveryForm">
    	<div class="senderWrap">
    		<p class="senderTitle">
    		  Sender
    		  <span class="usePrevious" v-if="tempSenderAddress" @click="usePreviousAddress">Use previous</span>
    		</p>
    		<div class="singleInfo">
          <div class="singleInfoTitle">
            <span class="infoMust" v-if="senderAddress.must">*</span>
            <span v-text="senderAddress.title"></span>
          </div>
          <div class="singleInfoInput">
            <div class="el-input">
            	<input type="text" id="senderAddress" class="autocompleteFiled" placeholder="" v-model="senderAddress.value"/>
            </div>
          </div>
        </div>
    		<div class="singleInfo" v-for="(item,key) in sender">
    		  <div class="singleInfoTitle">
    		    <span class="infoMust" v-if="item.must">*</span>
    		    <span v-text="item.title"></span>
    		  </div>
    		  <div class="singleInfoInput">
    		    <el-input v-model="item.value" :placeholder="item.info"></el-input>
    		  </div>
    		</div>
    		<div class="pickUpDate" @click="showTimePickChange">
    		  Pickup date
    		  <div class="choosedDate">
    		    <p v-text="getdate('days',pickUpTime)"></p>
    		    <p v-text="getdate('date',pickUpTime)"></p>
    		    <p >{{specTime}}</p>
    		  </div>
    		</div>
    		<div class="selectPickUpDate" v-if="showTimePick">
          <div class="optionDateWrap">
          	<div class="optionDate" v-for="(item,key) in pickUpOption" @click="selectDate(item)" :class="item.selected? 'active' : ''">
          	  {{item.days + ' ' + item.month + "/" + item.day + "/" + item.year}}
          	</div>
          </div>
          <div class="AMPMWrap">
            <div class="AMPM" :class="specTime == 'AM'? 'active' : ''" @click="selectSpecTime('AM')">AM</div>
            <div class="AMPM" :class="specTime == 'PM'? 'active' : ''" @click="selectSpecTime('PM')">PM</div>
          </div>
        </div>
        <div class="actionCommon" v-if="showTimePick" @click="confirmDate">Continue</div>
    	</div>
    	<div class="ReceiverWrap">
        <p class="senderTitle">Receiver</p>
        <div class="singleInfo">
          <div class="singleInfoTitle">
            <span class="infoMust" v-if="receiverAddress.must">*</span>
            <span v-text="receiverAddress.title"></span>
          </div>
          <div class="singleInfoInput">
            <div class="el-input">
              <input type="text" id="receiverAddress" class="autocompleteFiled" placeholder="" v-model="receiverAddress.value"/>
            </div>
          </div>
        </div>
        <div class="singleInfo" v-for="(item,key) in receiver">
          <div class="singleInfoTitle">
            <span class="infoMust" v-if="item.must">*</span>
            <span v-text="item.title"></span>
          </div>
          <div class="singleInfoInput">
            <el-input v-model="item.value" :placeholder="item.info"></el-input>
          </div>
        </div>
        <p class="DeliveryDate">
          <span class="DeliveryTitle">Delivery date:</span>
          <span v-text="DeliveryTime" class="specTime"></span>
        </p>
      </div>
      <div class="actionCommon" @click="pageCoutinue">Continue</div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'deliveryInfo',
    components: {},
    data() {
      return {
        tempSenderAddress:undefined,
        senderAddress:{
          title:"Address",
          must:true,
          value:"",
          position:undefined
        },
        receiverAddress:{
          title:"Address",
          must:true,
          value:"",
          position:undefined
        },
        sender:{
          Room:{
            title:"Room#",
            info:"block/room/suite#",
            must:false,
            value:""
          },
          Name:{
            title:"Name",
            must:true,
            value:""
          },
          Phone:{
            title:"Phone#",
            must:true,
            value:""
          },
          Email:{
            title:"Email",
            must:false,
            value:""
          },
          Comment:{
            title:"Comment",
            must:false,
            value:""
          }
        },
        receiver:{
          Room:{
            title:"Room#",
            info:"block/room/suite#",
            must:false,
            value:""
          },
          Name:{
            title:"Name",
            must:true,
            value:""
          },
          Phone:{
            title:"Phone#",
            must:true,
            value:""
          },
          Email:{
            title:"Email",
            must:false,
            value:""
          },
          Comment:{
            title:"Comment",
            must:false,
            value:""
          }
        },
        pickUpTime:{},
        pickUpOption:[],
        DeliveryTime:'',
        showTimePick:false,
        specTime:""
      }
  },
  watch:{
    specTime(){
      this.DeliveryTime = "same day";
    }
  },
  mounted() {
    this.initInputField();
    this.checkTempData()
    this.pickUpOption = this.getDateOption();
  },
  methods: {
    initInputField(){
      let _this = this;
      this.loader.load().then(() => {
        let senderAddressEle = document.getElementById('senderAddress');
        let receiverAddressEle = document.getElementById('receiverAddress');
        let autosenderAddress = new google.maps.places.Autocomplete(senderAddressEle);
        let autoreceiverAddress = new google.maps.places.Autocomplete(receiverAddressEle);
        autosenderAddress.addListener("place_changed",()=>{
          let senderAddress = autosenderAddress.getPlace();
          if(!senderAddress.geometry){
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Please enter the specific address',
              type: 'warning'
            });
          }else{
            _this.senderAddress.value = senderAddressEle.value;
            _this.senderAddress.position = {
              lat:senderAddress.geometry.location.lat(),
              lng:senderAddress.geometry.location.lng()
            }
          }
        })
        autoreceiverAddress.addListener("place_changed",()=>{
          let receiverAddress = autoreceiverAddress.getPlace();
          if(!receiverAddress.geometry){
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Please enter the specific address',
              type: 'warning'
            });
          }else{
            _this.receiverAddress.value = receiverAddressEle.value;
            _this.receiverAddress.position = {
              lat:receiverAddress.geometry.location.lat(),
              lng:receiverAddress.geometry.location.lng()
            }
          }
        })
      });
    },
    usePreviousAddress(){
      this.senderAddress = this.tempSenderAddress;
      this.sender = JSON.parse(localStorage.getItem("senderInfo"))
    },
    checkTempData(){
      // TODO
      this.tempSenderAddress = JSON.parse(localStorage.getItem("senderAddress"));
      localStorage.getItem("senderInfo") ? this.sender = JSON.parse(localStorage.getItem("senderInfo")) : null;
      localStorage.getItem("senderAddress") ? this.senderAddress = JSON.parse(localStorage.getItem("senderAddress")) : null;
      localStorage.getItem("receiverAddress") ? this.receiverAddress = JSON.parse(localStorage.getItem("receiverAddress")) : null;
      localStorage.getItem("senderInfo") ? this.sender = JSON.parse(localStorage.getItem("senderInfo")) : null;
      localStorage.getItem("receiverInfo") ? this.receiver = JSON.parse(localStorage.getItem("receiverInfo")) : null;
      localStorage.getItem("dateInfo") ? this.pickUpTime = JSON.parse(localStorage.getItem("dateInfo")) : null;
      localStorage.getItem("specTimeInfo") ? this.specTime = JSON.parse(localStorage.getItem("specTimeInfo")) : null;
    },
    goBack(){
      this.jumpRoute("mainPage")
    },
    jumpRoute(path){
      this.$router.push({
        path: "/" + path,
        name,
        params:{
          info: "null"
        }
      }).catch(err => {err})
    },
    pageCoutinue(){
      let checkArr = [this.senderAddress,this.sender.Phone,this.sender.Name,this.receiverAddress,this.receiver.Phone,this.receiver.Phone];
      let positionCheckArr = [this.senderAddress.position,this.receiverAddress.position]
      let checkPass = true;
      for (let i = 0; i < checkArr.length; i++) {
      	if(!checkArr[i].value){
      	  checkPass = false;
      	  break;
      	}
      }
      let checkPositionPass = true;
      for (let i = 0; i < positionCheckArr.length; i++) {
        if(!positionCheckArr[i]){
          checkPositionPass = false;
          break;
        }
      }
      console.log(checkPositionPass,this.senderAddress,this.receiverAddress)
      this.$message.closeAll();
      if(checkPass && checkPositionPass){
        if(this.specTime){
          localStorage.setItem("senderAddress",JSON.stringify(this.senderAddress));
          localStorage.setItem("receiverAddress",JSON.stringify(this.receiverAddress));
          localStorage.setItem("senderInfo",JSON.stringify(this.sender));
          localStorage.setItem("receiverInfo",JSON.stringify(this.receiver));
          localStorage.setItem("dateInfo",JSON.stringify(this.pickUpTime));
          localStorage.setItem("specTimeInfo",JSON.stringify(this.specTime));
          this.jumpRoute('packageInfo')          
        }else{
          this.$message({
            showClose: false,
            message: 'Please select pickup date',
            type: 'warning'
          });
        }
        
      }else if(!checkPass){
        this.$message({
          showClose: false,
          message: 'Please input necessary information',
          type: 'warning'
        });
      }else if(!checkPositionPass){
        this.$message({
          showClose: false,
          message: 'Please enter the specific address',
          type: 'warning'
        });
      }
    },
    getdate(type){
      if(!Object.keys(this.pickUpTime).length){
        return '';
      }
      if(type == 'days'){
        return this.pickUpTime.days;
      }else if(type == "date"){
        return this.pickUpTime.month + "/" + this.pickUpTime.day + "/" + this.pickUpTime.year
      }
    },
    confirmDate(){
      if(!this.specTime || !Object.keys(this.pickUpTime).length){
        this.$message({
          type: "warning",
          message: "please select pickup date"
        });
        return;
      }
      this.showTimePickChange()
    },
    getdays(days) {
      switch(days) {
        case 1:days = 'Monday';break;
        case 2:days = 'Tuesday';break;
        case 3:days = 'Wednesday';break;
        case 4:days = 'Thursday';break;
        case 5:days = 'Friday';break;
        case 6:days = 'Saturday';break;
        case 0:days = 'Sunday';break;
      }
      return days;
    },
    showTimePickChange(){
      this.showTimePick = !this.showTimePick;
    },
    selectDate(item){
      for (let i = 0; i < this.pickUpOption.length; i++) {
      	this.pickUpOption[i].selected = false;
      }
      item.selected = true;
      this.pickUpTime = item;
    },
    selectSpecTime(specTime){
      this.specTime = specTime;
    },
    getDateOption() {
      let optionArr = [];
      for(let i = 0; i < 3; i++) {
        let optionValue = (new Date()).getTime() + 86400000 * i
        let date = new Date(optionValue);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1);
        let day = (date.getDate());
        let days = date.getDay()
        optionArr.push({
          month,
          day,
          year,
          days:this.getdays(days),
          selected:false
        })
      }
      return optionArr
//      let date = new Date();
//      let year = date.getFullYear();
//      let month = (date.getMonth() + 1);
//      let day = (date.getDate());
//      let hours = (date.getHours());
//      let minutes = (date.getMinutes());
//      let seconds = (date.getSeconds());
//      //返回数据
//      return month + '/' + day + '/' + year;
      }
    }
  }
</script>
<style scoped="scoped">
.deliveryInfo{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.deliveryTitle{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}
.deliveryForm{
  width: 7.5rem;
  position: relative;
  top: 1.4rem;
  left: 0;
  height: calc(100% - 1.4rem);
  margin: auto;
  padding: 0.5rem 0.2rem;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.senderTitle{
  text-align: left;
  font-size: 0.48rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FF3232;
  line-height: 0.66rem;
  margin-bottom: 0.6rem;
}
.usePrevious{
  float: right;
  font-size: 0.36rem;
  line-height: 0.66rem;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #00C85E;
  text-decoration: underline;
}
.singleInfo{
  font-size: 0;
  padding-bottom: 0.6rem;
}
.singleInfoTitle{
  width: calc(100% - 5rem);
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3D3D3;
  line-height: 0.58rem;
  text-align: left;
}
.singleInfoTitle,.singleInfoInput{
  display: inline-block;
}
.infoMust{
  color: #FE1C1C;
}
.deliveryInfo .actionCommon{
  margin: auto;
  margin-bottom: 1.2rem;
}
.pickUpDate{
  width: 2.7rem;
  height: 0.88rem;
  border-radius: 0.44rem;
  background: #00C85E;
  font-size: 0.36rem;
  font-family: Helvetica;
  color: #FFFFFF;
  line-height: 0.88rem;
  margin-top: 0.6rem;
  margin-bottom: 1.2rem;
  position: relative;
}
.DeliveryDate{
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3D3D3;
  line-height: 0.58rem;
  text-align: left;
  margin-bottom: 1.2rem;
}
.specTime{
  color: #606266;
  padding: 0 0.2rem;
}
.optionDate{
  font-size: 0.36rem;
  font-family: Menlo-Regular, Menlo;
  font-weight: 400;
  color: #4C4C4C;
  width: 4.96rem;
  height: 1.28rem;
  line-height: 1.28rem;
  background: #F8F8F8;
  border-radius: 0.12rem;
  border: 0.02rem solid #EAEAEA;
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.optionDate.active{
  color: #FF3232;
  border: 0.02rem solid #FFB2B2;
}
.AMPM{
  width: 1.74rem;
  height: 1.28rem;
  line-height: 1.28rem;
  background: #F8F8F8;
  border-radius: 0.12rem;
  border: 0.02rem solid #EAEAEA;
  font-size: 0.36rem;
  font-family: Menlo-Regular, Menlo;
  font-weight: 400;
  color: #4C4C4C;
  margin-bottom: 0.4rem;
}
.AMPM.active{
  color: #FF3232;
  border: 0.02rem solid #FFB2B2;
}
.selectPickUpDate{
  display: flex;
  justify-content: space-between;
  margin-top: -0.3rem;
  margin-bottom: 0.6rem;
}
.choosedDate{
  position: absolute;
  left: 3rem;
  top: -0.8rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4C4C4C;
}
</style>