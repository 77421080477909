<template>
  <div class="verifyStepOne verifyStep">
    <div class="header">
      <div class="user_icon" @click="jumpRoute('registerDriver')"><i class="el-icon-arrow-left"></i></div>
      <p class="stepOneTitle">Step1</p>
    </div>
    <div class="stepOneForm">
      <div class="formWrap">
        <div class="singleFormItem">
          <p class="formItemTitle">
            Driver License#
          </p>
          <div class="inputWrap">
            <el-input type="text" v-model="formObj.DriverLicense" placeholder="Driver License#"></el-input>
          </div>
        </div>
        <div class="photoUploadWrap">
          <p class="photoTitle">
            Take clear pictures of front and back side of your driver license
          </p>
          <div class="photoBtnWrap">
            <div @click="choosePic">
              <input id="fileImage" class="fileImage" type="file" accept="image/*" size="1" style="display: none;" @change="filechange">
              <img src="../../assets/image/takePhoto.png" />
            </div>
          </div>
          <p class="takePhotoTitle">Take Photo{{uploadPhotoArr.length ? `(${uploadPhotoArr.length} uploaded)` : ""}}</p>
        </div>
        <div class="photoPreview">
          <img :src="item" v-for="item in photoArr" />

        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Social Security Number
          </p>
          <div class="inputWrap">
            <el-input type="text" v-model="formObj.SocialNumber" placeholder="Social Security Number"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Cell phone number
          </p>
          <div class="inputAndBtn">
            <div class="inputWrap">
              <el-input type="text" v-model="formObj.PhoneNumber" placeholder="Cell phone number"></el-input>
            </div>
            <div class="sendCode" :class="codeTimeout ? '' : 'ignore'" @click="sendCode" v-text="codeTimeout ? 'send verification code':'resend later'"></div>
          </div>
          <div class="inputWrap">
            <el-input type="text" v-model="formObj.verifyCode" placeholder=" input verification code"></el-input>
          </div>
        </div>

      </div>
      <div class="actionCommon" @click="stepOneDealData">Continue</div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'verifyStepOne',
    components: {
      cusCheckbox
    },
    data() {
      return {
        formObj: {
          DriverLicense: "",
          SocialNumber: "",
          PhoneNumber: "",
          verifyCode: "",
        },
        photoArr: [],
        uploadPhotoArr: [],
        registedEmail: "",
        codeTimeout: true
      }
    },
    mounted() {
      this.registedEmail = localStorage.getItem("registedEmail");
      this.uploadPhotoArr = localStorage.getItem("uploadedImg") ? JSON.parse(localStorage.getItem("uploadedImg")) : [];
      let stepOneData = localStorage.getItem("stepOneData");
      if(stepOneData) {
        this.formObj = JSON.parse(stepOneData);
      }
    },
    methods: {
      stepOneDealData() {
        let inputPass = true;
        for(let key in this.formObj) {
          if(!this.formObj[key]) {
            inputPass = false;
          }
        }
        if(inputPass) {
          localStorage.setItem("stepOneData", JSON.stringify(this.formObj))
          this.verifyCode()
        } else {
          this.$message.closeAll();
          this.$message({
            message: 'Please complete input field',
            type: 'warning'
          });
        }
      },
      jumpRoute(path) {
        this.$router.push({
          path: "/" + path,
          name,
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      },
      getSrc(file) {
        let srcObj = {};
        let _this = this;
        let imgReader = new FileReader();
        imgReader.readAsDataURL(file);
        imgReader.onload = function(evt) {
          srcObj.src = evt.target.result
        }
        return srcObj
      },
      filechange(e) {
        console.log(e.target.files);
        if(e.target.files.length) {
          this.uploadFile(e.target.files[0])
        }
      },
      uploadFile(file) {
        let uploadFileName = file.name.split(".");
        if(uploadFileName.length > 1) {
          //存在后缀名
          uploadFileName = uploadFileName[uploadFileName.length - 1]
        } else {
          this.$message.closeAll();
          this.$message({
            message: "Unrecognized file format",
            type: 'error'
          });
          return;
        }
        let tempfile = new File([file], "driver_" + this.registedEmail + "_" + this.photoArr.length + "." + uploadFileName);
        let formdatafile = new FormData();
        formdatafile.append("upload", tempfile);
        this.$http({
          url: "/api/file/a",
          method: "POST",
          data: formdatafile,
          uploadFile: true
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            this.generateImgSrc(tempfile);
            this.uploadPhotoArr.push(res.data.data.f)
            localStorage.setItem("uploadedImg", JSON.stringify(this.uploadPhotoArr));
          }
        })
      },
      generateImgSrc(file) {
        let _this = this;
        let imgReader = new FileReader();
        imgReader.readAsDataURL(file);
        imgReader.onload = function(evt) {
          _this.photoArr.push(evt.target.result)
        }

      },
      verifyCode() {
        this.$http({
          url: "/api/phone/invalid",
          method: "get",
          params: {
            phone: (this.formObj.PhoneNumber).toString(),
            verify_code: (this.formObj.verifyCode).toString()
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.jumpRoute('verifyStepTwo')
          }
        })
      },
      sendCode() {
        if(this.codeTimeout && (this.formObj.PhoneNumber).toString()) {
          this.codeTimeout = false;
          setTimeout(() => {
            this.codeTimeout = true;
          }, 60000)
          this.$http({
            url: "/api/message/send/code",
            method: "get",
            params: {
              phone: (this.formObj.PhoneNumber).toString()
            }
          }).then(res => {
            this.$message.closeAll();
            if(res.data.code) {
              this.codeTimeout = true;
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            } else {
              this.$message({
                message: "Code has sended to your Phone.",
                type: 'success'
              });
            }
          })
        }
      },
      choosePic() {
        document.getElementById("fileImage").click()
      },
    }
  }
</script>
<style scoped="scoped">
  .verifyStepOne {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .stepOneTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  
  .stepOneForm {
    width: 7.5rem;
    position: relative;
    top: 1.4rem;
    left: 0;
    height: calc(100% - 1.4rem);
    margin: auto;
    padding: 0.2rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .verifyStepOne .actionCommon {
    margin: auto;
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
  }
  
  .formWrap {
    margin-top: 0.3rem;
  }
  
  .singleFormItem {
    font-size: 0;
  }
  
  .formItemTitle {
    font-size: 0.42rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.9rem;
    text-align: left;
    padding: 0 0.2rem;
    box-sizing: border-box;
  }
  
  .inputAndBtn {
    overflow: hidden;
  }
  
  .inputAndBtn .inputWrap {
    width: 55%;
    float: left;
    margin-bottom: 0.2rem;
  }
  
  .sendCode.ignore {
    background: #D3D3D3;
  }
  
  .sendCode {
    float: right;
    width: 44%;
    height: 0.88rem;
    border-radius: 0.2rem;
    background: #00C85E;
    font-size: 0.3rem;
    font-family: Helvetica;
    color: #FFFFFF;
    line-height: 0.88rem;
  }
  
  .photoUploadWrap {
    padding: 0 0.2rem;
    text-align: left;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #9F9F9F;
    line-height: 0.44rem;
  }
  
  .photoBtnWrap {
    width: 3.42rem;
    height: 2.28rem;
    border-radius: 0.24rem;
    border: 0.02rem dashed #B1CADB;
    padding: 0.16rem;
    box-sizing: border-box;
    margin: 0.2rem auto;
    position: relative;
  }
  
  .photoBtnWrap>div {
    background: #EEF7FD;
    border-radius: 0.24rem;
    width: 100%;
    height: 100%;
  }
  
  .photoBtnWrap>div>img {
    width: 1rem;
    height: 1rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }
  
  .takePhotoTitle {
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #41A738;
    line-height: 0.44rem;
    text-align: center;
    margin-bottom: 0.56rem;
  }
  
  .photoPreview {
    width: 100%;
    height: 2rem;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .photoPreview>img {
    max-width: 50%;
    height: 90%;
    margin: 0 0.2rem;
  }
</style>