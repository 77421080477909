<template>
  <div class="infoShow contact_us">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="infoShowTitle">CONTACT US</p>
    </div>
    <div class="contentWrap">
 <p class="infoContent">Email: <a href="mailto:shiptal.com@gmail.com" style="text-decoration:none;color:#000;">shiptal.com@gmail.com</a><br>
Cell: 619-693-6666<br>
Address: Suite 1869, 8407 Central Ave, Newark, CA 94560<br>

          </p>
    </div>
  </div>
  
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'aboutUs',
    components: {},
    data() {
      return {
        infoTitle:"",
        userType:"",
        infoContent:"sss",
        infoUrl:""
      }
    },
    mounted() {
      this.userType = this.$route.query.type;
      let info = this.$route.query.info;
      if(info == "protocol"){
        if(this.userType == "user"){
          this.infoTitle = "User protocol"
          this.infoUrl = "/protocol_user.pdf"
        }else if(this.userType == "driver"){
          this.infoTitle = "Driver protocol"
          this.infoUrl = "/protocol_driver.pdf"
        }
      }else{
        this.infoTitle = "Privacy Policy"
        if(this.userType == "user"){
          this.infoUrl = "/privacy_user.pdf"
        }else if(this.userType == "driver"){
          this.infoUrl = "/privacy_driver.pdf"
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
<style lang="scss">
.infoShow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.infoShowTitle {
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}
.contact_us {
  .contentWrap {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  .infoContent{
    padding: 0.4rem;
    text-align: left;
    font-size: 0.28rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 0.7rem;
  }
}

</style>