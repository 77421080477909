<template>
  <div class="infoShow aboutus">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="infoShowTitle">ABOUT US</p>
    </div>
    <div class="contentWrap">
        <p class="infoContent">
<b>Shiptal has many features, including: </b><br>
> Route optimization<br>
> SMS reminder<br>
> Grouping: allocating tasks to many drivers<br>
> Loading & dropping assist, etc<br><br>
Closely designed with customers, Shiptal is much more efficient than other routing softwares.<br><br>
Shiptal can help you in two ways:<br>
1. If you have your own drivers, Shiptal can provides software service to greatly reduce your loading and delivery burden. For now, our software service is free, only serving customers in bay area, CA.<br>
2. Shiptal has drivers to deliver your packages, with cost around $5 per address in most cases.<br>
<br><br>
Contact us:<br>
Email: <a href="mailto:shiptal.com@gmail.com" style="text-decoration:none;color:#333;">shiptal.com@gmail.com</a><br>
Cell: 6196936666<br>
Address: Suite 1869, 8407 Central Ave, Newark, CA 94560<br>

          </p>
    </div>
  </div>
  
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'aboutUs',
    components: {},
    data() {
      return {
        infoTitle:"",
        userType:"",
        infoContent:"sss",
        infoUrl:""
      }
    },
    mounted() {
      this.userType = this.$route.query.type;
      let info = this.$route.query.info;
      if(info == "protocol"){
        if(this.userType == "user"){
          this.infoTitle = "User protocol"
          this.infoUrl = "/protocol_user.pdf"
        }else if(this.userType == "driver"){
          this.infoTitle = "Driver protocol"
          this.infoUrl = "/protocol_driver.pdf"
        }
      }else{
        this.infoTitle = "Privacy Policy"
        if(this.userType == "user"){
          this.infoUrl = "/privacy_user.pdf"
        }else if(this.userType == "driver"){
          this.infoUrl = "/privacy_driver.pdf"
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
<style lang="scss">
.aboutus {
  .infoShow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .infoShowTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  .contentWrap {
    width: 70%;
    height: 100%;
    position: absolute;
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  .infoContent{
    padding: 0.4rem;
    text-align: left;
    text-indent: 0.5rem;
    font-size: 0.28rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 0.5rem;
  }
}
</style>