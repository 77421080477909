<template>
  <div class="verifyStepThree verifyStep">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="stepOneTitle">Step3</p>
    </div>
    <div class="stepThreeForm">
      <div class="formWrap">
        <div class="singleFormItem">
          <p class="formItemTitle">
            Vehicle type
          </p>
          <div class="inputWrap">
            <el-select v-model="formObj.type" placeholder="Vehicle type" @change="typeChange">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Make
          </p>
          <div class="inputWrap">
            <el-select v-model="formObj.make" placeholder="Make" @change="makeChange">
              <el-option v-for="item in makeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Model
          </p>
          <div class="inputWrap">
            <el-select v-model="formObj.model" placeholder="Model" @change="modelChange">
              <el-option v-for="item in modelOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">
            Color
          </p>
          <div class="inputWrap">
            <el-select v-model="formObj.color" placeholder="Color">
              <el-option v-for="item in colorOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="actionCommon" @click="driverInfoComplete">Done</div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'verifyStepThree',
    components: {
      cusCheckbox
    },
    data() {
      return {
        typeOptions: [],
        makeOptions: [],
        modelOptions: [],
        colorOptions: [{
          value: 'car_color_1',
          label: 'car_color_1'
        }],
        formObj: {
          type: "",
          make: "",
          model: "",
          color: ""
        },
        stepOneData: {},
        stepTwoData: {},
        imgList: [],
        email: "",
        password: "",
        carInfo: {}
      }
    },
    mounted() {
      this.getCarInfo();
      this.email = localStorage.getItem("registedEmail");
      this.password = localStorage.getItem("registedPassword");

      let stepThreeData = localStorage.getItem("stepThreeData");
      if(stepThreeData) {
        this.formObj = JSON.parse(stepThreeData);
      }
      let stepOneData = localStorage.getItem("stepOneData");
      if(stepOneData) {
        this.stepOneData = JSON.parse(stepOneData);
      }
      let stepTwoData = localStorage.getItem("stepTwoData");
      if(stepTwoData) {
        this.stepTwoData = JSON.parse(stepTwoData);
      }
      let uploadImg = localStorage.getItem("uploadedImg");
      if(uploadImg) {
        this.imgList = JSON.parse(uploadImg);
      }
    },
    methods: {
      typeChange() {
        this.formObj.make = "";
        this.formObj.model = "";
        let carMake = Object.keys(this.carInfo[this.formObj.type])
        let makeOptions = [];
        carMake.map(function(item) {
          makeOptions.push({
            value: item,
            label: item
          })
        })
        this.makeOptions = makeOptions;

      },
      makeChange() {
        this.formObj.model = "";
        let carModel = this.carInfo[this.formObj.type][this.formObj.make]
        let modelOptions = [];
        carModel.map(function(item) {
          modelOptions.push({
            value: item,
            label: item
          })
        })
        this.modelOptions = modelOptions;
      },
      modelChange() {
        // do nothing
      },
      getCarInfo() {
        this.$http({
          url: "/api/car/get",
          method: "GET",
          params: {}
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            let carType = Object.keys(res.data.data)
            this.carInfo = res.data.data;
            let colorString = "White,silver Bai,yellow,red,blue,green,black";
            let colorArr = colorString.split(",");
            this.colorOptions = colorArr.map(function(item) {
              return item = {
                value: item,
                label: item
              }
            })
            let typeOptions = [];
            carType.map(function(item) {
              typeOptions.push({
                value: item,
                label: item
              })
            })
            this.typeOptions = typeOptions;
          }
        })
      },
      driverInfoComplete() {
        let inputPass = true;
        for(let key in this.formObj) {
          if(!this.formObj[key]) {
            inputPass = false;
          }
        }
        if(inputPass) {
          localStorage.setItem("stepThreeData", JSON.stringify(this.formObj))
          this.submitData();
          //				this.jumpRoute('verifyStatus')
        } else {
          this.$message.closeAll();
          this.$message({
            message: "Please complete your car's information",
            type: 'warning'
          });
        }
      },
      submitData() {
        let tempdata = this.dealRegisterData();
        console.log(tempdata)
        //return;
        this.$http({
          url: "/api/driver/register",
          method: "POST",
          data: tempdata
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            /*
          localStorage.removeItem("stepOneData")
          localStorage.removeItem("uploadedImg")
          localStorage.removeItem("registedPassword")
          localStorage.removeItem("stepTwoData")
          localStorage.removeItem("stepThreeData")
          */
            localStorage.clear()
            this.$router.push({
              path: "/verifyStatus",
              name: "verifyStatus",
              params: {
                info: 1
              }
            }).catch(err => {
              err
            })
          }
        })
      },
      dealRegisterData() {
        let tempData = {
          driver_license: this.stepOneData.DriverLicense,
          social_security_number: this.stepOneData.SocialNumber,
          take_photo_list: this.imgList.join(","),
          first_name: this.stepTwoData.first,
          middle_name: this.stepTwoData.hasMiddle ? this.stepTwoData.middle : "",
          last_name: this.stepTwoData.last,
          zip_code: this.stepTwoData.zip,
          home_address: this.stepTwoData.address,
          delivery_tool: this.formObj.type,
          make: this.formObj.make,
          model: this.formObj.model,
          color: this.formObj.color,
          driver_id: "",
          driver_name: "",
          status: 0,
          level: 0,
          star: 0,
          expected_city: 0,
          withdrawal_time: "",
          total_salary: 0,
          withdrawal_salary: 0,
          card: "",
          home_city: 0,
          phone: this.stepOneData.PhoneNumber,
          email: this.email,
          password: this.password,
        }
        return tempData;
      },
      goBack() {
        this.$router.go(-1);
      },
      jumpRoute(path) {
        this.$router.push({
          path: "/" + path,
          name,
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      }
    }
  }
</script>
<style scoped="scoped">
  .verifyStepThree {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .stepOneTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  
  .stepThreeForm {
    width: 7.5rem;
    position: relative;
    top: 1.4rem;
    left: 0;
    height: calc(100% - 1.4rem);
    margin: auto;
    padding: 0.2rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .verifyStepThree .actionCommon {
    margin: auto;
    margin-top: 0.6rem;
    margin-bottom: 1.2rem;
  }
  
  .formWrap {
    margin-top: 0.3rem;
  }
  
  .singleFormItem {
    font-size: 0;
  }
  
  .formItemTitle {
    font-size: 0.42rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.9rem;
    text-align: left;
    padding: 0 0.2rem;
    box-sizing: border-box;
  }
</style>