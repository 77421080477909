<template>
  <div>
    <el-dialog
      title="Add credit card"
      class="edit_dialog add_a_stop_dialog "
      :visible.sync="dialogShow"
      width="92%"
      center
      :close-on-click-modal="false"
      :before-close="addHandleClose">
      <div class="add_content">
        <form id="card-form">
          <div class="form-item">
            <div class="form-item-label">Full name(on then card)</div>
            <div class="form-item-content">
              <input id="card-name" type="text" class="el-input__inner" placeholder="Full name">
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">Card number</div>
            <div class="form-item-content">
              <div id="card-number"></div>
            </div>
          </div>
          <el-row>
            <el-col :span="12" class="pr-10">
              <div class="form-item">
                <div class="form-item-label">Expriation</div>
                <div class="form-item-content">
                  <div id="card-expiry"></div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="form-item">
                <div class="form-item-label">CVC</div>
                <div class="form-item-content">
                  <div id="card-cvc"></div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div slot="footer" class="bottom_btn">
            <span class="el-button el-button--warning" @click="addHandleClose" >Cancel</span>
            <button type="submit" class="el-button el-button--primary">
              <div v-if="creditLock" class="spinner"></div>
              <div v-else>Submit</div>
            </button>
          </div>
        </form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {stripePay} from '../mixin/stripePay.js'
  export default {
    name: 'creditCardAdd',
    mixins: [stripePay],
    props: {
      dialogShow: {
        default: function() {
          return false
        },
        type: Boolean
      }
    },
    data() {
      return {
        boundHandler: null,
        creditLock: false,
        setupIntent: null
      }
    },
    watch: {
      dialogShow(val) {
        if (val) {
          setTimeout(() => {
            this.initStripe();
          }, 20)
        }
      },
    },
    mounted() {
      this.getKey()
    },
    methods: {
      initStripe() {
        const elementStyles = {
          base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "14px",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
        }
        const elementClasses = {
          base: "stripe-input el-input__inner"
        }

        const stripe = Stripe(this.server_pub_key);
        const elements = stripe.elements();

        const cardNumber = elements.create('cardNumber', {
          style: elementStyles,
          classes: elementClasses,
        });
        cardNumber.mount('#card-number');

        const cardExpiry = elements.create('cardExpiry', {
          style: elementStyles,
          classes: elementClasses,
        });
        cardExpiry.mount('#card-expiry');

        const cardCvc = elements.create('cardCvc', {
          style: elementStyles,
          classes: elementClasses,
        });
        cardCvc.mount('#card-cvc');

        this.registerElements([cardNumber, cardExpiry, cardCvc], stripe);
      },
      registerElements(elements, stripe) {
        const form = document.getElementById('card-form');
        this.boundHandler = this.formSubmit.bind(this, elements, stripe); 
        form.addEventListener('submit', this.boundHandler);
      },
      addHandleClose(e) {
        document.querySelector('#card-name').value = '';
        this.$emit('update:dialogShow', false);

        const form = document.getElementById('card-form');
        form.removeEventListener('submit', this.boundHandler);
        this.boundHandler = null;
        this.creditLock = false;
      },
      /**
       * createToken => createCard
       * createSetupIntent => confirmCardSetup => confirmCardPayment
       */
      async formSubmit(elements, stripe) {
        event.preventDefault();
        const form = document.getElementById('card-form');

        const name = form.querySelector('#card-name').value;
        if (!name) {
          this.$message({ showClose: false, message: 'Please enter the cardholder name', type: 'warning' });
          return false;
        }

        if (this.creditLock) return false;
        this.creditLock = true;
        this.setupIntent = await this.createSetupIntent();
        stripe.confirmCardSetup(
          this.setupIntent.client_secret, {
            payment_method: {
              card: elements[0],
              billing_details: {
                name,
              },
            },
          }
        ).then((result) => {
          console.log(result);
          if (result.error) {
            this.creditLock = false;
            this.$message({message: result.error.message, type: 'error'});
          } else {
            this.addHandleClose();
            this.$message({message: 'Credit card added successfully', type: 'success'});
            setTimeout(() => {
              this.$emit('addCardSuccess');
              // localStorage.setItem('payment_method', result.setupIntent.payment_method);
            }, 200);

          }
        });

        // stripe.createToken(elements[0], { name }).then((result) => {
        //   if (result.error) {
        //     this.creditLock = false;
        //     this.$message({message: result.error.message, type: 'error'});
        //   } else {
        //     this.createCard(result.token.id);
        //   }
        // });

      },
    }
  }
</script>
<style scoped="scoped" lang="scss">
.pr-10 {
  padding-right: 10px;
}
/deep/.add_a_stop_dialog {
  >.el-dialog {
    max-width: 700px;
  }

  .el-dialog__footer {
    padding: 20px;
  }

  #card-form {
    .form-item {
      margin-bottom: 15px;
      &-label {
         margin-bottom: 8px;
      }
    }
  }

  .bottom_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 20px;

    .el-button {
      display: inline-block;
      width: 100px;
      height: 40px;
      padding: 0;
      line-height: 40px;
    }

    .el-button:nth-child(2) {
      margin-left: 30px;
    }

    .spinner:before, .spinner:after {
      background: teal!important;
    }

    button:hover, button:focus {
      .spinner:before, .spinner:after {
        background: #339999!important;
      }
    }

  }
  
}
.stripe-input {
  padding-top: 10px;
}
</style>