<template>
  <div class="register">
    <div class="header">
      <p class="registerTitle">Register</p>
    </div>
    <div class="registerWrap">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="formWrap">
        <p class="formTitle">Email</p>
        <p>
          <el-input v-model="Email" placeholder="Email"></el-input>
        </p>
        <p class="formTitle">Password</p>
        <p>
          <el-input v-model="Password" placeholder="Password" show-password></el-input>
        </p>
        <div class="policyWrap">
          <p class="readPolicy">
            I have read agree to the terms of service and privacy policy
            <span class="policyLink" @click="routeToProtocol">{{msg1}}</span> &nbsp;&nbsp;&nbsp;
            <span class="policyLink" @click="routeToPolicy">{{msg2}}</span>
          </p>
          <cusCheckbox :checkedObj="policyChecked"></cusCheckbox>
        </div>
      </div>
      <div class="actionCommon" @click="userRegesiter">Continue</div>
    </div>
  </div>
</template>

<script>
  import md5 from "js-md5"
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'register',
    components: {
      cusCheckbox
    },
    data() {
      return {
        type: "user",
        Email: "",
        Password: "",
        policyChecked: {
          checked: false
        },
        msg1: `《user protocol》`,
        msg2: `《Privacy Policy》`,
      }
    },
    mounted() {
      if(localStorage.getItem("registedEmail")) {
        this.Email = localStorage.getItem("registedEmail");
      }
      if(this.$route.path == "/registerDriver") {
        this.type = "driver";
      } else {
        this.type = "user";
      }
    },
    methods: {
      userRegesiter() {
        if(!this.policyChecked.checked) {
          this.$message.closeAll();
          this.$message({
            message: 'Please read the terms of service and privacy policy',
            type: 'warning'
          });
          return;
        }
        console.log(this.Email, md5(this.Password), this.type, this.$route.path)
        if(this.type == "user") {
          this.userRegister();
        } else {
          localStorage.setItem("registedEmail", this.Email);
          localStorage.setItem("registedPassword", md5(this.Password))
          this.$router.push({
            path: "/verifyStepOne",
            params: {
              info: "null"
            }
          }).catch(err => {
            err
          })
        }
      },
      userRegister() {
        this.$http({
          url: "/api/user/register",
          method: "POST",
          data: {
            email: this.Email,
            password: md5(this.Password)
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            localStorage.setItem("registedEmail", this.Email)
            this.$router.push({
              path: "/mainPage",
              name,
              params: {
                info: "null"
              }
            }).catch(err => {
              err
            })
          }
        })
      },
      routeToProtocol() {
        this.$router.push({
          path: "/infoShow",
          query: {
            type:this.type,
            info:"protocol"
          }
        }).catch(err => {
          err
        })
      },
      routeToPolicy() {
        this.$router.push({
          path: "/infoShow",
          query: {
            type:this.type,
            info:"Policy"
          }
        }).catch(err => {
          err
        })
      }
    }
  }
</script>
<style scoped="scoped">
  .register {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .registerTitle {
    font-size: 0.48rem;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #4C4C4C;
    line-height: 1.4rem;
    padding-left: 0.4rem;
    text-align: left;
  }
  
  .registerWrap {
    font-size: 0;
    position: relative;
    top: 1.4rem;
    height: calc(100% - 1.4rem);
  }
  
  .logo>img {
    margin: 1.2rem 0;
    width: 2.8rem;
    height: 2.02rem;
  }
  
  .formTitle {
    font-size: 0.42rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 0.58rem;
    text-align: left;
  }
  
  .formWrap>* {
    padding: 0 0.4rem 0.12rem 0.4rem;
    width: 6.7rem;
    margin: auto;
  }
  
  .formWrap .el-input {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  
  .policyWrap {
    position: relative;
    height: 0.74rem;
  }
  
  .cusCheckbox {
    top: 0.1rem;
  }
  
  .readPolicy {
    float: right;
    text-align: left;
    font-size: 0.27rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4c4c4c;
    line-height: 0.34rem;
    width: calc(100% - 0.7rem);
    margin: initial;
  }
  
  .policyLink {
    color: #6690FF;
  }
  
  .registerWrap>.actionCommon {
    margin: auto;
    margin-top: 0.8rem;
  }
</style>