<template>
  <div class="mainPage">
    <div class="header">
      <div class="center_info">
        {{mainCity}}
      </div>
      <div class="user_icon" @click="toggleMenu = !toggleMenu">
        <img src="../../assets/image/history.png" />
      </div>
      <!-- <div class="menu_icon" @click="checkJump('myMultiOrder')">
        <img src="../../assets/image/history.png" />
      </div> -->
    </div>
    <div class="contentWrap" @click="checkToggleMenu">
      <div id="mapContainer">

      </div>
      <div class="actionArea">
        <div class="areaWrap">
          <div class="request actionCommon" @click="checkJump('submitMultiStopReq')">Submit delivery request</div>
          <div class="request actionCommon"
           @click="goPcImportPay">Import & group</div>
        </div>
      </div>
    </div>
    <div class="toggleMenus" v-show="toggleMenu">
      <div>
        <img src="../../assets/image/logo.png" />
        <p v-if="name" class="nameTitle">{{name}}</p>
        <span v-else @click="jumpRoute('loginUser')">Login</span>
      </div>
      <!--<div class="actionMenu" @click="checkJump('wallet')">Wallet</div>-->
      <div class="actionMenu" @click="jumpRoute('myMultiOrder')">My request</div>
      <div class="actionMenu normal" @click="jumpRoute('setting')">Setting</div>
      <div class="actionMenu normal" @click="jumpRoute('contactUs')">Contact us</div>
      <div class="actionMenu normal" @click="jumpRoute('aboutUs')">About</div>
      <div class="actionMenu normal" @click="jumpRoute('howToUse')">How to use</div>
      <div class="actionMenu danger" @click="logout" v-if="name">Logout</div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'mainPage',
    components: {},
    data() {
      return {
        mainCity: "",
        name: "",
        isMobile: false,
        toggleMenu: false,
        isPCFlag: false
      }
    },
    mounted() {
      this.getPriceRule()
      this.name = localStorage.getItem("loginName")
      this.mainCity = localStorage.getItem("mainCity")
      this.initMap()
      this.IsPC()
    },
    methods: {
      goPcImportPay() {
        if (this.isPCFlag) {
          this.driver_id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).user_id : "";
          if (!this.driver_id) {
            this.jumpRoute("loginUser", 'pcExcelImport')
            this.$message({
              message: "Please login.",
              type: 'info'
            });
            return;
          } else {
            localStorage.setItem('tableDataGroup', JSON.stringify([]))
            localStorage.setItem('groupList', JSON.stringify([]))
            this.jumpRoute('pcExcelImport')
          }
        } else {
          this.$message({
            message: "Please upload excel file in PC browser",
            type: 'warning'
          });
          return;
        }
      },
      initMap() {
        let _this = this;
        let positionObj = localStorage.getItem("position");
        if(positionObj) {
          this.currentLocation = JSON.parse(positionObj)

        } else {
          this.currentLocation = {
            lat: 37.382767,
            lng: -122.023248
          };
        }
        this.loader.load().then(() => {
          _this.map = new google.maps.Map(document.getElementById("mapContainer"), {
            center: {
              lat: _this.currentLocation.lat,
              lng: _this.currentLocation.lng
            },
            zoom: 8,
          });
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({
            'location': _this.currentLocation
          }, function(results, status) {
            if(status == google.maps.GeocoderStatus.OK) {
              let addressArr = results[0].address_components
              for(let i = 0; i < addressArr.length; i++) {
                if(addressArr[i].types.indexOf("administrative_area_level_1") > -1) {
                  _this.mainCity = addressArr[i].long_name;
                  localStorage.setItem("mainCity", _this.mainCity)
                  break;
                }
              }
            } else {
              console.log('error');
            }

          });

        })
      },
      logout() {
        //localStorage.removeItem("loginName")
        //localStorage.removeItem("loginInfo")
        localStorage.clear()
        this.jumpRoute("loginUser")
      },
      checkJump(path) {
        this.driver_id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).user_id : "";
        if(!this.driver_id) {
          this.jumpRoute("loginUser", path)
          this.$message({
            message: "Please login.",
            type: 'info'
          });
          return;
        } else {
          this.jumpRoute(path)
        }
      },
      jumpRoute(path, redirect) {
        this.$router.push({
          path: "/" + path,
          name,
          query: {
            redirect
          },
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      },
      checkToggleMenu() {
        this.toggleMenu ? this.toggleMenu = false : void(0)
      },
      // 判断是什么设备
      IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
                    "SymbianOS", "Windows Phone",
                    "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        this.isPCFlag = flag
      },
    }
  }
</script>
<style scoped="scoped">
  .contentWrap {
    width: 7.5rem;
    height: calc(100% - 1.4rem);
    position: absolute;
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  
  #mapContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
  }
  
  .actionArea {
    width: 7.5rem;
    height: 6.36rem;
    background: #FFFFFF;
    border-radius: 0.36rem 0.36rem 0rem 0rem;
    opacity: 0.84;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 11;
    display: flex;
    justify-content: center;
  }
  
  .areaWrap {
    width: 6.7rem;
    height: 2.92rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .import {
    margin-top: 1.16rem;
  }
  .actionCommon {
    margin-bottom: 0.3rem;
  }
</style>