<template>
  <div class="listItemWrap">
    <div class="deliverInfo">
      <div>
        <p class="assigned">
          {{showIndex}} : Assigned by {{currentInfo.sender_name}}
        </p>
        <p class="tipInfo">
          $ {{(currentInfo.tip + currentInfo.cost)/100}} for {{currentInfo.distance ? currentInfo.distance.toFixed(3) : 0}}miles
        </p>
      </div>
      <div class="deliverInfoWrap">
        <div class="deliverTitle from">
          From
        </div>
        <div class="deliverDetail">
          <p v-text="currentInfo.sender_address" :class="cardOpen ? '' : 'singleLineTitle'"></p>
          <div class="otherInfo" v-show="cardOpen">
            <p v-text="currentInfo.sender_name + ' ' + currentInfo.sender_phone"></p>
            <p class="commentTitle">Comment:</p>
            <p class="specComment">({{currentInfo.remarks_sender || "no comment"}})</p>
            <p class="timeTitle">Pickup time:</p>
            <p class="specTime">({{getPickTime(currentInfo.pick_time)}})</p>
          </div>
        </div>
      </div>
      <div class="deliverInfoWrap">
        <div class="deliverTitle to">
          To
        </div>
        <div class="deliverDetail">
          <p class="nameInfo" v-text="currentInfo.receiver_address" :class="cardOpen ? '' : 'singleLineTitle'"></p>
          <div class="otherInfo" v-show="cardOpen">
            <p v-text="currentInfo.receiver_name + ' ' + currentInfo.receiver_phone"></p>
            <p class="commentTitle">Comment:</p>
            <p class="specComment">({{currentInfo.remarks_receiver || "no comment"}})</p>
            <p class="timeTitle">Delivery time:</p>
            <p class="specTime">(Same day)</p>
          </div>
        </div>
      </div>
      <div class="basicInfo">
        <span>
          <span v-text="sizeObj[currentInfo.good_max_volume]"></span>
        <cusCheckbox :checkedObj="checkedObj"></cusCheckbox>
        </span>
        <span>
          <span v-text="weightObj[currentInfo.good_max_weight]"></span>
        <cusCheckbox :checkedObj="checkedObj"></cusCheckbox>
        </span>
      </div>
      <div class="ItemsInfo" v-show="cardOpen">
        Item list (total: {{currentInfo.goods_list.length}} packages)
        <template v-for="(item,key) in currentInfo.goods_list">
          <p v-text="item.goods_name"></p>
        </template>
      </div>
      <div class="packageComment" v-show="cardOpen">
        Comment: <span class="danger">{{currentInfo.recv_comment}}</span>
      </div>
      <div class="pickUpTime" v-show="!cardOpen">
        pickup time: {{getPickTime(currentInfo.pick_time)}}
      </div>
      <div class="actionWrap">
        <div class="yesterdayTask" v-if="isHistory">Hurry up task</div>
        <div v-if="!isHistory"> </div>
        <span class="toggleCard" :class="cardOpen ? 'opend' : 'closed' " @click="cardOpenChange" v-show="cardOpen">
          <i class="el-icon-d-arrow-left"></i>
        </span>
      </div>
      <span class="toggleCard positiontoggle" :class="cardOpen ? 'opend' : 'closed' " @click="cardOpenChange" v-show="!cardOpen">
        <i class="el-icon-d-arrow-left"></i>
      </span>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'toDeliverItem',
    components: {
      cusCheckbox
    },
    props: {
      currentInfo: {
        default: function() {
          return {}
        },
        type: Object
      },
      isHistory: {
        default: false,
        type: Boolean
      },
      showIndex: {
        default: 0,
        type: Number
      }
    },
    data() {
      return {
        cardOpen: false,
        currentLocation: JSON.parse(localStorage.getItem("position")),
        sizeObj: {},
        weightObj: {},
        checkedObj: {
          checked: true,
          changeable: true
        },
      }
    },
    mounted() {
      this.ruleFormat()
    },
    methods: {
      ruleFormat() {
        if(localStorage.getItem("priceRule")) {
          let priceRule = JSON.parse(localStorage.getItem("priceRule"))
          this.weightObj = {
            0: priceRule.weightInfoArr[0].title,
            1: priceRule.weightInfoArr[1].title,
            2: priceRule.weightInfoArr[2].title
          }
          this.sizeObj = {
            0: priceRule.sizeInfoArr[0].title,
            1: priceRule.sizeInfoArr[1].title
          }
        }
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          params: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
      cardOpenChange() {
        this.cardOpen = !this.cardOpen;
      },
      getPickTime(time) {
        let picktime = this.formatdate(time * 1000);
        if(picktime.indexOf("21:0") > -1) {
          return picktime.split(" ")[0] + " PM"
        } else {
          return picktime.split(" ")[0] + " AM"
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .listItemWrap {
    position: relative;
    box-sizing: border-box;
    padding: 0 0.2rem;
    margin-bottom: 0.4rem;
  }
  
  .basicInfo {
    height: 1.04rem;
  }
  
  .deliverTitle {
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    line-height: 0.5rem;
    min-width: 1rem;
    color: #00C85E;
  }
  
  .from {
    color: #00C85E;
  }
  
  .to {
    color: #5583FF;
  }
  
  .deliverDetail {
    overflow: hidden;
    font-size: 0.36rem;
    min-width: calc(100% - 1rem);
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #005026;
    line-height: 0.56rem;
    margin-bottom: 0.32rem;
    padding-left: 0.22rem;
  }
  
  .deliverInfoWrap {
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-around;
    text-align: left;
  }
  
  .deliverInfo {
    padding: 0.2rem;
    background: #EDEDED;
    margin-top: 0.16rem;
  }
  
  .basicInfo {
    font-size: 0;
    text-align: left;
  }
  
  .basicInfo>span {
    display: inline-block;
    font-size: 0.48rem;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.04rem;
    padding-right: 0.6rem;
    position: relative;
    margin-right: 0.1rem;
  }
  
  .basicInfo>.deliverStatus {
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #94A8FF;
    line-height: 0.48rem;
    background: #EDF5FF;
    border-radius: 0.06rem;
    border: 0.02rem solid #DEEDFF;
    padding: 0;
    vertical-align: bottom;
    margin-top: 0.25rem;
  }
  
  .basicInfo>.deliverGoods {
    font-size: 0.48rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.58rem;
    padding: 0;
    vertical-align: bottom;
  }
  
  .basicInfo>span>.cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .ItemsInfo {
    font-size: 0.48rem;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: left;
  }
  
  .otherInfo {
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    color: #9F9F9F;
    line-height: 0.6rem;
  }
  
  .actionWrap {
    margin-top: 0.52rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .actionWrap>span {
    vertical-align: middle;
    width: 0.64rem;
    height: 0.64rem;
    background: #D8D8D8;
    border-radius: 50%;
    font-size: 0.28rem;
    line-height: 0.64rem;
    text-align: center;
    transition: 0.5s;
  }
  
  .toggleCard.opend {
    transform: rotate(90deg);
  }
  
  .toggleCard.closed {
    transform: rotate(-90deg);
  }
  
  .dailyInfo {
    padding: 0.4rem 0.2rem;
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    color: #FF3232;
    line-height: 0.5rem;
    text-align: left;
  }
  
  .assigned,
  .tipInfo {
    text-align: left;
    font-size: 0.36rem;
    /*font-family: AppleColorEmoji;*/
    color: #4C4C4C;
    line-height: 0.56rem;
  }
  
  .assigned {
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    color: #FE3E3E;
    line-height: 0.56rem;
  }
  
  .singleLineTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .packageComment {
    font-size: 0.48rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: left;
    margin-top: 0.48rem;
  }
  
  .danger {
    color: #FF3232;
  }
  
  .pickUpTime {
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: left;
  }
  
  .positiontoggle {
    width: 0.64rem;
    height: 0.64rem;
    background: #D8D8D8;
    border-radius: 50%;
    font-size: 0.28rem;
    line-height: 0.64rem;
    text-align: center;
    transition: 0.5s;
    position: absolute;
    right: 0.8rem;
    bottom: 1.8rem;
  }
  
  .yesterdayTask {
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #FE3E3E;
    line-height: 0.52rem;
    background: #FFEDED;
    border-radius: 0.06rem;
    border: 0.02rem solid #FFC7C7;
    padding: 0 0.2rem;
  }
</style>