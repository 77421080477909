<template>
  <div class="tip">
    <div class="header">
      <div class="user_icon"><i class="el-icon-arrow-left"></i></div>
      <p class="tipTitle">Tip</p>
    </div>
    <div class="moreOperation">
    	<div class="singleTip">
    	  <div class="singleBasicWrap">
    	  	<p class="basicTitle">Basic delivery fee:</p>
    	  	<span class="basicDetail"> $15</span>
    	  </div>
    		<div>
    		  <div class="singleInfoTitle">
            <span>Tip:</span>
          </div>
          <div class="singleInfoInput">
            <el-input></el-input>
          </div>
    		</div>
    	</div>
    	<div class="averageTip">
        <div class="averageBasicWrap">
          <p class="basicTitle">Average tip</p>
        </div>
        <div>
          <div class="singleInfoTitle">
            <span>Tip:</span>
          </div>
          <div class="singleInfoInput">
            <el-input></el-input>
          </div>
        </div>
      </div>
      <div class="actionCommon">Continue</div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'tip',
    components: {},
    data() {
      return {}
    },
    mounted() {},
    methods: {}
  }
</script>
<style scoped="scoped">
.tip{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.tipTitle{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}
.moreOperation{
  width: 7.5rem;
  margin: auto;
  position: relative;
  top: 1.4rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
}
.singleTip,.averageTip{
  font-size: 0;
  padding-bottom: 1.2rem;
}
.singleInfoTitle{
  width: calc(100% - 5rem);
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3D3D3;
  line-height: 0.58rem;
  text-align: left;
}
.singleInfoTitle,.singleInfoInput{
  display: inline-block;
}
.singleBasicWrap,.averageBasicWrap{
  width: 100%;
  height: 1.28rem;
  font-size: 0.48rem;
  font-family: Helvetica;
  line-height: 1.28rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 1rem 0 0.5rem 0;
  overflow: hidden;
  color: #D3D3D3;
}
.basicDetail{
  color: #4C4C4C;
}
</style>