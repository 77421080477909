<template>
  <div class="verifyStatus">
    <div class="header">
      <div class="center_info">
        {{title[status]}}
      </div>
      <div class="user_icon" @click="jumpRoute('loginDriver')">
        <img src="../../assets/image/user.png" />
      </div>
    </div>
    <div class="TaskListContainer">
      <div class="mainPhoto">
        <!--<img src="../../interface/微信图片_20201111181426.jpg"/>-->
        <img src="../../assets/image/logo.png" />
      </div>
      <p class="verifyStatusInfo">
        {{info[status]}}
      </p>
      <div class="actionWrap" v-if="0">
        <div>Declined</div>
        <div>Approved</div>
      </div>
      <div class="actionCommon" v-if="this.status=='fail'">Re upload</div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'verifyStatus',
    components: {},
    data() {
      return {
        status: "",
        title: {
          ing: "Under review",
          fail: "Audit failure"
        },
        info: {
          ing: "Under review. Will notify you when result becomes available",
          fail: "Audit failed, your license is not clear enough, please upload again"
        }
      }
    },
    mounted() {
      let statusNum = this.$route.params.info
      console.log(this.$route.params)
      if(statusNum == 1) {
        this.status = "ing"
      } else if(statusNum == 3) {
        this.status = "fail"
      }
    },
    methods: {
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          params: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
    }
  }
</script>
<style scoped="scoped">
  .TaskListContainer {
    width: 7.18rem;
    height: calc(100% - 1.4rem);
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    z-index: 10;
    background: #FFFFFF;
  }
  
  .mainPhoto {
    width: 7.1rem;
    height: 4.2rem;
    border-radius: 0.16rem;
    background: #FFFFFF;
    margin: auto;
  }
  
  .mainPhoto>img {
    height: 50%;
    transform: translateY(50%);
    text-align: center;
  }
  
  .verifyStatusInfo {
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FF3232;
    line-height: 0.66rem;
    padding: 0.2rem;
    margin-bottom: 0.8rem;
  }
  
  .actionWrap {
    display: flex;
    justify-content: space-between;
  }
  
  .actionWrap>div {
    width: 2.7rem;
    height: 0.88rem;
    text-align: center;
    background: #00C85E;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #FFFFFF;
    line-height: 0.88rem;
    border-radius: 0.44rem;
    text-decoration: none;
  }
</style>