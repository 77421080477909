
export const stripePay = {
  data() {
    return {
      payMethods: [],
      stripe: null, 
      stripeCard: null,
      server_security_key: '',
      server_pub_key: ''
    }
  },
  methods: {
    async getKey() {
      const res = await new Promise((resolve, reject) => {
        this.$http({
          url: `/api/pay/config`,
          method: "GET",
          params: {
          },
        }).then(res => {
          if (res.data.code) {
            this.$message({message: res.data.message, type: 'error'});
            resolve(null);
          } else {
            this.server_security_key = res.data.data.s_key
            this.server_pub_key = res.data.data.p_key
            resolve(res.data.data.p_key)
          }
        }).catch(e => {
          resolve(null);
        })
      })
      return res;
    },
    // 获取customer
    async getCustomer() {
      const customer = localStorage.getItem('customer');
      if (customer) return customer;
      const res = await new Promise((resolve, reject) => {
        this.$http({
          url: `/api/user/get/cus`,
          method: "GET",
          params: {
            email: localStorage.getItem("loginName")
          },
        }).then(res => {
          if (res.data.code) {
            this.$message({message: res.data.message, type: 'error'});
            resolve(null);
          } else {
            localStorage.setItem('customer', res.data);
            resolve(res.data);
          }
        }).catch(e => {
          resolve(null);
        })
      })
      return res;
    },
    // 创建 SetupIntent
    async createSetupIntent() {
      const customer =  await this.getCustomer();
      if (!customer) return;
      const key = await this.getKey()
      const res = await new Promise((resolve, reject) => {
        this.$http({
          url: `https://api.stripe.com/v1/setup_intents`,
          stripe: true,
          wwwForm: true,
          method: "POST",
          data: {
            customer,
          },
          headers: {
            Authorization: `Bearer ${this.server_security_key}`
          }
        }).then(res => {
          resolve(res.data);
        }).catch(e => {
          this.$message({message: e && e.data.error.message, type: 'error'});
          resolve(null);
        })
      })
      return res;
    },
    // 获取绑定的信用卡
    async getCardList(cusId) {
      const key = await this.getKey()
      const res = await new Promise((resolve, reject) => {
        this.$http({
          url: `https://api.stripe.com/v1/customers/${cusId}/sources`,
          stripe: true,
          method: "GET",
          params: {
            object: 'card'
          },
          headers: {
            Authorization: `Bearer ${this.server_security_key}`
          }
        }).then(res => {
          resolve(res.data);
        }).catch(e => {
          resolve(null);
        })
      })
      return res;
    },
    // 获取客户paymentMethods
    // @see https://stripe.com/docs/api/payment_methods/list#list_payment_methods-type
    async getPayMethods() {
      const customer = await this.getCustomer();
      const key = await this.getKey()
      const res = await new Promise((resolve, reject) => {
        this.$http({
          url: `https://api.stripe.com/v1/payment_methods`,
          stripe: true,
          method: "GET",
          params: {
            customer,
            type: 'card'
          },
          headers: {
            Authorization: `Bearer ${this.server_security_key}`
          }
        }).then(res => {
          resolve(res.data.data);
        }).catch(e => {
          resolve(null);
        })
      })
      return res;
    },

    // 保存信用卡
    async createCard(source) {
      const customer =  await this.getCustomer();
      this.$http({
        url: `https://api.stripe.com/v1/customers/${customer}/sources`,
        wwwForm: true,
        method: "POST",
        data: {
          source: source
        },
        headers: {
          Authorization: `Bearer ${this.server_security_key}`
        }
      }).then(res => {
        this.addHandleClose();
        this.$message({message: 'Credit card added successfully', type: 'success'});
        setTimeout(() => {
          this.$emit('addCardSuccess');
        }, 200);
      }).catch(e => {
        this.$message({message: e && e.data.error.message, type: 'error'});
      }).finally(() => {
        this.creditLock = false;
      })
    },
    // 保存信用卡成功后显示pay
    async addCardSuccess() {
      this.updatePayMothod();
      this.setTipDialog = true;
    },
    // 预创建订单（获取publishable_key，client_secret）
    createPay(params) {
      if (this.submitLock) {
        return;
      }
      this.submitLock = true;
      // this.showCreditInfo = true;
      this.$http({url: "/api/pay/create", method: "POST", data: params}).then(res => {
        this.$message.closeAll();
        this.submitLock = false;
        if (res.data.code) {
          this.$message({message: res.data.message, type: 'error'});
        } else {
          // 只设置小费
          if (this.tipOnly) {
            this.handleClose();
            this.$emit('tipSuccess', "Set tip successful!");
            return;
          }
          const payData = res.data.data;
          // this.creditInfo = this.setupElements(payData);
          this.pay(payData, params);
        }
      })
    },
    setupElements(data) {
      const stripe = Stripe(data.publishable_key);
      const client_secret = data.client_secre
      const elements = stripe.elements();
      const style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
      
      var card = elements.create("card", {style: style});
      card.mount("#card-element");
      return {stripe, card, client_secret};
    },
    pay(payData, params) {
      // const {stripe, card, client_secret} = this.creditInfo;
      const stripe = Stripe(payData.publishable_key);
      if (this.payLoading) {
        return;
      }
  
      this.payLoading = true;
      stripe.confirmCardPayment(payData.client_secret, {
        // payment_method: {
        //   card: card
        // }
        payment_method: this.curPayMethod.id,
        // payment_method: 'pm_card_authenticationRequiredSetupForOffSession',
      }).then((result) => {
        this.payLoading = false;
        console.log(result);
        if (result.error) {
          this.$message({message: result.error.message, type: 'error'});
        } else {
          if (result.paymentIntent.status == "succeeded") {
            this.$message({message: "Payment successful!", type: 'success'});
            this.handleClose();
            this.$emit('paySuccess', {
              msg:"Payment successful!",
              params: params
            });
          }
        }
      });
    },
    // 更新支付方式
    async updatePayMothod() {
      this.payMethods = await this.getPayMethods();
    },
  }

}
