<template>
  <div class="moreOrder">
    <div class="header">
      <div class="user_icon"><i class="el-icon-arrow-left" @click="jumpRoute('mainPage')"></i></div>
      <p class="moreTitle">More delivery request</p>
    </div>
    <div class="moreOperation">
    	<div class="singleOperation" @click="jumpRoute('deliveryInfo')">
    	  <p>More delivery request</p>
    	  <i class="el-icon-arrow-right"></i>
    	</div>
    	<div class="singleOperation" @click="jumpRoute('myOrder')">
        <p>My requests</p>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'moreOrder',
    components: {},
    data() {
      return {}
    },
    mounted() {},
    methods: {
      jumpRoute(path){
        this.$router.push({
          path: "/" + path,
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      }
    }
  }
</script>
<style scoped="scoped">
.moreOrder{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.moreTitle{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}
.moreOperation{
  width: 7.5rem;
  margin: auto;
  position: relative;
  top: 1.4rem;
  box-sizing: border-box;
  padding: 0 0.4rem;
}
.singleOperation{
  width: 100%;
  height: 1.28rem;
  background: #F8F8F8;
  border-radius: 0.12rem;
  border: 0.02rem solid #C6C6C6;
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #4C4C4C;
  line-height: 1.28rem;
  padding: 0 0.32rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
}
</style>