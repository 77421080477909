<template>
  <div class="listItemWrap">
    <div class="deliverInfo">
      <p class="routeStatus">
        {{showOrder}}:{{getStatus()}}
      </p>
      <p class="orderStatus">
        order status: {{orderStatus[currentInfo.status]}}
      </p>
      <p></p>
      <div class="deliverInfoWrap" v-if="currentRouteType == 'sender'">
        <div class="deliverTitle from">
          Address:
        </div>
        <div class="deliverDetail">
          <p v-text="currentInfo.sender_address"></p>
        </div>
      </div>
      <div class="otherInfo" v-if="currentRouteType == 'sender'">
        <p class="senderTitle">Sender: {{currentInfo.sender_name}}</p>
        <p class="specTime">Pickup time: ({{getPickTime(currentInfo.pick_time)}})</p>
        <p class="specComment">Comment: ({{currentInfo.remarks_sender || "no comment"}})</p>
      </div>
      <div class="deliverInfoWrap" v-if="currentRouteType == 'receiver'">
        <div class="deliverTitle to">
          Address:
        </div>
        <div class="deliverDetail">
          <p class="nameInfo" v-text="currentInfo.receiver_address"></p>
        </div>
      </div>
      <div class="otherInfo" v-if="currentRouteType == 'receiver'">
        <p class="senderTitle">Receiver: {{currentInfo.receiver_name}}</p>
        <p class="specTime">Deliver time: (Same day)</p>
        <p class="specComment">Comment: ({{currentInfo.remarks_receiver || "no comment"}})</p>
      </div>
      <div class="basicInfo">
        <span>
          <span v-text="sizeObj[currentInfo.good_max_volume]"></span>
        <cusCheckbox :checkedObj="checkedObj"></cusCheckbox>
        </span>
        <span>
          <span v-text="weightObj[currentInfo.good_max_weight]"></span>
        <cusCheckbox :checkedObj="checkedObj"></cusCheckbox>
        </span>
      </div>
      <div class="actionWrap">
        <div>
          <span @click="openMap" :class="getStatusClass()">Go</span>
          <span :class="getStatusClass()">
            <a v-if="!btnDisable" :href="getphone('tel:')">Call</a>
            <a v-else>Call</a>
          </span>
          <span :class="getStatusClass()">
            <a v-if="!btnDisable" :href="getphone('sms:')">Text</a>
            <a v-else>Text</a>
          </span>
        </div>
        <div>
          <span @click="confirmArrived" :class="getStatusClass()">
      	  		{{currentRouteType == 'receiver' ? "Arrived" : "Picked up"}}
      	  </span>
          <span @click="confirmDone" :class="getStatusClass()">
	      		Done
	      </span>
          <span class="ignore"></span>
        </div>
      </div>
      <div class="ItemsInfo">
        Item list (total: {{currentInfo.goods_list && currentInfo.goods_list.length}} packages)
        <template v-for="(item,key) in currentInfo.goods_list">
          <p v-text="item.goods_name"></p>
        </template>
      </div>
      <div class="packageComment">
        Comment: <span class="danger">{{currentInfo.recv_comment}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'routeItem',
    components: {
      cusCheckbox
    },
    props: {
      currentInfo: {
        default: function() {
          return {
            goods_list: []
          }
        },
        type: Object
      },
      currentLocation: {
        default: function() {
          return {}
        },
        type: Object
      },
      currentRouteType: {
        default: function() {
          return ""
        },
        type: String
      },
      showOrder: {
        default: function() {
          return ""
        },
        type: String
      },
    },
    data() {
      return {
        sizeObj: {},
        weightObj: {},
        checkedObj: {
          checked: true,
          changeable: true
        },
        driver_id: "",
        changeLock:false,
        btnDisable:false
      }
    },
    mounted() {
      this.ruleFormat()
      this.driver_id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).id : "";
      if(!this.driver_id) {
        this.jumpRoute("loginDriver")
        return;
      }
    },
    methods: {
      getStatus() {
        if(this.currentRouteType == "sender") {
          return "Pickup"
        } else if(this.currentRouteType == "receiver") {
          return "Delivery"
        } else if(this.currentRouteType == "error") {
          return "Abnormal order"
        } else {
          return ""
        }
      },
      getStatusClass() {
        if(this.currentRouteType == "sender") {
          if(this.currentInfo.status < 5) {
            this.btnDisable = false;
            return ""
          } else {
            this.btnDisable = true;
            return "deactive"
          }
        } else if(this.currentRouteType == "receiver") {
          if(this.currentInfo.status == 5) {
            this.btnDisable = false;
            return ""
          } else {
            this.btnDisable = true;
            return "deactive"
          }
        } else if(this.currentRouteType == "error") {
          this.btnDisable = true;
          return "deactive"
        }
      },
      confirmArrived() {
        if(this.btnDisable) return;
        this.$emit("confirmArrived")
      },
      confirmDone() {
        if(this.btnDisable) return;
        this.$emit("confirmDone")
      },
      ruleFormat() {
        if(localStorage.getItem("priceRule")) {
          let priceRule = JSON.parse(localStorage.getItem("priceRule"))
          this.weightObj = {
            0: priceRule.weightInfoArr[0].title,
            1: priceRule.weightInfoArr[1].title,
            2: priceRule.weightInfoArr[2].title
          }
          this.sizeObj = {
            0: priceRule.sizeInfoArr[0].title,
            1: priceRule.sizeInfoArr[1].title
          }
        }
      },
      getphone(type) {
        if(this.currentRouteType == "sender") {
          return type + this.currentInfo.sender_phone
        } else if(this.currentRouteType == "receiver") {
          return type + this.currentInfo.receiver_phone
        } else {
          return ""
        }
      },
      getText() {
        if(this.currentInfo.status == 2 || this.currentInfo.status == 3) {
          return "-sender"
        } else if(this.currentInfo.status == 4 || this.currentInfo.status == 5) {
          return "-receiver"
        } else {
          return ""
        }
      },
      hrefMap() {
        if(this.currentRouteType == "sender") {
          //收货方地址
          let placeName = this.currentInfo.sender_address
          //return "https://www.google.com/maps/dir/?api=1&destination=" + this.currentInfo.latitude + "," + this.currentInfo.longitude;
          return "https://www.google.com/maps/dir/?api=1&destination=" + placeName.replace(" ","+")
        } else {
          //取件方地址
          let placeName = this.currentInfo.receiver_address
          //return "https://www.google.com/maps/dir/?api=1&destination=" + this.currentInfo.receiver_latitude + "," + this.currentInfo.receiver_longitude;
          return "https://www.google.com/maps/dir/?api=1&destination=" + placeName.replace(" ","+")
        }
      },
      openMap() {
        if(this.btnDisable) return;
        if(this.currentRouteType == "sender") {
          if(this.currentInfo.status >= 3) {
            window.open(this.hrefMap())
            return
          } else {
            if(!this.changeLock){
              this.changeLock = true;
              this.changeStatus(3)              
            }
            window.open(this.hrefMap())
          }
        } else if(this.currentRouteType == "receiver") {
          if(this.currentInfo.status == 5) {
            window.open(this.hrefMap())
          } else if(this.currentInfo.status < 5) {
            this.$message.closeAll();
            this.$message({
              message: "Please confirm picked up Packages",
              type: 'warning'
            })
          }
        }
      },
      changeStatus(status) {
        if(this.currentInfo.status > status) {
          return
        }
        this.$http({
          url: "/api/order/status",
          method: "POST",
          data: {
            order_id: this.currentInfo.order_id,
            driver_id: this.driver_id,
            status,
            method: 1,
            driver_longitude: this.currentLocation.lng,
            driver_latitude: this.currentLocation.lat,
            driver_current_address: ""
          }
        }).then(res => {
          this.changeLock = false;
          if(res.data.code) {
            this.$message.closeAll();
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.$emit("refresh", this.currentInfo.order_id)
          }
        })
      },
      getPickTime(time) {
        let picktime = this.formatdate(time * 1000);
        if(picktime.indexOf("21:0") > -1) {
          return picktime.split(" ")[0] + " PM"
        } else {
          return picktime.split(" ")[0] + " AM"
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .listItemWrap {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 0.4rem;
  }
  
  .basicInfo {
    height: 1.04rem;
  }
  
  .deliverTitle {
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #005026;
    line-height: 0.56rem;
    min-width: 1.5rem;
  }
  
  .deliverDetail {
    min-width: calc(100% - 1.5rem);
    overflow: hidden;
    font-size: 0.36rem;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #005026;
    line-height: 0.56rem;
    margin-bottom: 0.32rem;
    padding-left: 0.22rem;
  }
  
  .deliverInfoWrap {
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-around;
    text-align: left;
  }
  
  .deliverInfo {
    padding: 0.2rem;
    background: #EDEDED;
    margin-top: 0.16rem;
  }
  
  .routeStatus {
    text-align: left;
    height: 0.56rem;
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    color: #00C85E;
    line-height: 0.56rem;
  }
  
  .orderStatus {
    text-align: left;
    height: 0.56rem;
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #666666;
    line-height: 0.56rem;
  }
  
  .basicInfo {
    font-size: 0;
    text-align: left;
  }
  
  .basicInfo>span {
    display: inline-block;
    font-size: 0.48rem;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.04rem;
    padding-right: 0.6rem;
    position: relative;
    margin-right: 0.1rem;
  }
  
  .basicInfo>.deliverStatus {
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #94A8FF;
    line-height: 0.48rem;
    background: #EDF5FF;
    border-radius: 0.06rem;
    border: 0.02rem solid #DEEDFF;
    padding: 0;
    vertical-align: bottom;
    margin-top: 0.25rem;
  }
  
  .basicInfo>.deliverGoods {
    font-size: 0.48rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.58rem;
    padding: 0;
    vertical-align: bottom;
  }
  
  .basicInfo>span>.cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .ItemsInfo {
    font-size: 0.48rem;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: left;
  }
  
  .otherInfo {
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    color: #9F9F9F;
    line-height: 0.56rem;
    text-align: left;
  }
  
  .packageComment {
    font-size: 0.48rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: left;
    margin-top: 0.48rem;
  }
  
  .danger {
    color: #FF3232;
  }
  
  .actionWrap {
    margin: 0.2rem 0;
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .actionWrap>div {
    display: flex;
    justify-content: space-between;
  }
  
  .actionWrap span {
    width: 2.2rem;
    height: 0.88rem;
    background: #00C85E;
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #FFFFFF;
    line-height: 0.88rem;
    border-radius: 0.44rem;
  }
  
  .actionWrap .ignore {
    background: transparent;
  }
  
  .actionWrap .deactive {
    background: #A7A7A7;
  }
  
  .actionWrap a {
    color: #FFFFFF;
    text-decoration: none;
  }
</style>