<template>
  <div class="deliverDetail">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="detailTitle">Delivery detail</p>
    </div>
    <div class="moreOperation">
      <div class="orderInfoContainer">
        <div class="order_title">
          Order info
        </div>
        <div class="order_content">
          <p>
            <span class="infoTitle">Status: </span>
            <span v-text="orderStatus[currentInfo.receiverInfo.status]" class="innerInfo"></span>
          </p>
          <!-- <p>
            <span class="infoTitle">Request submit time: </span>
            <span v-text="currentInfo.receiverInfo.submittedTime ? formatdate(currentInfo.receiverInfo.submittedTime*1000) : ''" class="innerInfo"></span>
          </p> -->
          <p>
            <span class="infoTitle">Target address: </span>
            <span class="innerInfo"><span v-if="currentInfo.receiverInfo.room">{{currentInfo.receiverInfo.room}}, </span>{{currentInfo.receiverInfo.address}}</span>
          </p>
          <p v-if="currentInfo.receiverInfo.comment">
            <span class="infoTitle">Comment: </span>
            <span class="innerInfo">{{currentInfo.receiverInfo.comment}}</span>
          </p>
          <div class="innerInfo" >
            <span class="infoTitle">Package list: </span>
            <div class="itemInfo">
              <p v-for="(item,index) in currentInfo.package_list" :key="index">{{item}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="orderInfoContainer">
        <div class="order_title">
          Driver info
        </div>
        <p>
          <span class="infoTitle">ID:</span>
          <span v-text="currentInfo.driverInfo.id" class="innerInfo"></span>
        </p>
        <p>
          <span class="infoTitle">Comment:</span>
          <span v-text="currentInfo.driverInfo.comment" class="innerInfo"></span>
        </p>
      </div>
      <div class="orderInfoContainer" v-if="currentInfo.uploadPhotoArr.length">
        <div class="order_title">
          Delivery pics
        </div>
        <div class="pics">
          <div class="photoPreview" v-if="currentInfo.uploadPhotoArr.length">
            <img :src="item" v-for="(item ,index) in currentInfo.uploadPhotoArr"
              :key="index" @click="showImageBig(item)" />
          </div>
        </div>
      </div>
      <div class="orderInfoContainer">
        <div class="order_title">
          Submit comment<span>(Only the recipient can comment)</span>
        </div>
        <el-form ref="form" :model="addForm" class="submit_form">
          <el-row>
            <el-col :span="6" >
            <span class="infoTitle">Comment: </span>
            </el-col>
            <el-col :span="18" >
              <el-input  type="textarea" :rows="3" resize="none" v-model="addForm.comment"></el-input>
              </el-col>
          </el-row>
          <el-row class="phone_confirm">
            <el-col :span="12" > 
              <span class="infoTitle">Recipient cell phone#: </span>
            </el-col>
            <el-col :span="12">
              <el-input :maxlength="10" :placeholder="1234567899" v-model="addForm.phone"></el-input>
            </el-col>
          </el-row>
          <div class="btn_line">
          </div>
          <div class="actionCommon" @click="confirmPhone">Verify cell phone # </div>
          <div class="actionCommon" @click="submitComment(2)">Submit comment</div>
        </el-form>
      </div>      

      <el-dialog
      title=""
      :visible.sync="bigImageDialog"
      width="70%"
      class="image_dialog"
      :show-close="false"
      :before-close="closeImageDialog">
      <div class="img_big_dialog">
        <img :src="bigImageSrc" alt="">
      </div>
      </el-dialog>
    
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'deliverDetail',
    data() {
      return {

        currentInfo: {
          
            package_list:[],
            uploadPhotoArr:[],

            receiverInfo:{
              status:1,
              address:"",
              submittedTime:1616315246,
              comment: '',
              room: ''
            },

            driverInfo:{
              id:"",
              comment:"Driver's comment",
              
           },
            
        },
        bigImageSrc: '',
        bigImageDialog: false,
        comment:'',
        order_id: "",
        group_id:"",
        index: 0,

        addForm: {
              phoneConfirmed:false,
              phone:"",
              phone2:"",
              comment: ""
          },
      }
      
    },
    mounted() {
      this.order_query = this.$route.query.m;
      this.group_id = this.$route.query.group_id;
      this.index = this.$route.query.index;

      this.getOrderInfo()
      },
    methods: {
	    getOrderInfo() {
        this.$http({
          url: "/api/batch/order/user/item/v2",
          method: "GET",
          params: {
            // m: '22b48a726b7e7cbf86424aec6970b6a6',
            m: this.order_query
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            if(res.data)
              this.dealResult(res.data);
              this.order_id = res.data.order_id
          }
        })
      },
      dealResult(list) {
        let packages = list.package_list;
        for(let j = 0; j < packages.length; j++) {
          this.currentInfo.package_list.push(packages[j].name)
        }
        this.currentInfo.receiverInfo.status = list.status;
        this.currentInfo.receiverInfo.address = list.address;
        this.currentInfo.receiverInfo.comment = list.comment;
        this.currentInfo.receiverInfo.room = list.room;

        this.currentInfo.driverInfo.id = list.driver_id;
        this.currentInfo.driverInfo.comment = list.driver_comment;
        this.addForm.phone2 = list.phone;


        if (list.image_info ) {
          let photoArr = list.image_info;
          for(let j = 0; j < photoArr.length; j++) {
            this.currentInfo.uploadPhotoArr.push(photoArr[j]);
          }
        }
       
      },

      fetchImage(imageUrl){
        let sendBack="";
        this.$http({
          url: imageUrl,
          method: "GET",
          responseType:'blob'
        }).then(res => {
          //const blob = new Blob([res.data], { type: 'image/png' });
          const content = res.data;
          sendBack = window.URL.createObjectURL(content);
        }).catch((err)=>{
	        this.$message({
	          message: "Error Occured while fetching image: "+err,
	          type: 'error'
	        });
        })
        return sendBack; 
        
      },

      closeImageDialog() {
        this.bigImageDialog = false
      },

      goBack(){
        this.$router.go(-1);
      },
      
      confirmPhone() {
        if(!this.addForm.phoneConfirmed) {
             
          if(this.addForm.phone2!='' && this.addForm.phone==this.addForm.phone2) {
                this.addForm.phoneConfirmed=true;
                this.$message({
                  message: "Phone # Confirmed!",
                  type: 'success'
                });
                return;
          } else {
                this.addForm.phoneConfirmed=false;
                this.$message({
                  message: "Please input the correct phone #",
                  type: 'error'
              });
           }
        }else{
          this.$message({
                  message: "Cell Phone No has been confirmed!",
                  type: 'success'
              });
        }
      },

      
      submitComment(type) {
        if(!this.addForm.phoneConfirmed){
            this.$message({
            message: "Please confirm your cell phone # before submission!",
            type: 'error'
          });
          return;
        }
        if(this.addForm.comment==''){
            this.$message({
            message: "Please input comment before submission!",
            type: 'error'
          });
          return;
        }
        this.$http({
          url: "/api/command/add",
          method: "POST",
          params: {
            who_id: this.addForm.phone2,
            what: this.addForm.comment,
            relation_id: this.order_id,
            comment_type:type
          }
        }).then(res => {
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }else {
            
              this.$message({
                message: "Add Comment Completed!",
                type: 'success'
              });
              
          }
        })
        
        
      },
      showImageBig(src) {
        this.bigImageSrc = src
        this.bigImageDialog = true
      },
      
      jumpRoute(path){
        this.$router.push({
          path: "/" + path,
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      }
    }
  }
</script>
<style lang="scss">
.deliverDetail{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .detailTitle{
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  .moreOperation{
    width: 7.5rem;
    height: calc(100% - 1.4rem);
    margin: auto;
    position: relative;
    top: 1.4rem;
    box-sizing: border-box;
    // padding: 0.4rem 0.2rem;
    overflow-x: hidden;
    overflow-y: auto;
    background: #f4f5f7;
    color: #333;
    .orderInfoContainer {
      font-size: 0.3rem;
      line-height: 0.4rem;
      text-align: left;
      margin-top: 0.2rem;
      background: #fff;
      padding: 0.2rem;
      .order_title {
        width: 100%;
        height: 0.6rem;
        line-height: 0.4rem;
        font-size: 0.35rem;
        font-weight: 700;
        border-bottom: 1px solid #e5e5e5;
        color: #999;
        span {
          font-size: 0.2rem;
          margin-left: 0.2rem;
          color: rgb(255, 174, 0);
          font-weight: normal;
        }
      }
      .infoTitle{
        font-weight: 600;
        font-size: 0.3rem;
        font-family: Helvetica;
        color: #666;
        line-height: 0.58rem;
        margin-bottom: 0.24rem;
      }
      .order_content {
        padding: 0.1rem 0;
        .itemInfo {
          margin-left: 0.5rem;
          p {
            line-height: 0.5rem;
          }
        }
      }
      .submit_form {
        width: 100%;
        padding: 0.2rem 0;
        .el-col-4 {
          margin-right: 0.2rem;
        }
        .el-textarea {
          background: #fff;
          .el-textarea__inner {
            background: #fff;
            font-size: 0.3rem;
            line-height: 0.5rem;
            height: 100%;
          }
        }
      }
    }
  }
  .actionCommon {
    width: 6.8rem;
    height: 0.88rem;
    border-radius: 0.44rem;
    background:#00C85E;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #FFFFFF;
    text-align: center;
    line-height: 0.88rem;
    margin: 0.4rem auto 0.2rem;
  }
  .photoPreview {
    width: 100%;
    height: 2rem;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 0.2rem;
  }
  
  .photoPreview>img {
    max-width: 90%;
    height: 90%;
    margin: 0 0.2rem;
  }
  .phone_confirm {
    margin-top: 0.2rem;
    .el-input {
      width: 3.55rem;
      .el-input__inner {
        background: #fff;
        font-size: 0.3rem;
      }
    }
  }
  .confirm_phone {
    color: #fff;
    margin-top: 0.1rem;
    font-size: 0.25rem;
    border-radius: 0.44rem;
    background:#00C85E;
    border: none;
    font-family: Helvetica;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    font-weight: 700;
  }
  .btn_line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .actionCommon {
      width: 48%;
      border-radius: 0.2rem;
    }
  }
}

</style>