<template>
  <div class="myMultiOrder2">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="myOrderTitle">My requests</p>
    </div>
    <div class="moreOperation" ref="moreOperation">
      <div class="menuContainer">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="All" name="-1"></el-tab-pane>
          <el-tab-pane label="Not paid" name="0"></el-tab-pane>
          <!-- <el-tab-pane label="Not assigned" name="Not assigned"></el-tab-pane>
          <el-tab-pane label="Assigned" name="Assigned"></el-tab-pane> -->
          <el-tab-pane label="In progress" name="8"></el-tab-pane>
          <el-tab-pane label="Completed" name="9"></el-tab-pane>
          <el-tab-pane label="Failed" name="15"></el-tab-pane>
        </el-tabs>
      </div>
    	<div class="listContainer" ref="listContainer" v-loading="orderLoading"
        @touchstart="touchStart($event)" @touchend="touchEnd($event)">
    		<div class="list-item" v-for="(item,index) in orderList" :key="index">
          <div class="top_title">
            <span>{{item.order_name}}</span>
            <div class="detail_btn" @click="goDetail(item)">Details</div>
          </div>
          <div class="item_center">
            <div>
              <span>Status: {{orderStatus[item.order_status]}}</span>
              <span>Posted at: {{item.order_time | timeFilter}}</span>
            </div>
            <div class="bottom_detail">
              {{item.group_done}} done / {{item.group_count}} Groups, {{item.complete_count}} done / {{item.order_count}} drops 
            </div>
            <div>
              <span v-if="item.order_status == 0">Payment: 0</span>
              <span v-else>Payment: ${{item.cost>0?item.cost/100:item.cost}} + ${{item.tip>0?item.tip/100:item.tip}}tips</span>
              <span>Scheduled on: {{item.expected_time | scheduledFilter}}</span>
            </div>
          </div>
          <div class="handle_item">
            <div class="btn pay" v-if="item.order_status == 0" @click="showGroup(item, index, 'pay')">Pay</div>
            <div class="btn tips"  v-if="item.order_status >= 1" @click="showGroup(item, index, 'tip')">More tips</div>
            <div class="btn group" @click="showGroup(item,index)">Groups</div>
          </div>
          <div class="show_groups" v-if="item.groupShow">
            <div class="group_item" v-for="(ele, eleIndex) in groupsList" :key="eleIndex">
              <div class="top_title">
                <span>{{ele.group_name}}</span>
                <div class="detail_btn" @click="goGroupDetail(ele)">Details</div>
              </div>
              <div class="item_center">
                <div>
                  <span>Status: {{orderStatus[ele.status]}}</span>
                  <span>Driver: {{ele.driver_info}}</span>
                </div>
                <div>
                  <span>{{ele.complete_count}} done / {{ele.order_count}} drops</span>
                  <span v-if="ele.status == 0">Payment: 0</span>
                  <span v-else>Payment: ${{ele.cost>0?ele.cost/100:ele.cost}} + ${{ele.tip>0?ele.tip/100:ele.tip}}tips</span>
                </div>
              </div>
              <div class="handle_item">
                <div class="btn">
                  <a class="btn pay" v-if="ele.driver_phone" :href="getphone('tel:',ele.driver_phone)">Call driver</a>
                  <a class="btn not_btn" v-else @click="noPhone()">Call driver</a>
                </div>
                <div class="btn">
                  <a class="btn tips" v-if="ele.driver_phone" :href="getphone('sms:',ele.driver_phone)">Text driver</a>
                  <a class="btn not_btn" v-else @click="noPhone()">Text driver</a>
                </div>
              </div>
            </div>
          </div>
    		</div>
    		<div class="load-more" v-show="enableLoadMore" @click="loadMoreData">{{loadMoreText}}</div>  
      <div class="load-end" v-show="!enableLoadMore">All loaded</div>  
    	</div>
    </div>
    <AddCreditCard :dialogShow.sync="addCreditDialog"
     @addCardSuccess="addCardSuccess"></AddCreditCard>
    <SeTipAndPay :dialogShow.sync="setTipDialog" :tableData="payData" :order_id="currentOrder.order_id" :tipOnly="tipOnly"
     @tipSuccess="paySuccess" @paySuccess="paySuccess"></SeTipAndPay>
  </div>
</template>

<script>
  import {stripePay} from '../../mixin/stripePay.js'
  import orderItem from "@/components/orderItem.vue"
  import AddCreditCard from '@/components/addCreditCard.vue'
  import SeTipAndPay from '@/components/seTipAndPay.vue'
  import moment from "moment"
  // @ is an alias to /src
  export default {
    name: 'myOrder',
    mixins: [stripePay],
    components: {orderItem, AddCreditCard, SeTipAndPay},
    data() {
      return {
        activeName: '-1',
        orderList:[],
        groupsList: [],
        extendObj:{},
        loadMoreText: "",
        startX: 0,
        startY: 0,
        isLoading: false,
        enableLoadMore:true,
        pageIndex:1,
        isPCFlag: false,
        currentOrder: {
          order_id: ''
        },
        orderLoading: false,
        addCreditDialog: false,
        setTipDialog: false,
        payData: [],
        tipOnly: false,
      }
    },
    filters:{
      timeFilter(val) {
        if (val) {
          return moment(val*1000).format('MM/DD hh:mm')
        } else {
          return ''
        }
      },
      scheduledFilter(val) {
        if (val) {
          return moment(val*1000).format('MM/DD')
        } else {
          return moment().format('MM/DD')
        }
      }
    },
    created() {
      this.updatePayMothod();
    },
    mounted() {
      this.loadMoreText = this.isPhone ? 'Pull up to load more': 'Click to load more'
      if(localStorage.getItem("loginInfo")){
        this.sender_id = JSON.parse(localStorage.getItem("loginInfo")).user_id;
      }
      this.getOrderList(-1,'All')
      this.IsPC()
    },
    methods: {
      handleClick() {
        this.orderList = [];
        this.pageIndex = 1;
        this.enableLoadMore = true;
        this.getOrderList()
      },
      IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
                    "SymbianOS", "Windows Phone",
                    "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        this.isPCFlag = flag
      },
      loadMoreData(){
        this.doLoadMore()
      },
      touchStart(e) {
        this.startY = e.targetTouches[0].pageY;
        this.startX = e.targetTouches[0].pageX;  
      },
      scrollToEnd(e) {
        let ele = this.$refs.listContainer
        let scrollHeight = ele.scrollHeight; 
        let clientHeight = ele.clientHeight; 
        let scrollTop = ele.scrollTop;  
        if (scrollTop + clientHeight >= scrollHeight && this.enableLoadMore) {
            this.doLoadMore()  
        } 
      },
      doLoadMore() {
        this.loadMoreText = 'loading...'
        this.onLoadMore(this.loadDone);
      },
      onLoadMore(cb){
        if (this.isLoading) {
          return;
        }
        this.pageIndex++;
        this.getOrderList()
        setTimeout(()=>{
          cb()
        },1000);
      },
      loadDone() {
        this.isLoading = false;
        this.loadMoreText = this.isPhone ? 'Pull up to load more': 'Click to load more'
      },
      touchEnd(e) {
        if (this.isLoading) {
            return;
        }

        let endX = e.changedTouches[0].pageX,
            endY = e.changedTouches[0].pageY,
            dy = this.startY - endY,
            dx = endX - this.startX;
        if(Math.abs(dx) < 2 && Math.abs(dy) < 2) {
            return;
        }
        if (endY < this.startY) {
            this.scrollToEnd(e)
        }
      },
      getOrderList(){
        this.orderLoading = true
        this.$http({
          url: "/api/batch/order/user/v2",
          method: "GET",
          params: {
            user_id:this.sender_id,
            status:this.activeName,
            page:this.pageIndex,
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code){
            this.orderLoading = false
            this.enableLoadMore = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }else{
            this.orderLoading = false
            this.enableLoadMore = false;
            let orderList = res.data.data.data
            orderList.forEach(item => {
              item.groupShow = false
              var done = 0
              item.drop_summary.forEach(ele => {
                if (ele.status == 9) {
                  done = ele.count
                }
              })
              item.group_done = done
            })
            this.orderList = this.orderList.concat(orderList)
          }
        })
      },
      goBack(){
        this.$router.push({
          path: "/" + "mainPage",
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      },

      // handle 
      goDetail(item) {
        var orderId = item.order_id
        var path = ''
        if (this.isPCFlag) {
          path = 'pcExcelImport'
        } else {
          path = 'myMultiDetail'
        }
        this.$router.push({
          path: "/" + path,
          query:{
            id: orderId
          }
        }).catch(err => {err})
      },
      goGroupDetail(ele) {
        var orderId = this.currentOrder.order_id
        var groupId = ele.group_id
        this.$router.push({
          path: "/multiOptimizedRoute",
          query: {
            id: orderId,
            groupId: groupId
          }
        }).catch(err => {err})
      },
      noPhone() {
        // No phone number provided
        this.$message.closeAll();
        this.$message({
          message: "No phone number provided",
          type: 'warning'
        })
      },
      getphone(type) {
        return type + this.currentTask.phone
      },
      showGroup(item,index, flag) {
        var orderId = item.order_id
        this.currentOrder = item
        this.orderLoading = true
        this.$http({
          url: "/api/batch/order/user/group/v2",
          method: "GET",
          params: {
            order_id: orderId
          }
        }).then(res => {
          this.$message.closeAll();
          this.orderLoading = false
          if(res.data.code){
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            var groupList = res.data.data
            groupList.forEach(ele => {
              ele.complete_count = 0
              ele.order_count = 0
              ele.summary.forEach(item => {
                if (item.status == 6) {
                  ele.complete_count = item.count
                }
                ele.order_count+=item.count
              })
            })
            this.groupsList = groupList

            // 走设置小费和支付
            if (flag) {
              this.goPay(flag);
              return;
            }
            this.orderList.forEach(ele => {
              ele.groupShow = false
            })
            this.orderList[index].groupShow = true
          }
        })
      },
      async goPay(flag) {
        this.tipOnly = flag === 'tip';

        this.payData = this.groupsList.map(l => {
          return {
            groupId: l.group_id,
            groupName: l.group_name,
          }
        });
        if (this.tipOnly) {
          this.setTipDialog = true;
          return;
        }
        if (!this.payMethods || !this.payMethods.length) {
          // 绑卡
          this.addCreditDialog = true;
        } else {
          // 设置小费 支付
          this.setTipDialog = true;
        }
      },
      async addCardSuccess() {
        this.updatePayMothod();
      },
      // 支付成功
      paySuccess(obj) {
        this.$message({message: obj.msg, type: 'success'});
        this.orderList = []
        this.getOrderList();
      },
    }
  }
</script>
<style lang="scss">
.myMultiOrder2{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .el-tabs__header {
    background: #fff;
    margin-bottom: 0.2rem;
  }
  .el-tabs__item {
    font-size: 0.34rem;
    height: 0.9rem;
    line-height: 0.9rem;
  }
  .el-tabs__nav-next, .el-tabs__nav-prev {
    font-size: 0.34rem;
  }
  .myOrderTitle{
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  .moreOperation{
    width: 7.5rem;
    height: calc(100% - 1.4rem);
    margin: auto;
    position: relative;
    top: 1.4rem;
    box-sizing: border-box;
    // padding: 0 0.16rem;
    background: #f4f5f7;
  }
  .menuItem.active{
    color: #00C85E;
  }
  .listContainer{
    position: relative;
    height: calc(100% - 1.2rem);
    overflow-y: auto;
    .list-item {
      width: 100%;
      box-sizing: border-box;
      font-size: 0.32rem;
      padding: 0.2rem;
      line-height: 0.6rem;
      border: 1px solid #e5e5e5;
      margin-bottom: 0.2rem;
      background: #fff;
      .top_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0.1rem;
        border-bottom: 1px dashed #e5e5e5;
        .detail_btn {
          width: 1.2rem;
          height: 0.6rem;
          line-height: 0.6rem;
          background: #00C85E;
          color: #fff;
          border-radius: 5px;
        }
        .no_detail {
          background: #bbb;
        }
      }
      .item_center {
        box-sizing: border-box;
        padding: 0.1rem 0;
        text-align: left;
        font-size: 0.3rem;
        div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .handle_item {
        width: 100%;
        height: 0.6rem;
        display: flex;
        flex-direction: row;
        // justify-content: space-around;
        .btn {
          width: 1.6rem;
          height: 0.6rem;
          line-height: 0.6rem;
          color: #fff;
          border-radius: 5px;
          margin-right: 10px;
          font-size: 0.3rem;
        }
        .pay {
          background: rgb(219, 131, 10);
        }
        .tips {
          background: rgb(3, 129, 3);
        }
        .group {
          background: rgb(125, 19, 224);
        }
      }
      .show_groups {
        padding: 10px;
        margin-top: 10px;
        padding-bottom: 0;
        border: 1px solid #e5e5e5;
        border-radius: 6px;
        .group_item {
          color: #555;
          border-bottom: 1px solid #e5e5e5;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
            border-bottom: none;
          }
          .top_title {
            border-bottom: none;
            padding-bottom: 0;
          }
          .handle_item {
            margin-bottom: 10px;
            justify-content: flex-end;
            .btn {
              margin-right: 0;
              margin-left: 10px;
              a {
                display: block;     
                margin-left: 0;
              }
            }
            .not_btn {
              background: #ccc;
            }
          }
        }
      }
    }
  }
  .listItemWrap{
    background: #F8F8F8;
    border-radius: 0.2rem;
  }
  .load-more{
    cursor: pointer;
  }
}
</style>