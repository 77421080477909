<template>
  <div class="infoShow">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="infoShowTitle">Information setting</p>
    </div>
    <div class="contentWrap">
      <div class="contentWrap-sec">
        <div class="contentWrap-sec-title">
          The basic information
          <el-button type="primary" size="mini" @click="setBasicInfo">Save</el-button>
        </div>
        <div :class="flexClass">
          <div class="form-item">
            <div class="form-item-label">Cell phone</div>
            <div class="form-item-content">
              <el-input maxlength="11" v-model="form.phone" placeholder="Cell phone" :clearable="true"></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">Email</div>
            <div class="form-item-content">
              <el-input v-model="form.email" placeholder="Email" :clearable="true"></el-input>
            </div>
          </div>
          <div class="form-item">
            <div class="form-item-label">Password</div>
            <div class="form-item-content">
              <el-input v-model="form.password" type="password" placeholder="Password" :clearable="true"></el-input>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="form-item-label">Address</div>
          <div class="form-item-content">
            <el-input type="textarea" v-model="form.address" placeholder="Address" :clearable="true"></el-input>
          </div>
        </div>
      </div>
      <div class="contentWrap-sec">
        <div class="contentWrap-sec-title">
          The billing info
          <el-button type="primary" size="mini" @click="addCreditDialog=true;">Add</el-button>
        </div>
        <div class="form-item">
          <div class="form-item-content">
            <el-table
              :data="form.cards"
              empty-text="No data"
              stripe border>
              <el-table-column
                label="Number">
                <template slot-scope="scope">
                  <sub>****</sub> {{scope.row.last4}}
                </template>
              </el-table-column>
              <el-table-column
                prop="brand"
                label="Brand">
              </el-table-column>
              <el-table-column
                label="Exp">
                <template slot-scope="scope">
                  {{scope.row.exp_month}}/{{scope.row.exp_year}}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <AddCreditCard :dialogShow.sync="addCreditDialog"
      @addCardSuccess="getCards"></AddCreditCard>
  </div>
</template>
<script>
  import md5 from "js-md5"
  import AddCreditCard from '@/components/addCreditCard.vue'
  import { stripePay } from '@/mixin/stripePay.js'
  export default {
    name: 'inforSetting',
    components: { AddCreditCard },
    mixins: [stripePay],
    data() {
      return {
        form: {
          phone: '',
          email: '',
          password: '',
          address: '',
          cards: []
        },
        oldPassword: '', // 旧密码
        addCreditDialog: false,
      }
    },
    computed: {
      flexClass() {
        return this.isPhone ? '' : 'flex-class'
      }
    },
    mounted() {
      this.getBasicInfo();
      this.getCards();
    },
    methods: {
      getBasicInfo() {
        this.$http({
          url: '/api/user/get/setting',
          method: "GET",
          params: {},
        }).then(res => {
          const data = res.data;
          if (data.code) {
            this.$message({message: res.data.message, type: 'error'});
          } else {
            const { phone, email, password, home_address } = data;
            this.form.phone = phone;
            this.form.email = email;
            this.form.password = password;
            this.form.address = home_address;
            this.oldPassword = password;
          }
        })
      },
      setBasicInfo() {
        if (!this.form.email) {
          this.$message({ showClose: false, message: 'Email is required!', type: 'warning' });
          return;
        }
        if (!this.form.password) {
          this.$message({ showClose: false, message: 'Passwrod is required!', type: 'warning' });
          return;
        }
        const parmas = Object.assign({}, this.form, {
          old_password: this.oldPassword,
          new_password: md5(this.form.password),
        });
        this.$http({
          url: '/api/user/setting',
          method: "POST",
          data: parmas,
        }).then(res => {
          const data = res.data;
          if (data.code) {
            this.$message({message: res.data.message, type: 'error'});
          } else {
            this.$message({message: 'Set information successfully', type: 'success'});
            localStorage.setItem("loginName", this.form.email);
          }
        })
      },
      async getCards() {
        const customer = await this.getCustomer();
        const payMethods = await this.getPayMethods(customer.id);
        this.form.cards = payMethods.map(v => {
          return v.card;
        }) || [];
      },
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
<style lang="scss" scoped>
.infoShow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.infoShowTitle {
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}

.contentWrap {
  width: 90%;
  margin: 0 auto;
  position: absolute;
  top: 1.4rem;
  left: 0;
  right: 0;

  &-sec {
    margin: .3rem 0;
    border-radius: .08rem;
    border: 1px solid #ebeef5;
    background: #fff;
    padding: .4rem;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);

    &-title {
      padding-bottom: .2rem;
      font-size: .4rem;
      color: #333;
      border-bottom: 1px solid #ccc;
      text-align: left;
    }

  }

  .flex-class {
    display: flex;
    justify-content: space-between;

    .form-item {
      width: 30%;
    }
  }

  .form-item {
    margin-top: .4rem;
    text-align: left;

    &-label {
      margin-bottom: .2rem;
      font-size: .36rem;
      color: #606266;
    }

    &-content {
      >div {
        width: 100%;
      }
    }

  }

  button {
    width: 1.4rem;
    float: right;
    padding: .1rem .06rem;
    font-size: .24rem;
  }

}

</style>