<!--
 * @Author: your name
 * @Date: 2021-01-26 13:19:04
 * @LastEditTime: 2021-02-03 14:23:07
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \shiptal-frontend\src\components\multiDelete.vue
-->
<template>
  <div>
    <!-- 删除地址弹窗 -->
    <el-dialog
      :title="deleteType == 'all'?'Clear whole route':''"
      :visible.sync="deleteDialog"
      :close-on-click-modal="false"
      width="60%"
      :show-close="true"
      class="add_stop_dialog delete_dialog"
      center>
        <div class="delete_title">
          <i class="el-icon-warning"></i>
          <span v-if="deleteType == 'all'">This operation will clear the whole route. Do you want to continue?</span>
          <span v-else>Do you want to delete this stop?</span>
        </div>
        <div class="dialog_btn">
          <div class="btn cancel" @click="deleteCancel()">Cancel</div>
          <div class="btn add" @click="deleteConfirm()">Continue</div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'multiDelete',
    components: {},
    props: {
      deleteType: {
        default: function() {
          return ''
        },
        type: String
      },
      deleteDialog: {
        // default: function() {
        //   return false
        // },
        type: Boolean
      }
    },
    data() {
      return {}
    },
    mounted() {},
    methods: {
      deleteCancel() {
        this.$emit('deleteCancel')
      },
      deleteConfirm() {
        this.$emit('deleteConfirm')
      }
    }
  }
</script>
<style scoped="scoped">

</style>