<template>
  <div class="myMultiDetail">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="myOrderTitle">{{orderInfoName}}</p>
    </div>
    <div class="TaskListContainer" v-loading="loading">
      <div class="show_map">
        <div id="mapWrap"></div>
      </div>
      <div class="multi_total">
        <span>Groups: </span>{{orderGroupList.length}} total
      </div>
      <div class="show_list">
        <el-collapse accordion @change="collapseChange" v-model="selectedGroupIndex">
          <el-collapse-item v-for="(item,index) in orderGroupList" :key="index" :name="index">
            <template slot="title">
              <div class="group_detail">
                <span><i :style="{'backgroundColor':item.groupColor}"></i>{{item.group_name}}</span>
                <span>Time: {{item.total_time | timeFilter}}</span>
                <span>{{item.doneNum}} done / {{item.address_list.length}} drops</span>
              </div>
            </template>
            <div class="address_detail">
              <div class="mult_list_item" v-for="(addItem, addIndex) in item.address_list" 
                :class="(selectedId==addItem.order_index?'select_item':'')" :key="addIndex">
                <div class="mult_item" @click="showHandle(addItem, addIndex,index)">
                  <div class="order_no">
                    <i >{{addIndex+1}}</i>
                  </div>
                  <div class="address">
                    <p><span v-if="addItem.room">{{addItem.room}}, </span>{{addItem.address}}</p>
                    <p class="comment" v-if="selectedId==addItem.order_index && addItem.comment">Comment: {{addItem.comment}}</p>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment"
  // @ is an alias to /src
  let GroupColors=['#46baef','#4eef46','#141814','#8e6dec','#f1836d',
  '#eaea3d', '#eedcdc','#bca968','#dceee9','#065b44','#e38bd6','#00a6ac','#867892','#f7acbc']
  let markersList = []
  export default {
    name: 'myOrderDetail',
    data() {
      return {
        isPCFlag: false,
        loadMoreText:'',
        sender_id: '',
        orderId: '',
        orderDetailInfo: {},
        orderGroupList: [],
        orderInfoName: '',
        selectedId: 0,
        selectedGroupIndex: 0,
        // 地图相关
        map: null,
        currentTask: {},
        loading: false
      }
    },
    filters: {
      timeFilter(time) {
        if (time) {
          if (time < 60) {
            return time + 'm'
          } else {
            return Math.floor(time/60)+"h "+((time%60)==0?"":(time%60)+"m")
          }
        } else {
          return ''
        }
      }
    },
    created() {
      this.orderId = this.$route.query.id
      this.getGoogleLocation();
    },
    mounted() {
      this.loadMoreText = this.isPhone ? 'Pull up to load more': 'Click to load more'
      if(localStorage.getItem("loginInfo")){
        this.sender_id = JSON.parse(localStorage.getItem("loginInfo")).user_id;
      }
      this.IsPC()
      this.initMap()
      this.getOrderDetail()
    },
    methods: {
      collapseChange(e) {
      },
      IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
                    "SymbianOS", "Windows Phone",
                    "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        this.isPCFlag = flag
      },
      getOrderDetail(){
        this.loading = true
        this.$http({
          url: "/api/batch/order/info/v2",
          method: "GET",
          params: {
            id: this.orderId
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code){
            this.loading = false
            this.enableLoadMore = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }else{
            this.loading = false
            this.orderDetailInfo = res.data.data
            var group = res.data.data.group
            this.orderInfoName = res.data.data.order_name
            var addressList = []
            group.forEach((item,index)=>{
              item.doneNum = 0
              item.groupColor = GroupColors[item.group_id]
              item.address_list.forEach((ele,i) => {
                var groupId = ele.group_id
                ele.groupBgIndex = groupId
                ele.groupIndex = index
                ele.groupColor = GroupColors[groupId]
                ele.lat = ele.latitude
                ele.lng = ele.long
                ele.childIndex = i
                if (ele.status == 6) {
                  item.doneNum ++
                }
              })
              this.markMap(item.address_list);
            })
            this.orderGroupList = group
          }
        })
      },
      goBack(){
        this.$router.push({
          path: "/" + "myMultiOrder",
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      },
      showHandle(handleItem,index,groupIndex) {
        this.selectedId = handleItem.order_index
        this.selectedGroupIndex = groupIndex-0
        this.currentTask = handleItem
        this.changeMarkClass(1)
        this.centerMap()
      },
      // 以下方法是地图相关
      initMap() {
        let _this = this;
        this.loader.load().then(() => {
          _this.map = new google.maps.Map(document.getElementById("mapWrap"), {
            center: {
              lat: 37.382767,
              lng: -122.023248
            },
            zoom: 10,
          });
        });
      },
      centerMap() {
        let centerPos;
        centerPos = {
          lat: this.currentTask.latitude,
          lng: this.currentTask.long
        }
        this.map.setCenter(centerPos)
      },
      markMap(addressList) {
        let _this = this;
        this.loader.load().then(() => {
          for (let i = 0; i < addressList.length; i++ ) {
            var orderIndex = i+1
            var item = addressList[i]
            let contentSender = document.createElement("div");
            contentSender.innerText = (orderIndex)
            contentSender.classList.add('sender')
            contentSender.setAttribute("order_id", item.order_index)
            contentSender.setAttribute("type", "sender")
            contentSender.setAttribute("order", i)
            contentSender.setAttribute("groupbg", item.groupBgIndex)
            contentSender.setAttribute("groupIndex", item.groupIndex)
            contentSender.style.backgroundImage = "url(" 
              + require("../../assets/image/posi/posi"+item.groupBgIndex+".png") + ")";
            let popupSender = new this.Popup(
              new google.maps.LatLng(item.lat, item.lng),
              contentSender,
              _this.bubbleClick
            );
            popupSender.setMap(this.map);
          }
        });
      },
      bubbleClick(e) {
        let ele = e.target;
        let index = ele.getAttribute("order")
        let groupIndex = ele.getAttribute("groupIndex")
        let item = this.orderGroupList[groupIndex].address_list[index]
        let id = item.order_index
        this.showHandle(item,id,groupIndex)
      },
      // 修改点标记的状态class
      changeMarkClass(handle) {
        // handle 1代表选中 2 代表完成
        var domArr = document.getElementsByClassName('popup-bubble')
        for (let i = 0; i < domArr.length; i++) {
          var domItem = domArr[i]
          var domIndex = domItem.getAttribute('order_id')
          domItem.classList.remove('nowhandle')
          if (domIndex == this.selectedId) {
            if (handle == 1) {
              domItem.classList.add('nowhandle')
            } else {
              domItem.classList.add('deactive')
            }
          }
        }
      },
      getGoogleLocation(){
        this.$http({
          url: "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDR0LyFODkJ8CS3B8bGO_YoCzK3C279O90",
          method: "POST",
          data: {
            considerIp:"true"
          }
        }).then(res => {
          if(res.status == 200){
            let positionObj = res.data.location;
            this.currentLocation = positionObj;
          }else{
            this.$message.closeAll();
            this.$message({
              message: positionObj.errorInfo,
              type: 'error'
            });
          }
        }).catch(()=>{
	        this.$message.closeAll();
	        this.$message({
	          message: "Can not find your location",
	          type: 'error'
	        });
        })
      },
    }
  }
</script>
<style lang="scss">
.myMultiDetail {
  .header {
    height: 1rem;
  }
  .myOrderTitle{
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1rem;
  }
  #mapWrap {
    height: 4.5rem;
    position: relative;
  }
  .TaskListContainer {
    width: 7.18rem;
    height: calc(100% - 1rem);
    top: 1rem;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    z-index: 10;
    border-radius: 0.2rem;
  }
  .multi_total {
    width: 7.18rem;
    height: 1rem;
    line-height: 1rem;
    margin: auto;
    // border-bottom: 1px solid #ccc;
    position: fixed;
    z-index: 10;
    text-align: left;
    box-sizing: border-box;
    padding: 0 0.2rem;
    font-size: 0.3rem;
    background: #fff;
    span {
      // color:#00C85E;
      font-size: 0.35rem;
    }
    i {
      font-style: normal;
      margin-left: 0.1rem;
      font-size: 0.3rem;
    }
  }
  .show_list {
    width: 100%;
    height: calc(100% - 4.5rem);
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    overflow: auto;
    .group_detail {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        text-align: left;
      }
      span:nth-child(1) {
        flex: 2;
        display: flex;
        flex-direction: row;
        align-items: center;
        i {
          width: 18px;
          height: 10px;
          margin-right: 5px;
        }
      }
      span:nth-child(2) {
        flex: 2;
      }
      span:nth-child(3) {
        flex: 2;
      }
    }
    .mult_list_item {
      border-bottom: 1px dashed #ccc;
      background: rgba(0, 200, 94,0.1);
      &:last-child{
        border-bottom: none;
      }
    }
    .el-collapse-item__content {
      padding-bottom: 0;
    }
    .mult_item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.25rem 0.2rem;
      font-size: 0.3rem;
      line-height: 0.4rem;
      box-sizing: border-box;
      .order_no {
        margin-right: 0.1rem;
        font-size: 0.25rem;
        display: flex;
        justify-content: space-around;
        i {
          height: 0.38rem;
          line-height: 0.36rem;
          border: 1px solid #00C85E;
          width: 0.38rem;
          color: #00C85E;
          text-align: center;
          box-sizing: border-box;
          border-radius: 50%;
          font-style: normal;
        }
      }
      .type {
        width: 1rem;
        height: 0.45rem;
        line-height: 0.45rem;
        border-radius: 5px;
        font-size: 0.3rem;
        margin-right: 0.1rem;
        color: #fff;
      }
      .type_pickup {
        background: #E6A23C;
      }
      .type_delivery {
        background: #409EFF;
      }
      .type_pickup_done {
        background: rgba(230, 162, 60, 0.2);
      }
      .type_delivery_done {
        background: rgba(64, 158, 255, 0.2);
      }
      .address {
        flex: 1;
        p {
          text-align: left;
        }
        display: flex;
        flex-direction: column;
        .comment {
          padding-top: 6px;
        }
      }
    }
    .select_item {
      background: rgba(255, 194, 7,0.15);
    }
  }
}
</style>