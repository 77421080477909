<template>
  <div class="login">
    <div class="header">
      <p class="loginTitle">{{getTitle()}}</p>
      <span class="jumpToOther" @click="jumpToOther">
      		{{getJumpTitle()}}
      </span>
    </div>
    <div class="loginWrap">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="formWrap">
        <p class="formTitle">Email</p>
        <p>
          <el-input v-model="Email" placeholder="Email"></el-input>
        </p>
        <p class="formTitle">Password</p>
        <p>
          <el-input v-model="Password" placeholder="Password" show-password></el-input>
        </p>
        <p class="loginActionWrap">
          <span @click="resetPass">Forget password?</span>
          <span @click="needRegister">Sign Up</span>
        </p>
      </div>
      <div class="actionCommon" @click="loginFn">Login</div>
    </div>
  </div>
</template>

<script>
  import md5 from "js-md5"
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'login',
    components: {
      cusCheckbox
    },
    data() {
      return {
        type: "user",
        Email: "",
        Password: "",
        policyChecked: {
          checked: false
        },
        msg1: `《user protocol》`,
        msg2: `《Privacy Policy》`,
      }
    },
    mounted() {
      if(localStorage.getItem("registedEmail")) {
        this.Email = localStorage.getItem("registedEmail");
      }
      if(this.$route.path == "/loginUser") {
        this.type = "user";
      } else {
        this.type = "driver";
      }
    },
    methods: {
      getTitle() {
        if(this.type == "user") {
          return "Login"
        } else {
          return "Courier Login"
        }
      },
      getJumpTitle() {
        if(this.type == "user") {
          return "Courier?"
        } else {
          return "User?"
        }
      },
      jumpToOther() {
        if(this.type == "user") {
          location.href = ("/loginDriver")
        } else {
          location.href = ("/loginUser")
        }
      },
      needRegister() {
        if(this.type == "user") {
          this.jumpRoute('registerUser')
        } else {
          this.jumpRoute('registerDriver')
        }
      },
      resetPass(){
        this.jumpRoute('resetPass')
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          params: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
      loginFn() {
        if(!this.Email || !this.Password) {
          this.$message.closeAll();
          this.$message({
            message: 'Please input your Email and Password',
            type: 'warning'
          });
          return;
        }
        let urltemp = "";
        this.type == "user" ? urltemp = "/api/user/login" : urltemp = "/api/driver/login"
        this.$http({
          url: urltemp,
          method: "POST",
          data: {
            email: this.Email,
            password: md5(this.Password)
          }
        }).then(res => {
          if(res.data.code) {
            this.$message.closeAll();
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            localStorage.setItem("loginInfo", JSON.stringify(res.data.data));
            localStorage.setItem("loginName", this.Email);
            localStorage.setItem("customer", '');
            if(this.type == "user") {
              const path = this.$route.query.redirect
              localStorage.setItem("userType", "user")
              if (path) {
                this.jumpRoute(path)
              } else {
                this.jumpRoute("mainPage")
              }
            } else {
              //status 状态 1:未审核 ，2：审核通过，3:拒绝
              let status = res.data.data.status;
              if(status == 2) {
                localStorage.setItem("userType", "driver")
                this.jumpRoute("mainTaskPage");
              } else {
                this.jumpRoute("verifyStatus", status);
              }
            }
          }
        })
      }
    }
  }
</script>
<style scoped="scoped">
  .login {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .loginTitle {
    font-size: 0.48rem;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #4C4C4C;
    line-height: 1.4rem;
    padding-left: 0.4rem;
    text-align: left;
  }
  
  .loginWrap {
    font-size: 0;
    position: relative;
    top: 1.4rem;
    height: calc(100% - 1.4rem);
  }
  
  .logo>img {
    margin: 1.2rem 0;
    width: 2.8rem;
    height: 2.02rem;
  }
  
  .formTitle {
    font-size: 0.42rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 0.58rem;
    text-align: left;
  }
  
  .formWrap>* {
    padding: 0 0.4rem 0.12rem 0.4rem;
    width: 6.7rem;
    margin: auto;
  }
  
  .formWrap .el-input {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  
  .policyWrap {
    position: relative;
    height: 0.74rem;
  }
  
  .cusCheckbox {
    top: 0.1rem;
  }
  
  .readPolicy {
    float: right;
    text-align: left;
    font-size: 0.27rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4c4c4c;
    line-height: 0.34rem;
    width: calc(100% - 0.7rem);
    margin: initial;
  }
  
  .policyLink {
    color: #6690FF;
  }
  
  .loginWrap>.actionCommon {
    margin: auto;
    margin-top: 0.8rem;
  }
  
  .loginActionWrap {
    font-size: 0.28rem;
    font-family: Helvetica;
    color: #5483FF;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .jumpToOther {
    position: absolute;
    right: 0.4rem;
    top: 0.4rem;
    text-decoration: underline;
  }
</style>