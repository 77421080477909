<template>
  <div class="mainTaskPage">
    <el-dialog title="Location" :visible.sync="locationVisible" :show-close="false" :close-on-click-modal="false" width="80%">
      <p style="line-height: 1rem;">We need your location to pair tasks.</p>
      <div class="singleInfoInput">
        <div class="el-input">
          <input type="text" id="senderAddress" class="autocompleteFiled" placeholder="" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer  custom-footer">
		    <el-button @click="locationVisible = false">No</el-button>
		    <el-button class="actionSummary" @click="locationVisible = false">Yes</el-button>
		  </span>
    </el-dialog>
    <div class="header">
      <div class="center_info">
        Shiptal
      </div>
      <div class="user_icon" @click="toggleMenu = !toggleMenu">
        <img src="../../assets/image/user.png" />
      </div>
      <div class="menu_icon" @click="showLocationCheck">
        <i class="el-icon-map-location"></i>
      </div>
    </div>
    <div class="TaskListContainer" @click="checkToggleMenu">
      <!-- <div class="mainPhoto">
        <img src="../../assets/image/logo.png" />
      </div> -->
      <div class="taskContainer">
        <p class="taskTitle">
          <span><img src="../../assets/image/logo.png" /></span>
          Tasks to accept: {{toAcceptToday + toAccepTomorrow + toAccepAfterday}}
        </p>
        <div class="singleOperation" @click="jumpCheck('toAcceptTask','Today')">
          <p>Today's tasks to accept: {{toAcceptToday}}</p>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="singleOperation" @click="jumpCheck('toAcceptTask','Tomorrow')">
          <p>Tomorrow ({{dateOption[1].month + "/" + dateOption[1].day}}) tasks to accept: {{toAccepTomorrow}}</p>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="singleOperation" @click="jumpCheck('toAcceptTask','afterday')">
          <p>{{dateOption[2].month + "/" + dateOption[2].day}}'s tasks to accept: {{toAccepAfterday}}</p>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="taskContainer">
        <p class="taskTitle">
          Tasks to do: {{todoToday + todoTomorrow + todoAfterday}}
          <span><img src="../../assets/image/logo.png" /></span>
        </p>
        <div class="singleOperation" @click="jumpRoute('dayDeliveryTask')">
          <p>Today's tasks to do: {{todoToday}}</p>
          <i class="el-icon-arrow-right"></i>
        </div>
        <!-- <div class="singleOperation" @click="jumpCheck('toDeliverTask','Tomorrow')">
          <p>Tomorrow ({{dateOption[1].month + "/" + dateOption[1].day}}) tasks to do: {{todoTomorrow}}</p>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="singleOperation" @click="jumpCheck('toDeliverTask','afterday')">
          <p>{{dateOption[2].month + "/" + dateOption[2].day}}'s tasks to do: {{todoAfterday}}</p>
          <i class="el-icon-arrow-right"></i>
        </div> -->
        <div class="taskOtherInfo">
          <div class="singleOperation" @click="jumpCheck('completedTask')">
            <!--<div class="singleOperation">-->
            <p>Completed tasks</p>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="singleOperation" @click="jumpCheck('wallet')">
            <p>Total income: ${{income / 100}}</p>
            <!--<i class="el-icon-arrow-right"></i>-->
          </div>
        </div>
      </div>
    </div>
    <div class="toggleMenus" v-show="toggleMenu">
      <div>
        <img src="../../assets/image/logo.png" />
        <p v-if="name" class="nameTitle">{{name}}</p>
        <p v-if="id" class="nameTitle">ID:{{id}}</p>
        <span v-if="!name" @click="jumpRoute('loginDriver')">login</span>
      </div>
      <div class="actionMenu" @click="jumpWallet">Wallet</div>
      <div class="actionMenu" @click="jumpRoute('personalPage')">Personal</div>
      <div class="actionMenu" @click="jumpRoute('setting')">Setting</div>
      <div class="actionMenu danger" @click="logout" v-if="name">Logout</div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'mainTaskPage',
    components: {},
    data() {
      return {
        name: "",
        id:"",
        locationVisible: false,
        currentLocation: {},
        toggleMenu: false,
        todoToday: 0,
        todoTomorrow: 0,
        todoAfterday: 0,
        toAcceptToday: 0,
        toAccepTomorrow: 0,
        toAccepAfterday: 0,
        completed: 0,
        income: 0,
        dateOption: this.getDateOption(),
        driver_id: ""
      }
    },
    mounted() {
      this.getPriceRule()
      this.driver_id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).id : "";
      if(!this.driver_id) {
        this.jumpRoute("loginDriver")
        return;
      }
      this.getCurrentLocation();
      this.name = localStorage.getItem("loginName")
      this.getDriverInfo();
      //this.getComplatedTasks();
      this.getTodoListNew();
    },
    methods: {
    	getComplatedTasks(){
        this.$http({
          url: "/api/order/driver/o",
          method: "GET",
          params: {
            driver_id: this.driver_id,
            status: 6
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: "Get completed number error",
              type: 'error'
            });
          } else {
            let hisList = res.data.data.not.length;
            let todayList = res.data.data.today.length;
            this.completed = hisList + todayList;
          }
        })
      },
      jumpWallet(){
      	if(!this.driver_id) {
	        this.jumpRoute("loginDriver")
	        return;
	      }
      	this.jumpRoute("wallet")
      },
      getGoogleLocation(){
        this.$http({
          url: "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyDR0LyFODkJ8CS3B8bGO_YoCzK3C279O90",
          method: "POST",
          data: {
            considerIp:"true"
          }
        }).then(res => {
          if(res.status == 200){
            let positionObj = res.data.location;
            localStorage.setItem("position", JSON.stringify(positionObj))
            this.currentLocation = positionObj;
            //今日todo
            // this.getTodoList();
            //今日accept
            this.getTodayToAcceptList();
          }else{
            this.showLocationCheck();
            this.$message.closeAll();
            this.$message({
              message: positionObj.errorInfo,
              type: 'error'
            });
          }
        }).catch(()=>{
        	this.showLocationCheck();
	        this.$message.closeAll();
	        this.$message({
	          message: "Can not find your location",
	          type: 'error'
	        });
        })
      },
      showLocationCheck() {
        this.locationVisible = true;
        this.$nextTick(() => {
          this.initInputField();
        });
      },
      initInputField() {
        let _this = this;
        this.loader.load().then(() => {
          let senderAddressEle = document.getElementById('senderAddress');
          let autosenderAddress = new google.maps.places.Autocomplete(senderAddressEle);
          autosenderAddress.addListener("place_changed", () => {
            let senderAddress = autosenderAddress.getPlace();
            if(!senderAddress.geometry) {
              this.$message.closeAll();
              this.$message({
                showClose: false,
                message: 'Please enter the specific address',
                type: 'warning'
              });
            } else {
              let positionObj = {
                lat: senderAddress.geometry.location.lat(),
                lng: senderAddress.geometry.location.lng()
              }
              localStorage.setItem("position", JSON.stringify(positionObj))
              this.currentLocation = positionObj;
              //今日todo
              // this.getTodoList();
              //今日accept
              this.getTodayToAcceptList();
              //以后accept
              //this.getAfterDayToAcceptList();

            }
          })
        });
      },
      getDriverInfo() {
        this.$http({
          url: "/api/driver/get",
          method: "GET",
          params: {
            driver_id: this.driver_id
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.completed = res.data.data.total_order;
            this.income = res.data.data.total_salary;
            this.id = res.data.data.id;
          }
        })
      },
      getCurrentLocation() {
        let locationFound = localStorage.getItem("locationFound");
        if(locationFound) {
          if(locationFound == "true") {
            //更新一下
            this.getLocation(this.locationFound);
          } else {
            this.getGoogleLocation()
          }
        } else {
          this.getLocation(this.locationFound);
        }
      },
      locationFound(positionObj) {
        if(positionObj.errorInfo) {
          localStorage.setItem("locationFound", "false");
          this.getGoogleLocation()

        } else {
          localStorage.setItem("locationFound", "true");
          localStorage.setItem("position", JSON.stringify(positionObj))
          this.currentLocation = positionObj;
          //今日todo
          // this.getTodoList();
          //今日accept
          this.getTodayToAcceptList();
          //以后accept
          //this.getAfterDayToAcceptList();
        }
      },
      getAfterDayToAcceptList() {
        let begin_time = this.dateOption[1].year + "/" + this.dateOption[1].month + "/" + this.dateOption[1].day + " 00:00:00";
        let end_time = this.dateOption[2].year + "/" + this.dateOption[2].month + "/" + this.dateOption[2].day + " 23:59:59";
        this.$http({
          url: "/api/order/history",
          method: "GET",
          params: {
            driver_id: this.driver_id,
            begin_time: Math.floor(new Date(begin_time).getTime() / 1000),
            end_time: Math.floor(new Date(end_time).getTime() / 1000),
            status: 0,
            sender_id: "",
            page_index: 1
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            if(res.data.code == 130011) {
              this.toAccepTomorrow = 0;
              this.toAccepAfterday = 0;
            } else {
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            }
          } else {
            // TODO
          }
        })
      },
      getTodayToAcceptList() {
        this.$http({
          url: "/api/order/dispatch",
          method: "GET",
          params: {
            driver_id: this.driver_id,
            driver_longitude: this.currentLocation.lng,
            driver_latitude: this.currentLocation.lat,
            driver_current_address:""
            //method: 1
          }
        }).then(res => {
          if(res.data.code) {
            if(res.data.code == 130011) {
              this.toAcceptToday = 0;
            } else {
              this.$message.closeAll();
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            }
          } else {
            let assignedList = res.data.data.assigned;
            let platformList = res.data.data.platform;
            this.dealList("toAccept", res.data.data)
            //this.toAcceptToday = assignedList.length + platformList.length;
          }
        })
      },

      getTodoList() {
        this.$http({ //  /api/order/driver/o
          url: "/api/order/driver/o",
          method: "GET",
          params: {
            driver_id: this.driver_id,
            status: 2
          }
        }).then(res => {
          if(res.data.code) {
            this.$message.closeAll();
            if(res.data.code != "130011") {
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            } else {
              this.todoToday = 0;
              this.todoTomorrow = 0;
              this.todoAfterday = 0;
            }
          } else {
            let allList = res.data.data
            this.dealList("todo", allList)
            //          this.todoToday = allList.not.length + allList.today.length;
            //          this.todoTomorrow = allList.tomorrow.length;
            //          this.todoAfterday = allList.third.length;
          }
        })
      },
      getTodoListNew() {
        console.log(this.getDateOption());
        const today = (this.getDateOption())[0];
        const {year, month, day} = today;
        const searchTime = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
        this.$http({
          url: "/api/driver/order/list",
          method: "GET",
          params: {
            search_time: searchTime,
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.todoToday = res.data.data.length;
          }
        })
      },

      dealList(type, data) {
        let todayBaseline = this.dateOption[0].year + "/" + this.dateOption[0].month + "/" + this.dateOption[0].day + " 00:00:00";
        let tomorowBaseLine = this.dateOption[1].year + "/" + this.dateOption[1].month + "/" + this.dateOption[1].day + " 00:00:00";
        let afterdayBaseLine = this.dateOption[2].year + "/" + this.dateOption[2].month + "/" + this.dateOption[2].day + " 00:00:00";
        todayBaseline = Math.floor(new Date(todayBaseline).getTime() / 1000);
        tomorowBaseLine = Math.floor(new Date(tomorowBaseLine).getTime() / 1000);
        afterdayBaseLine = Math.floor(new Date(afterdayBaseLine).getTime() / 1000);
        if(type == "toAccept") {
          let assignedList = data.assigned;
          let platformList = data.platform;
          let todayList = [];
          let tomorrowList = [];
          let afterdayList = [];
          for(let i = 0; i < assignedList.length; i++) {
            if(assignedList[i].expected_time > afterdayBaseLine) {
              afterdayList.push(assignedList[i])
            } else if(assignedList[i].expected_time > tomorowBaseLine) {
              tomorrowList.push(assignedList[i])
            } else {
              todayList.push(assignedList[i])
            }
          }
          for(let i = 0; i < platformList.length; i++) {
            if(platformList[i].expected_time > afterdayBaseLine) {
              afterdayList.push(platformList[i])
            } else if(platformList[i].expected_time > tomorowBaseLine) {
              tomorrowList.push(platformList[i])
            } else {
              todayList.push(platformList[i])
            }
          }
          this.toAcceptToday = todayList.length;
          this.toAccepTomorrow = tomorrowList.length;
          this.toAccepAfterday = afterdayList.length;
        } else if(type == "todo") {
          let historyList = data.not;
          let todayList = data.today;
          let tomorrowList = data.tomorrow;
          let afterdayList = data.third;
          
      
          this.todoToday = todayList.length + historyList.length;
          this.todoTomorrow = tomorrowList.length;
          this.todoAfterday = afterdayList.length;
        }
      },
      
      logout() {
        //localStorage.removeItem("loginName")
        //localStorage.removeItem("loginInfo")
        localStorage.clear()
        this.jumpRoute("loginDriver")
      },
      jumpCheck(path, type) {
        if("toAcceptTask" == path) {
          if(type == "Today") {
            this.jumpRoute(path, type)
            //this.toAcceptToday ? this.jumpRoute(path, type) : void(0);
          } else if(type == "Tomorrow") {
            this.toAccepTomorrow ? this.jumpRoute(path, type) : void(0);
          } else {
            this.toAccepAfterday ? this.jumpRoute(path, type) : void(0);
          }
        } else if("toDeliverTask" == path) {
          if(type == "Today") {
            this.todoToday ? this.jumpRoute(path, type) : void(0);
          } else if(type == "Tomorrow") {
            this.todoTomorrow ? this.jumpRoute(path, type) : void(0);
          } else {
            this.todoAfterday ? this.jumpRoute(path, type) : void(0);
          }
        } else {
        	this.jumpRoute(path, type)
        }
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          query: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
      checkToggleMenu(){
        this.toggleMenu ? this.toggleMenu = false : void(0)
      }
    }
  }
</script>
<style scoped="scoped">
  .TaskListContainer {
    width: 100%;
    height: calc(100% - 1.4rem);
    top: 1.4rem;
    position: absolute;
    z-index: 10;
    background: #FFFFFF;
  }
  
  .mainPhoto {
    width: 7.1rem;
    height: 4.2rem;
    border-radius: 0.16rem;
    background: #F8F8F8;
    margin: auto;
  }
  
  .mainPhoto>img {
    height: 50%;
    margin: auto;
    transform: translateY(50%);
  }
  
  .taskContainer {
    width: 7.18rem;
    height: 5.5rem;
    background: #F8F8F8;
    border-radius: 0.2rem;
    margin: auto;
    margin-top: 0.4rem;
    padding: 0 0.24rem;
    box-sizing: border-box;
  }
  
  .singleOperation {
    width: 100%;
    height: 1.1rem;
    background: #E5F3FA;
    border-radius: 0.12rem;
    border: 0.02rem solid #D9EEF9;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 1.1rem;
    padding-left: 0.2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 0.3rem;
    overflow: hidden;
  }
  
  .taskTitle {
    text-align: left;
    height: 1.2rem;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 1.2rem;
  }
  .taskTitle span {
    width: 0.6rem;
    float: right;
  }
  .taskTitle span img {
    width: 100%;
  }
</style>