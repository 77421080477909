<template>
  <div class="setting">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="settingTitle">Setting</p>
    </div>
    <div class="moreOperation">
      <!-- <div class="singleOperation" @click="jumpRoute('aboutUs')">
        <p>About us</p>
        <i class="el-icon-arrow-right"></i>
      </div> -->
      <div class="singleOperation" v-show="isLogin" @click="jumpRoute('inforSetting')">
        <p>Personal setting</p>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="singleOperation">
        <p>User agreement</p>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="singleOperation">
        <p>Privacy agreement</p>
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="actionCommon" @click="logout" v-show="isLogin">Log out</div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'setting',
    components: {},
    data() {
      return {
        isLogin: false
      }
    },
    mounted() {
      this.isLogin = localStorage.getItem("loginInfo") ? true : false
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      logout() {
        let userType = localStorage.getItem("userType")
        localStorage.clear()
        if(userType == "user") {
          this.jumpRoute("loginUser")
        } else {
          this.jumpRoute("loginDriver")
        }
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          query: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
    }
  }
</script>
<style scoped="scoped">
  .setting {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .settingTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  
  .moreOperation {
    width: 7.5rem;
    margin: auto;
    position: relative;
    top: 1.4rem;
    box-sizing: border-box;
    padding: 0 0.4rem;
  }
  
  .singleOperation {
    width: 100%;
    height: 1.28rem;
    background: #F8F8F8;
    border-radius: 0.12rem;
    border: 0.02rem solid #C6C6C6;
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 1.28rem;
    padding: 0 0.32rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;
  }
</style>