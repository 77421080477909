<template>
  <div class="addMultiDelivery" :class="isPCFlag?'pc_style':''">
    <div class="header">
      <p class="deliveryTitle">Submit multi-stop delivery request</p>
    </div>
    <div class="multi_content" ref="multiListContent">
      <div class="multi_delivery_list" :class="multiList.length >= 10?'fixed_height' : ''">
        <div class="list_item" v-for="(item, index) in multiList" :key="index"
         :class="item.errorIndex?'error_bg':''">
          <div class="order_no">
            <i >{{index+1}}</i>
          </div>
          <div  class="flex-style">
            <div class="type type_pickup" v-if="item.type == 1">Pickup</div>
            <div class="type type_delivery" v-else>Delivery</div>
          </div>
          <div class="address">
            <p><span v-if="item.room">{{item.room}}, </span>{{item.address}}</p>
            <p class="comment" v-if="item.comment">Comment: {{item.comment}}</p>
          </div>
          <div class="flex-style-btn">
            <span class="edit_btn" @click="goEdit(item.type, index)">Edit</span>
          </div>
        </div>
      </div>
    </div>
      <div class="previous" v-if="multiList.length == 0 && preOrderId" @click="goPreOptimize">Previous Optimize route?</div>
    <div class="handle_btn">
      <div class="actionCommon" @click="addAStop()">Add a stop</div>
      <div class="more_handle">
        <div class="flex_row">
          <div class="actionCommon" :class="deliveryNum >= 1 && pickUpNum > 0 ? '' : 'unable'"
            @click="goOptRoute(1)">Optimize route</div>
          <!-- <div class="actionCommon" :class="deliveryNum >= 1 && pickUpNum > 0 ? '' : 'unable'"
            @click="goOptRoute(2)">Submit request</div> -->
        </div>
        <!-- <div class="go_more"  @click="showActionSheet"> -->
        <div class="go_more"  v-if="multiList.length > 0" @click="showActionSheet">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    </div>
    <Action :show="actionSheet" v-on:hide="hideActionSheet" v-on:goDeleteAll="goDeleteAll"></Action>
    <!-- dialog -->
    <el-dialog
      :title="isEdit==1?'Edit stop':'New stop'"
      :visible.sync="addAStopDialog"
      :close-on-click-modal="false"
      width="95%"
      :show-close="true"
      class="add_stop_dialog"
      center>
      <el-tabs type="border-card" class="dialog-tabs" v-model="tabsName" @tab-click="tabChange">
        <el-tab-pane label="Pickup" name="Pickup">
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">
                <span class="infoMust">*</span>
                Address</div>
              <div class="form_input">
                <div class="input_item el-input-address">
                  <input type="text" id="senderAddress" class="autocompleteFiled" placeholder=""
                   v-model="pickupForm.address"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">
                </div>
              <div class="form_input">
                <div class="input_item el-input-suite">
                  <input type="text" class="autocompleteFiled" placeholder="suite/floor/room/block# etc"
                   v-model="pickupForm.room"/>
                </div>
              </div>
            </div>
          </div>

          <div class="form_info">
            <div class="form_item">
              <div class="form_label">Contact</div>
              <div class="form_input">
                <div class="input_item el-input-phone">
                  <input type="text" class="autocompleteFiled" placeholder="4081116789" v-model="pickupForm.phone"/>
                </div>
                <div class="input_item el-input-email">
                  <input type="text" class="autocompleteFiled" placeholder="email" v-model="pickupForm.email"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">Comment</div>
              <div class="form_input">
                <div class="input_item el-input-all">
                  <input type="text" class="autocompleteFiled"
                   placeholder="e.g. gate code#, parking info" v-model="pickupForm.comment"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info">
            <div class="form_item">
              <div class="form_label form_label_coumln">
                <span>Pickup</span>
                <span>Time</span>
              </div>
              <div class="form_input">
                <div class="pickup_time" @click="openPicker">{{pickupForm.pick_time}}</div>
                <vue-pickers :data="pickupTimeOptions"
                  @cancel="timeCancel"
                  @confirm="timeConfirm"
                  :showToolbar="true"
                  :maskClick="true"
                  cancelText="cancel"
                  confirmText="confirm"
                  :visible.sync="timePickerVisible"
                ></vue-pickers>
              </div>
            </div>
          </div>
          <div class="form_info form_info_area">
            <div class="form_item">
              <div class="form_label form_label_coumln">
                <span>Packages</span>
                <span>list:</span>
              </div>
              <div class="form_input">
                <div class="input_item el-input-area">
                  <el-input v-model="pickupForm.packageList" class="text-area"
                    type="textarea"
                    :rows="4"
                    @blur="pickupPglblur"
                    @focus="pickupPglfocus"
                    placeholder=""></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog_btn">
            <div class="btn cancel" @click="dialogCancel(1)">Cancel</div>
            <div class="btn delete" v-if="isEdit == 1" @click="goDelete(1)">Delete</div>
            <div class="btn add" @click="dialogAdd(1)">{{isEdit == 1?'Edit':'Add'}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Delivery" name="Delivery">
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">
                <span class="infoMust">*</span>
                Address</div>
              <div class="form_input">
                <div class="input_item el-input-address">
                  <input type="text" id="receiverAddress" class="autocompleteFiled"
                   placeholder="" v-model="deliveryForm.address"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">
                </div>
              <div class="form_input">
                <div class="input_item el-input-suite">
                  <input type="text" class="autocompleteFiled" placeholder="suite/floor/room/block# etc"
                   v-model="deliveryForm.room"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">Contact</div>
              <div class="form_input">
                <div class="input_item el-input-phone">
                  <input type="text" class="autocompleteFiled" placeholder="1234567899" v-model="deliveryForm.phone"/>
                </div>
                <div class="input_item el-input-email">
                  <input type="text" class="autocompleteFiled" placeholder="email" v-model="deliveryForm.email"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info">
            <div class="form_item">
              <div class="form_label">Comment</div>
              <div class="form_input">
                <div class="input_item el-input-all">
                  <input type="text" class="autocompleteFiled" 
                    placeholder="e.g. gate code#, parking info" v-model="deliveryForm.comment"/>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info form_info_radio">
            <div class="form_item">
              <div class="form_label">Weight</div>
              <div class="form_input">
                <div class="input_item el-input-all">
                  <el-radio-group v-model="deliveryForm.weight">
                    <el-radio v-for="(item, index) in checkWeight" :key="index"
                    :label="index">{{item.title}}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info form_info_radio">
            <div class="form_item">
              <div class="form_label">Size</div>
              <div class="form_input">
                <div class="input_item el-input-all">
                  <el-radio-group v-model="deliveryForm.size">
                    <el-radio v-for="(item, index) in checkSize" :key="index"
                    :label="index">{{item.title}}</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="form_info form_info_area">
            <div class="form_item">
              <div class="form_label form_label_coumln">
                <span>Packages</span>
                <span>list:</span>
              </div>
              <div class="form_input">
                <div class="input_item el-input-area">
                  <el-input v-model="deliveryForm.packageList" class="text-area"
                    type="textarea"
                    :rows="4"
                    @blur="deliveryPglblur"
                    @focus="deliveryPglfocus"
                    placeholder=""></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog_btn">
            <div class="btn cancel" @click="dialogCancel(2)">Cancel</div>
            <div class="btn delete" v-if="isEdit == 1" @click="goDelete(2)">Delete</div>
            <div class="btn add" @click="dialogAdd(2)">{{isEdit == 1?'Edit':'Add'}}</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 删除地址弹窗 -->
    <el-dialog
      title="Clear whole route"
      :visible.sync="deleteDialog"
      :close-on-click-modal="false"
      width="95%"
      :show-close="true"
      class="add_stop_dialog delete_dialog"
      center>
        <div class="delete_title">
          <i class="el-icon-warning"></i>
          <span v-if="deleteType == 'all'">This operation will clear the whole route. Do you want to continue?</span>
          <span v-else>Do you want to delete this stop?</span>
        </div>
        <div class="dialog_btn">
          <div class="btn cancel" @click="deleteCancel()">Cancel</div>
          <div class="btn add" @click="deleteConfirm()">Continue</div>
        </div>
    </el-dialog>
    <!-- 绑卡支付 -->
    <AddCreditCard :dialogShow.sync="addCreditDialog" @addCardSuccess="addCardSuccess"></AddCreditCard>
    <SeTipAndPay :dialogShow.sync="setTipDialog" :tableData="payData" :order_id="orderId" @paySuccess="paySuccess"></SeTipAndPay>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from "moment"
  import vuePickers from 'vue-pickers'
  import Action from './../../components/actionSheet.vue'
  import AddCreditCard from './../../components/addCreditCard.vue'
  import SeTipAndPay from './../../components/seTipAndPay.vue'
  import {stripePay} from '../../mixin/stripePay.js'
  const placeholder = 'Package A1-A10\nOne package per row'
  export default {
    name: 'addMultiDelivery',
    mixins: [stripePay],
    data() {
      return {
        changeLock: false,
        deliveryNum: 0,
        pickUpNum: 0,
        multiList: [],
        orderId: '',
        preOrderId: 0,
        // dialog
        timePickerVisible: false,
        pickupTimeOptions: [
          []
        ],
        checkWeight: [],
        checkSize: [],
        addAStopDialog: false,
        tabsName: 'Pickup',
        isEdit: 2, //1 edit; 2 add
        editIndex: 0,
        pickupForm: {
          address: '',
          room: '',
          phone: '',
          email: '',
          comment: '',
          pick_time: '',
          packageList: 'Package A1-A10\nOne package per row',
          status: 1,
          type: 1,
          image_info: [],
          weight: 0,
          size: 0,
          errorIndex: 0,
          driver_id: '',
          driver_phone: ''
        },
        deliveryForm: {
          address: '',
          room: '',
          phone: '',
          email: '',
          comment: '',
          weight: 0,
          size: 0,
          packageList: 'Package A1-A10\nOne package per row',
          status: 1,
          type: 2,
          image_info: [],
          errorIndex: 0,
          driver_id: '',
          driver_phone: ''
        },
        isPCFlag: false,

        actionSheet: false,
        deleteDialog: false,
        deleteType: 'all', //all 代表全部删除 single 代表单条删除
        addCreditDialog: false,
        setTipDialog: false,
        payData: [],
      }
    },
    components: { vuePickers,Action, AddCreditCard, SeTipAndPay },
    created() {
      this.updatePayMothod();
    },
    mounted() {
      this.getPriceRule()
      if (localStorage.getItem("multiList")) {
        let localMultiList = JSON.parse(localStorage.getItem("multiList"))
        this.multiList = localMultiList
        this.sumTypeNum()
      }
      if (localStorage.getItem("multiOrderId")) {
        this.preOrderId = localStorage.getItem("multiOrderId")
      }
      this.IsPC()
    },
    methods: {
      // 判断是什么设备
      IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone",
                    "SymbianOS", "Windows Phone",
                    "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        this.isPCFlag = flag
        // console.log(this.isPCFlag)
      },
      pickupPglblur() {
        if(this.pickupForm.packageList ===''){
          this.pickupForm.packageList = placeholder;
        }
      },
      pickupPglfocus() {
        if(this.pickupForm.packageList === placeholder){
          this.pickupForm.packageList = ''
        }
      },
      deliveryPglblur() {
        if(this.deliveryForm.packageList ===''){
          this.deliveryForm.packageList = placeholder;
        }
      },
      deliveryPglfocus() {
        if(this.deliveryForm.packageList === placeholder){
          this.deliveryForm.packageList = ''
        }
      },
      initPageInfo() {
        let priceRule = JSON.parse(localStorage.getItem("priceRule"))
        this.checkWeight = priceRule.weightInfoArr
        this.checkSize = priceRule.sizeInfoArr
        const nowTime = moment().format('a');
        let timeArr = []
        if (nowTime == 'am') {
          timeArr = [moment().format('MM/DD')+' morning',moment().format('MM/DD')+' afternoon',
          moment().add(1, 'days').format('MM/DD')+' morning',moment().add(1, 'days').format('MM/DD')+' afternoon',
          moment().add(2, 'days').format('MM/DD')+' morning',moment().add(2, 'days').format('MM/DD')+' afternoon',]
        } else {
          timeArr = [moment().format('MM/DD')+' afternoon',
          moment().add(1, 'days').format('MM/DD')+' morning',moment().add(1, 'days').format('MM/DD')+' afternoon',
          moment().add(2, 'days').format('MM/DD')+' morning',moment().add(2, 'days').format('MM/DD')+' afternoon',]
        }
        var nowTimeArr = []
        timeArr.forEach((item,index) =>{
          nowTimeArr.push({
            label: item,
            value: index
          })
        })
        this.pickupTimeOptions[0] = nowTimeArr

        let _this = this;
        this.loader.load().then(() => {
          let senderAddressEle = document.getElementById('senderAddress');
          let receiverAddressEle = document.getElementById('receiverAddress');
          let autosenderAddress = new google.maps.places.Autocomplete(senderAddressEle);
          let autoreceiverAddress = new google.maps.places.Autocomplete(receiverAddressEle);
          autosenderAddress.addListener("place_changed",()=>{
            let senderAddress = autosenderAddress.getPlace();
            if(!senderAddress.geometry){
              this.$message.closeAll();
              this.$message({
                showClose: false,
                message: 'Please enter the specific address',
                type: 'warning'
              });
            }else{
              _this.pickupForm.address = senderAddressEle.value
              _this.pickupForm.latitude = senderAddress.geometry.location.lat(),
              _this.pickupForm.long = senderAddress.geometry.location.lng()
              _this.pickupForm.errorIndex = 0
            }
          })
          autoreceiverAddress.addListener("place_changed",()=>{
            let receiverAddress = autoreceiverAddress.getPlace();
            if(!receiverAddress.geometry){
              this.$message.closeAll();
              this.$message({
                showClose: false,
                message: 'Please enter the specific address',
                type: 'warning'
              });
            }else{
              _this.deliveryForm.address = receiverAddressEle.value;
              _this.deliveryForm.latitude = receiverAddress.geometry.location.lat()
              _this.deliveryForm.long = receiverAddress.geometry.location.lng()
              _this.deliveryForm.errorIndex = 0
            }
          })
        });
      },
      sumTypeNum() {
        let deliveryNum = 0, pickUpNum = 0
        if (this.multiList && this.multiList.length) {
          this.multiList.forEach(item => {
            if (item.type == 2) {
              deliveryNum+=1
            } else if (item.type == 1) {
              pickUpNum+=1
            }
          })
        }
        this.deliveryNum = deliveryNum
        this.pickUpNum = pickUpNum
      },
      addAStop(){
        this.initPageInfo()
        this.isEdit = 2
        if (this.multiList.length >= 1) {
          this.tabsName = 'Delivery'
          this.deliveryForm.type = 2
        } else {
          this.pickupForm.pick_time = this.pickupTimeOptions[0][0].label
          this.pickupForm.type = 1
          this.tabsName = 'Pickup'
        }
        this.addAStopDialog = true
      },
      goOptRoute(sourceType) {
        // sourceType 1 代表优化路径 2 代表提交后台
        if (this.deliveryNum < 1 || this.pickUpNum <=0) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please add at least 1 delivery and 1 pickup',
            type: 'warning'
          });
          return false
        } 
        var errorIndexArr = []
        this.multiList.forEach((item, index) => {
          if (!item.latitude) {
            // item.address = ''
            item.errorIndex = 1
            errorIndexArr.push(item)
          } else {
            item.errorIndex = 0
          }
        })
        if (errorIndexArr && errorIndexArr.length) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please select the address from the address list',
            type: 'warning'
          });
          return false
        }
        let allList = this.multiList
        let pickup_list = []
        let dropoff_list = []
        allList.forEach((item,index) => {
          var pacList = []
          if(item.packageList === placeholder){
            item.packageList = ''
          }
          if (item.packageList) {
            pacList = item.packageList.split('\n')
          }
          var pacListArr = []
          pacList.forEach(item => {
            var str = item.replace(/\s+/g,"");   
            var packItem = {}
            if (str) {
              packItem.status = 0
              packItem.name = item
              pacListArr.push(packItem)
            }
          })
          item.package_list = pacListArr
          item.image_info = []
          item.status = 1
          item.cost = 0
          item.group_name = 'default'
          item.group_id = 0
          var secTime = Math.floor(new Date().getTime() / 1000)
          item.expected_time = secTime
          if (!item.weight) {
            item.weight = 0
          }
          if (!item.size) {
            item.size = 0
          }
          if (item.type == 1) {
            pickup_list.push(item)
          } else {
            dropoff_list.push(item)
          }
        })
        if (sourceType == 1) {
          this.goSubmit(pickup_list, dropoff_list)
        } else {
          this.submitReq(pickup_list, dropoff_list)
        }
      },
      goSubmit(pickup_list, dropoff_list) {
        if(this.changeLock) {
          return;
        }
        this.changeLock = true;
        this.$http({
	        url: "/api/batch/order/create/v2",
	        method: "POST",
          data: {
            pickup_list: pickup_list,
            dropoff_list: dropoff_list,
            order_name: 'Import & group',
            group_count: 0,
          }
	      }).then(res => {
          this.changeLock = false;
	        this.$message.closeAll();
	        if(res.data.code){
	          this.$message({
	            message: res.data.message,
	            type: 'error'
	          });
	        }else{
            this.orderId = res.data.data.id
            this.multiList = []
            localStorage.setItem('multiList', JSON.stringify(this.multiList))
            localStorage.setItem('multiOrderId', JSON.stringify(this.orderId))
            this.sumTypeNum()
            this.$message({
	            message: 'School success',
	            type: 'success'
            });
            // 296b669e-e194-4299-ad50-2202a8efe74d
            this.$router.push({
              path: "/multiOptimizedRoute",
              query: {
                id: this.orderId
              }
            }).catch(err => {err})
	        }
	      }).catch(()=>{
          this.changeLock = false;
        })
      },
      submitReq(pickup_list, dropoff_list) {
        if(this.changeLock) {
          return;
        }
        this.changeLock = true;
        this.$http({
	        url: "/api/batch/order/create/v2",
	        method: "POST",
          data: {
            pickup_list: pickup_list,
            dropoff_list: dropoff_list,
            order_name: 'Import & group',
            group_count: 0,
            id: this.orderId
          }
	      }).then(res => {
          this.changeLock = false;
	        this.$message.closeAll();
	        if(res.data.code){
	          this.$message({
	            message: res.data.message,
	            type: 'error'
	          });
	        }else{
            this.orderId = res.data.data.id
            this.goPay(dropoff_list);
	        }
	      }).catch(()=>{
          this.changeLock = false;
        })
      },
      goPreOptimize() {
        var order =JSON.parse(this.preOrderId);
        this.$router.push({
          path: "/multiOptimizedRoute",
          query: {
            id: order
          }
        }).catch(err => {err})
      },
      goEdit(type ,index) {
        this.initPageInfo()
        this.isEdit = 1
        this.editIndex = index
        if (type == 1) {
          this.pickupForm = {...this.multiList[index]}
          this.tabsName = 'Pickup'
        } else {
          this.deliveryForm = {...this.multiList[index]}
          this.tabsName = 'Delivery'
        }
        this.addAStopDialog = true
      },
      // dialog
      tabChange() {
        if (this.tabsName == 'Pickup') {
          this.pickupForm = {...this.deliveryForm}
          this.pickupForm.pick_time = this.pickupTimeOptions[0][0].label
          this.pickupForm.type = 1
        } else {
          this.deliveryForm = {...this.pickupForm}
          this.deliveryForm.type = 2
        }
      },
      openPicker() {
        this.timePickerVisible = true
      },
      timeConfirm(objArr) {
        this.pickupForm.pick_time = objArr[0].label
        this.timePickerVisible = false
      },
      timeCancel() {
        this.timePickerVisible = false
      },
      dialogCancel(type) {
        if (type == 1) {
          Object.keys(this.pickupForm).forEach((key) => (this.pickupForm[key] = ''))
          this.pickupForm.packageList = placeholder
        } else {
          Object.keys(this.deliveryForm).forEach((key) => (this.deliveryForm[key] = ''))
          this.deliveryForm.packageList = placeholder
        }
        this.deliveryForm.weight = 0
        this.deliveryForm.size = 0
        this.addAStopDialog = false
      },
      dialogAdd(type) {
        let noPickUp = true
        if (this.multiList && this.multiList.length) {
          this.multiList.forEach(item => {
            if (item.type == 1) {
              noPickUp = false
            }
          })
        }
        if (type == 2 && noPickUp) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please input one pickup address first',
            type: 'warning'
          });
          return false
        }
        var form = {}
        if (type == 1) {
          if (!this.pickupForm.address) {
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Please enter the specific address',
              type: 'warning'
            });
            return false
          }
          if (!this.pickupForm.latitude) {
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Please select the address from the address list',
              type: 'warning'
            });
            return false
          }
          form = {...this.pickupForm}
          form.size = 0
          form.weight = 0
        } else {
          if (!this.deliveryForm.address) {
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Please enter the specific address',
              type: 'warning'
            });
            return false
          }
          if (!this.deliveryForm.latitude) {
            this.$message.closeAll();
            this.$message({
              showClose: false,
              message: 'Please select the address from the address list',
              type: 'warning'
            });
            return false
          }
          form = {...this.deliveryForm}
          form.pick_time = ''
        }
        if (this.isEdit == 1) {
          this.multiList[this.editIndex] = form
        } else {
          this.multiList.push(form)
        }
        Object.keys(this.pickupForm).forEach((key) => (this.pickupForm[key] = ''))
        Object.keys(this.deliveryForm).forEach((key) => (this.deliveryForm[key] = ''))
        this.deliveryForm.weight = 0
        this.deliveryForm.size = 0
        this.deliveryForm.packageList = placeholder
        this.pickupForm.packageList = placeholder
        localStorage.setItem('multiList', JSON.stringify(this.multiList))
        this.sumTypeNum()
        if (this.isEdit == 2) {
          this.$nextTick(() => {
            let tablePar = this.$refs["multiListContent"];
            tablePar.scrollIntoView(tablePar.scrollTop)
          });
        }
        this.addAStopDialog = false
      },
      goDelete(type) {
        // 清除数组中指定对象并更新缓存
        // if (type == 1 && this.pickUpNum == 1) {
        //   this.$message.closeAll();
        //   this.$message({
        //     showClose: false,
        //     message: 'Please input one pickup address first',
        //     type: 'warning'
        //   });
        //   return false
        // }
        this.deleteType = 'single'
        this.deleteDialog = true
      },
      goDeleteAll() {
        // 清除multi list和缓存 pickupNum deliveryNum
        this.deleteType = 'all'
        this.deleteDialog = true
      },
      deleteCancel() {
        this.deleteDialog = false
        this.hideActionSheet()
      },
      deleteConfirm() {
        if (this.deleteType == 'all') {
          this.multiList = []
        } else {
          this.multiList.splice(this.editIndex,1)
        }
        this.sumTypeNum()
        localStorage.setItem('multiList', JSON.stringify(this.multiList))
        this.$message.closeAll();
        this.$message({
          showClose: false,
          message: 'Successfully deleted',
          type: 'warning'
        });
        this.deleteCancel()
        Object.keys(this.pickupForm).forEach((key) => (this.pickupForm[key] = ''))
        Object.keys(this.deliveryForm).forEach((key) => (this.deliveryForm[key] = ''))
        this.deliveryForm.weight = 0
        this.deliveryForm.size = 0
        this.deliveryForm.packageList = placeholder
        this.pickupForm.packageList = placeholder
        this.addAStopDialog = false
      },
      showActionSheet() {
        this.actionSheet = true
      },
      hideActionSheet() {
        console.log(this.actionSheet)
        this.actionSheet = false
      },
      goPay(dropoff_list) {
        this.payData = new Array(dropoff_list.length).fill({
          groupId: 0,
          groupName: 'default',
        });
        if (!this.payMethods || !this.payMethods.length) {
          // 绑卡
          this.addCreditDialog = true;
        } else {
          // 设置小费 支付
          this.setTipDialog = true;
        }
      },
      // 支付成功
      paySuccess(msg) {
        // 296b669e-e194-4299-ad50-2202a8efe74d
        this.multiList = []
        localStorage.setItem('multiList', JSON.stringify(this.multiList))
        localStorage.setItem('multiOrderId', JSON.stringify(this.orderId))
        this.sumTypeNum()
        this.$message({
          message: 'School success',
          type: 'success'
        });
        this.$router.push({
          path: "/multiOptimizedRoute",
          query: {
            id: this.orderId
          }
        }).catch(err => {err})
      },

    }
  }
</script>
<style lang="scss">
  .addMultiDelivery {
    // position: absolute;
    width: 7.5rem;
    margin: auto;
    // height: 100%;
    // left: 0;
    // top: 0;
    .header {
      position: static;
    }
   .deliveryTitle{
      font-size: 0.45rem;
      font-family: Helvetica;
      color: #000000;
      line-height: 1.4rem;
    }
    .multi_content {
      // position: absolute;
      // top: 1.4rem;
      width: 100%;
      padding-bottom: 2.66rem;
      // padding-bottom: 3.2rem;
      overflow: auto;
    }
    .handle_btn{
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 0.3rem;
      background: #fff;
      height: 2.66rem;
      // height: 3.2rem;
      box-sizing: border-box;
      position: fixed;
      bottom: 0px;
      left: 0;
      right: 0;
      .previous {
        text-align: right;
        width: 6.7rem;
        color: #1c4064;
      }
      .more_handle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;
        width: 6.7rem;
        .actionCommon {
          // width: 6rem;
          margin: 0;
          margin-bottom: 0.3rem;
        }
        .flex_row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 6.7rem;
          .actionCommon {
            // width: 48%;
            width: 100%;
          }
        }
        .go_more {
          line-height: 0.2rem;
          font-size: 0.5rem;
          display: flex;
          flex-direction: column;
          width: 1rem;
          margin-bottom: 0.3rem;
          font-weight: bold;
        }
      }
    } 
    .handle_btn .actionCommon{
      margin: auto;
      margin-bottom: 0.3rem;
    }
    .handle_btn .unable {
      background: #ccc;
    }
    .delete-all {
      width: 6.7rem;
      text-align: right;
      margin-bottom: 0.2rem;
      font-size: 0.4rem;
      color: #E6A23C;
    }
    .fixed_bottom {
      position: fixed;
      bottom: 0px;
      left: 0;
      right: 0;
    }
    .multi_delivery_list {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.2rem;
      overflow: auto;
    }
    .fixed_height {
      // height: calc(100vh - 4.06rem);
      /* 100vh - header - handle_btn */
    }
    .multi_delivery_list .list_item {
      width: 100%;
      // height: 0.8rem;
      line-height: 0.8rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      font-size: 0.36rem;
      border-bottom: 1px dashed #e5e5e5;
      overflow: hidden;
      padding: 0.2rem 0;
      .address {
        font-size: 0.3rem;
        display: flex;
        flex-direction: column;
        .comment {
          padding-top: 6px;
        }
      }
    }
    .list_item .flex-style {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    .list_item .flex-style-btn {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
    .list_item .order_no {
      flex: 0.8;
      margin-right: 0.1rem;
      font-size: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .form_item .infoMust{
      color: #FE1C1C;
    }
    .order_no i {
      height: 0.38rem;
      line-height: 0.36rem;
      border: 1px solid #00C85E;
      width: 0.38rem;
      color: #00C85E;
      text-align: center;
      box-sizing: border-box;
      border-radius: 50%;
      font-style: normal;
    }
    .list_item .edit_btn {
      // flex: 1.8;
      width: 1rem;
      height: 0.5rem;
      line-height: 0.5rem;
      color: #00C85E;
      border: 1px solid #00C85E;
      // margin-top: 0.15rem;
      border-radius: 20px;
      font-size: 0.3rem;
    }
    .list_item .delete_btn {
      color: #E6A23C;
      border: 1px solid #E6A23C;
      margin-top: 0.1rem;
    }
    .list_item .address {
      flex: 8;
      overflow: hidden;
      line-height: 0.4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-content: center;
    }
    .list_item .address p {
      text-align: left;
      width: 100%;
      font-size: 0.3rem;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      word-break: break-all;
      // font-weight: 500;
    }
    .list_item .type {
      width: 1.2rem;
      height: 0.5rem;
      line-height: 0.5rem;
      // margin-top: 0.15rem;
      border-radius: 5px;
      font-size: 0.3rem;
      margin-right: 0.1rem;
      color: #fff;
    }
    .list_item .type_pickup {
      background: #E6A23C;
    }
    .list_item .type_delivery {
      background: #409EFF;
    }
    .error_bg {
      background: rgba(255,0,31,0.2);
    }
    /* dialog */
    .add_stop_dialog {
      max-width: 7.5rem;
      margin: auto;
      .el-dialog__header {
        padding-bottom: 20px;
        button:hover {
          filter: none;
          background: transparent;
        }
        .el-dialog__headerbtn {
          width: 30px;
          border-radius: 0;
          margin-top: 0;
          font-size: 26px;
          box-shadow: none;
          background: transparent !important;
        }
      }
      .el-dialog__headerbtn:hover {
        background: #fff;
      }
      .el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
        color: #999;
      }
      .el-dialog__title {
        font-size: 0.4rem;
      }
    }
    .add_stop_dialog .el-tabs__nav {
      clear: none;
      width: 100%;
    }
    .add_stop_dialog .el-tabs__item {
      width: 51%;
      text-align: center;
      font-size: 0.3rem;
      height: 0.8rem;
      line-height: 0.8rem;
    }
    .add_stop_dialog .el-dialog {
      border-radius: 0.1rem;
      overflow: hidden;
      margin-top: 10%!important;
    }
    .add_stop_dialog .el-dialog--center .el-dialog__body {
      padding: 0 !important;
    }
    .add_stop_dialog .el-tabs__content {
      overflow: auto;
      padding: 0.16rem;
    }
    .add_stop_dialog .form_info {
      width: 100%;
    }
    .add_stop_dialog .form_info_area {
      height: 2.4rem;
      padding-top: 0.1rem;
      width: 100%;
    }
    .add_stop_dialog .form_info_radio {
      width: 100%;
      height: 0.6rem;
      .form_item {
        height: 0.6rem;
        line-height: 0.6rem;
      }
    }
    .add_stop_dialog .dialog-tabs .el-tabs__content{
      height: 9rem;
    }
    .add_stop_dialog .dialog_btn {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0.2rem 0.5rem 0;
    }
    .dialog_btn .btn {
      width: 1.6rem;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      background: #ccc;
      border-radius: 5px;
      color: #fff;
      font-size: 0.3rem;
    }
    .dialog_btn .cancel {
      background: #E6A23C;
    }
    .dialog_btn .delete {
      background: #FE1C1C;
    }
    .dialog_btn .add {
      background: #67C23A;
    }
    .form_item {
      width: 100%;
      height: 1rem;
      line-height: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .form_item .pickup_time {
      height: 0.8rem;
      line-height: 0.8rem;
      margin-top: 0.1rem;
      width: 100%;
      color: #606266;
      font-size: 0.28rem;
      border-radius: 0.12rem;
      padding: 0 .2rem;
      box-sizing: border-box;
      background: #EAEAEA url('./../../assets/image/time_select.png') no-repeat right;
      background-size: 8%;
      background-position-x: 96%;
    }
    .form_item .form_label {
      flex: 1.3;
      margin-right: 0.1rem;
      font-size: 0.28rem;
    }
    .form_item .form_label_coumln {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      padding: 20px 0;
      box-sizing: border-box;
    }
    .form_item .form_label span {
      height: 0.4rem;
      line-height: 0.5rem;
    }
    .form_item .form_input {
      flex: 5;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .form_item input{ 
      color: #606266;
      font-size: 0.28rem; 
      outline: none !important;
    } 
    .form_item .text-area {
      border: none;
      padding: 5px 10px;
    }
    .form_item .text-area .el-textarea__inner {
      padding: 0!important;
    }
    .form_item .text-area .el-textarea__inner {
      height: auto !important;
      line-height: 0.5rem!important;
      font-size: 0.28rem;
    }
    .form_item .form_input .el-input-address{
      flex: 1;
      input {
        font-size: 0.24rem;
      }
    }
    .form_item .form_input .el-input-suite{
      flex: 1;
    }
    .form_item .form_input .el-input-phone{
      flex: 2;
      margin-right: 0.1rem;
    }
    .form_item .form_input .el-input-email{
      flex: 2.4;
    }
    .form_item .form_input .el-input-all {
      flex: 1
    }
    .form_item .form_input .el-input-area {
      flex: 1;
      height: auto;
    }
    .form_item .form_input .input_item input {
      height: 0.8rem;
      width: 100%;
    }

    .delete_dialog {
      .el-dialog {
        margin-top: 25% !important;

        .delete_title {
          padding: 10px 20px;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .el-icon-warning {
            color: #E6A23C;
            margin-right: 10px;
            font-size: 0.5rem;
          }
          span {
            line-height: 0.5rem;
            font-size: 0.3rem;
          }
        }
        .dialog_btn {
          margin-bottom: 20px;
        }
      }
    }
  }
  .pc_style {
    .add_stop_dialog .el-dialog__title {
      font-size: 20px;

    }
    .add_stop_dialog .el-tabs__item {
      font-size: 20px;
      height: 50px;
      line-height: 50px;
    }
    .add_stop_dialog .form_item {
      height: 50px;
      line-height: 50px;
       .form_label {
         font-size: 20px;
         span {
           height: 30px;
           line-height: 30px;
         }
      }
      .form_input .input_item input {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
      .pickup_time {
        height: 40px;
        line-height: 40px;
        font-size: 20px;
      }
    }
    .add_stop_dialog .form_info_area {
      font-size: 20px;
      height: 200px;
      .el-textarea {
        min-height: auto;
      }
      .form_item .text-area .el-textarea__inner {
        font-size: 20px;
        line-height: 40px !important;

      }
    }
    .add_stop_dialog .dialog_btn {
      padding: 0;
      .btn {
        font-size: 20px;
        height: 60px;
        line-height: 60px;
        width: 120px;
      }
    }
    .add_stop_dialog .form_info_radio {
      height: 40px;
      .form_item {
        height: 30px;
        line-height: 30px;
      }
    }
     .add_stop_dialog .dialog-tabs .el-tabs__content {
       height: 580px;
    }


  }
</style>