<template>
  <div class="drivers">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="settingTitle">Selected drivers</p>
      <div class="driverOperation" v-text="editing ? 'Done' : 'Edit'" @click="changeEditing"></div>
    </div>
    <div class="listWrap">
    	<div class="driverList">
        <div v-for="item in driverList" class="singleList">
          <p class="driverName" v-text="item.name">
          </p>
          <cusCheckbox :checkedObj="item" v-if="editing"></cusCheckbox>
          
        </div>
      </div>
      <div class="actionCommon" v-if="!editing" @click="jumpRoute('mainPage')">Continue</div>
      <div class="actionCommon danger" v-if="editing">Delete</div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'drivers',
    components: {cusCheckbox},
    data() {
      return {
        editing:false,
        driverList:[{
          name:"1111111111111111111111111111111111111111111",
          checked: false,
          id:1
        },{
          name:"222",
          checked: true,
          id:2
        },{
          name:"333",
          checked: false,
          id:3
        },{
          name:"111",
          checked: true,
          id:4
        }]
      }
    },
    mounted() {},
    methods: {
      goBack(){
        this.$router.go(-1);
      },
      changeEditing(){
        this.editing = !this.editing;
      },
      jumpRoute(path){
        this.$router.push({
          path: "/" + path,
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      }
    }
  }
</script>
<style scoped="scoped">
.drivers{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.driverOperation{
  position: absolute;
  width: 1.66rem;
  height: 0.88rem;
  top: 0;
  bottom: 0;
  right: 0.4rem;
  margin: auto;
  font-size: 0.36rem;
  font-family: Helvetica;
  line-height: 0.88rem;
  border-radius: 0.44rem;
  color: #FFFFFF;
  background: #00C85E;
}
.settingTitle{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
  padding-right: 1rem;
}
.listWrap{
  position: relative;
  top: 1.4rem;
  height: calc(100% - 1.4rem);
}
.listWrap>div{
  width: 6.7rem;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
}
.driverList{
  border-bottom: 0.02rem solid #F0F0F0;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 35%;
  max-height: 60%;
}
.driverName{
  text-align: left;
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 0.58rem;
  padding: 0.3rem 0;
  width: calc(100% - 1rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.singleList{
  position: relative;
}
.cusCheckbox{
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>