//actionSheet.vue   

<template>
<div>
<transition name="fade">
    <div class="action_mask" :show="show" v-show="show" @click="hideAction"></div>
</transition>
<transition name="bounce">
    <div class="action" :show="show" v-show="show">
        <!-- <div class="action_title">这是一个标题</div> -->
        <div class="action_content">
            <div class="item action_btn action_delete" v-if="showClearRoute" @click="clearRoute">Clear whole route</div>
            <div class="item action_btn action_delete" v-if="showReOptimize" @click="goReOptimite">Re-optimize route</div>
            <div class="item action_btn action_delete" v-if="showLoction" @click="setHomeLocation">Set end location (e.g. home)</div>
            <div class="item action_btn" v-if="showGroupInfo" @click="previewRoute">Preview route</div>
            <div class="item action_btn" v-if="showPreviewsRoute" @click="noPreviewRoute">No optimize route</div>
            <div class="item action_btn" v-if="showGroupInfo" @click="arrageCourier">Text</div>
            <div class="item action_btn action_delete" v-if="showGroupInfo" @click="deleteGroup">Delete</div>
            <div class="item action_btn" v-if="showHelpBtn">
              <a class="download" href="/template_excel.xlsx">Download the template</a>
            </div>
            <div class="item action_btn" v-if="showHelpBtn" @click="templateClick">Template instructions</div>
        </div>
        <div class="action_btn" @click="hideAction">Cancel</div>
    </div>
</transition>

</div>

</template>
<script>
    export default {
      props: {
        show: {
          default: function() {
            return false
          },
          type: Boolean
        },
        showClearRoute: {
          default: function() {
            return false
          },
          type: Boolean
        },
        showReOptimize: {
          default: function() {
            return false
          },
          type: Boolean
        },
        showLoction: {
          default: function() {
            return false
          },
          type: Boolean
        },
        showGroupInfo: {
          default: function() {
            return false
          },
          type: Boolean
        },
        showPreviewsRoute: {
          default: function() {
            return false
          },
          type: Boolean
        },
        groupItem: {
          default: function() {
            return {}
          },
          type: Object
        },
        showHelpBtn: {
          default: function() {
            return false
          },
          type: Boolean
        }
      },
        data() {
          return {
          }
        },
        methods: {
            hideAction(){
              this.$emit('hide')
            },
            clearRoute(){
              this.$emit('goDeleteAll')
            },
            goReOptimite() {
              this.$confirm('This operation will re optimize the path', 'Tips', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                customClass: 're-optimite',
                type: 'warning'
              }).then(() => {
                this.$emit('goReOptimite')
              }).catch(() => {
              })
            },
            setHomeLocation() {
              this.$emit('setHomeLocation')
            },
            deleteGroup() {
              this.$emit('deleteGroup',this.groupItem, this.groupItem.index)
            },
            arrageCourier() {
              this.$emit('arrageCourier',this.groupItem, this.groupItem.index)
            },
            previewRoute() {
              this.$emit('previewRoute',this.groupItem, this.groupItem.index)
            },
            noPreviewRoute() {
              this.$emit('noPreviewRoute')
            },
            templateClick() {
              this.$emit('showTemplateDialogFun')
            }
        }
    }
</script>
<style lang="scss">
.action{
    position: fixed;
    left: 0;
    bottom: 20px;
    right: 0;
    width: 7rem;
    margin: auto;
    // height: 170px;
    // background-color: #efefef;
    z-index: 1000;
    .action_title{
        padding: 0 20px;
        color: #888;
        border-bottom: 1px solid #efefef;
        height: 40px;
         background-color: #fff;
         line-height: 40px;
    }
    .action_content{
        color: #666;
        .item{
            padding: 0 20px;
            border-bottom: 1px solid #efefef;
            background-color: #fff;
        }
    }
    .action_btn{
      height: 50px;
      margin-top: 16px;
      background-color: #fff;
      line-height: 50px;
      border-radius: 10px;
      color: #1989fa;
      font-weight: 500;
      font-size: 0.4rem;
      cursor: pointer;
      a {
        color: #1989fa;
      }
    }
    .action_delete {
      color: #FE1C1C;
    }
}

.action_mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    z-index: 1000;
}
.bounce-enter-active {
  animation: bounce-in .4s;
}
.bounce-leave-active {
  animation: bounce-out .4s;
}
@keyframes bounce-in {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0px);
  }
}
@keyframes bounce-out {
   0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(300px);
  }
}

.fade-enter-active {
  animation: fade-in .5s;
}
.fade-leave-active {
  animation: fade-out .5s;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}
@keyframes fade-out {
   0% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}
.re-optimite {
  width: 90%;
  .el-message-box__title {
    width: 90%;
  }
  .el-message-box__headerbtn {
    width: 0.4rem;
    height: 0.4rem;
  }
  button {
    box-shadow: none;
    top: 0;
  }
  .el-message-box__btns {
    text-align: center;
    .el-button {
      width: 48%;
    }
  }
}
</style>