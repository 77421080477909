<template>
  <div class="myOrder">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="myOrderTitle">My requests</p>
    </div>
    <div class="moreOperation" ref="moreOperation">
    	<div class="menuContainer">
    		<div class="menuItem" :class="getActiveClass('all')" @click="changeSelectedMenu('all')">All reqs</div>
    		<div class="menuItem" :class="getActiveClass('unpaid')" @click="changeSelectedMenu('unpaid')">Unpaid reqs</div>
    		<div class="menuItem" :class="getActiveClass('unassign')" @click="changeSelectedMenu('unassign')">Not assigned reqs</div>
    		<div class="menuItem" :class="getActiveClass('error')" @click="changeSelectedMenu('error')">Problematic reqs</div>
    	</div>
    	<div class="listContainer" ref="listContainer" @touchstart="touchStart($event)" @touchend="touchEnd($event)">
    		<template v-for="(item,key) in orderList" @click="test">
    		  <orderItem :currentInfo="item" :extendObj="extendObj" :activeMenu="activeMenu" :changeable="changeable"></orderItem>
    		</template>
    		<div class="load-more" v-show="enableLoadMore" @click="loadMoreData">{{loadMoreText}}</div>  
      <div class="load-end" v-show="!enableLoadMore">All loaded</div>  
    	</div>
    </div>
  </div>
</template>

<script>
  import orderItem from "@/components/orderItem.vue"
  // @ is an alias to /src
  export default {
    name: 'myOrder',
    components: {orderItem},
    data() {
      return {
        changeable:{
          checked:true,
          changeable:true
        },
        activeMenu:"all",
        orderList:[],
        extendObj:{},
        loadMoreText: "",
        startX: 0,
        startY: 0,
        isLoading: false,
        enableLoadMore:true,
        pageIndex:1
      }
    },
    mounted() {
      this.loadMoreText = this.isPhone ? 'Pull up to load more': 'Click to load more'
      if(localStorage.getItem("loginInfo")){
        this.sender_id = JSON.parse(localStorage.getItem("loginInfo")).user_id;
      }
      this.getOrderList(-1,'all')
    },
    methods: {
      loadMoreData(){
        this.doLoadMore()
      },
      touchStart(e) {
        this.startY = e.targetTouches[0].pageY;
        this.startX = e.targetTouches[0].pageX;  
      },
      scrollToEnd(e) {
        let ele = this.$refs.listContainer
        let scrollHeight = ele.scrollHeight; 
        let clientHeight = ele.clientHeight; 
        let scrollTop = ele.scrollTop;  
        if (scrollTop + clientHeight >= scrollHeight && this.enableLoadMore) {
            this.doLoadMore()  
        } 
      },
      doLoadMore() {
        this.isLoading = true
        this.loadMoreText = 'loading...'
        this.onLoadMore(this.loadDone);
      },
      onLoadMore(cb){
        if (this.isLoading) {
          return;
        }
        this.pageIndex++;
        if(this.activeMenu == "unpaid"){
          this.getOrderList(0,"unpaid")
        }else if(this.activeMenu == "all"){
          this.getOrderList(-1,"all")
        }else if(this.activeMenu == "unassign"){
          this.getOrderList(1,"unassign")
        }else if(this.activeMenu == "error"){
          this.getOrderList(-1,'error')
        }
        setTimeout(()=>{
          cb()
        },1000);
      },
      loadDone() {
        this.isLoading = false;
        this.loadMoreText = this.isPhone ? 'Pull up to load more': 'Click to load more'
      },
      touchEnd(e) {
        if (this.isLoading) {
            return;
        }

        let endX = e.changedTouches[0].pageX,
            endY = e.changedTouches[0].pageY,
            dy = this.startY - endY,
            dx = endX - this.startX;
        if(Math.abs(dx) < 2 && Math.abs(dy) < 2) {
            return;
        }
        if (endY < this.startY) {
            this.scrollToEnd(e)
        }
      },
      getOrderList(status,type){
        this.$http({
          url: "/api/order/sender",
          method: "GET",
          params: {
            sender_id:this.sender_id,
            status:status,
            page_index:this.pageIndex
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code){
            this.enableLoadMore = false;
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }else{
            if(type != this.activeMenu){
              return
            }
            if(res.data.data.list.order_info.length){
              this.enableLoadMore = true;
            }else{
              this.enableLoadMore = false;
            }
            if(type == "error"){
              let statusArr = [7,9,11,12,13,14,15]
              let orderList = res.data.data.list.order_info.filter(function(item){
                return statusArr.indexOf(item.status) > -1;
              })
              this.orderList = this.orderList.concat(orderList)
            }else{
              let orderList = res.data.data.list.order_info
              this.orderList = this.orderList.concat(orderList)
            }
            this.dealResult(res.data.data.list);
          }
        })
      },
      dealResult(list){
        let extendTemp = {};
        let extendList = list.order_extend;
        for (var i = 0; i < extendList.length; i++) {
          let goodsArr = [];
          for (let j = 0; j < extendList[i].goods_list.length; j++) {
          	goodsArr.push(extendList[i].goods_list[j].goods_name)
          }
          extendList[i].goodsArr = goodsArr;
        	extendTemp[extendList[i].order_id] = extendList[i];
        }
        this.extendObj = Object.assign(extendTemp,this.extendObj)
      },
      goBack(){
        this.$router.push({
          path: "/" + "mainPage",
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      },
      getActiveClass(type){
        if(type == this.activeMenu){
          return "active";
        }else{
          return "";
        }
      },
      changeSelectedMenu(type){
        this.activeMenu = type;
        this.orderList = [];
        this.pageIndex = 1;
        this.enableLoadMore = true;
        if(type == "unpaid"){
          this.getOrderList(0,"unpaid")
        }else if(type == "all"){
          this.getOrderList(-1,"all")
        }else if(type == "unassign"){
          this.getOrderList(1,"unassign")
        }else if(type == "error"){
          this.getOrderList(-1,'error')
        }
      }
    }
  }
</script>
<style scoped="scoped">
.myOrder{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.myOrderTitle{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}
.moreOperation{
  width: 7.5rem;
  height: calc(100% - 1.4rem);
  margin: auto;
  position: relative;
  top: 1.4rem;
  box-sizing: border-box;
  padding: 0 0.16rem;
}
.menuContainer{
  display: flex;
  justify-content: center;
  font-size: 0.36rem;
  font-family: Helvetica;
  color: #4C4C4C;
  line-height: 0.44rem;
  padding: 0.4rem 0 0.8rem 0;
}
.menuItem.active{
  color: #00C85E;
}
.listContainer{
  position: relative;
  height: calc(100% - 2.1rem);
  overflow-y: auto;
}
.listItemWrap{
  
  background: #F8F8F8;
  border-radius: 0.2rem;
}
.basicInfo{
  
}
.load-more{
  cursor: pointer;
}
</style>