<!--
 * @Author: your name
 * @Date: 2021-01-04 08:50:00
 * @LastEditTime: 2021-04-01 17:00:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shiptal-frontend\src\components\cusCheckbox.vue
-->
<template>
  <div class="cusCheckbox" :class="(checkedObj.checked ? 'active' : ''||checkedObj.changeable ? 'done' : '')"
   @click="changeChecked(checkedObj)">

  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'cusCheckbox',
    components: {},
    props: {
      checkedObj: {
        default: function() {
          return {
            checked: false
          }
        },
        type: Object
      },
      hasCall: {
        default: function() {
          return false
        },
        type: Boolean
      }
    },
    data() {
      return {}
    },
    mounted() {},
    methods: {
      changeChecked(item) {
        if(!item.changeable) {
          item.checked = !item.checked;
        }
        if(!item.changeable && this.hasCall) {
          this.$emit("changeCallback", item)
        }
      }
    }
  }
</script>
<style scoped="scoped">
.cannotChange {
  
}
</style>