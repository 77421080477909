
<template>
  <div class="editTitle">
    <el-dialog
      title="Send text to cell phone"
      class="edit_dialog"
      :visible.sync="driverDialog"
      :width="width"
      center
      :close-on-click-modal="false"
      :before-close="editHandleClose">
      <div class="edit_content">
        <el-form ref="editForm" :model="addForm" label-width="70px">
          <el-form-item label="Phone#">
            <el-input v-model="addForm.phone" placeholder=""></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <div class="bottom_btn">
            <el-button type="warning" @click="editHandleClose">Cancel</el-button>
            <el-button type="primary" @click="confirmEdit">Confirm</el-button>
          </div>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from "moment"
  export default {
    name: 'addDriver',
    components: {
    },
    props: {
      driverDialog: {
        default: function() {
          return false
        },
        type: Boolean
      },
      groupId: {
        default: function() {
          return 0
        },
        type: Number
      },
      orderId: {
        default: function() {
          return ''
        },
        type: String
      },
      width: {
        default: function() {
          return '400px'
        },
        type: String
      }
    },
    data() {
      return {
        addForm: {
          phone: ''
        }
      }
    },
    mounted() {

    },
    methods: {
      editHandleClose() {
        this.$refs['editForm'].resetFields()
        this.$emit('driverClose')
      },
      confirmEdit() {
        if (!this.addForm.phone) {
          this.$message({
            message: "Please enter the driver's phone number",
            type: 'warning'
          });
          return false
        }
        this.$http({
          url: "/api/batch/order/update/v2",
          method: "POST",
          data: {
            id: this.orderId,
            group_id: this.groupId,
            phone: this.addForm.phone,
            // driver_id:
          },
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            this.sendMsg(this.addForm.phone)
          }
        }).catch(err => {
        })
      },
      sendMsg(phone) {
        this.$http({
          url: "/api/message/mobile",
          method: "POST",
          data: {
            from: 4085398388+'',
            to: phone,
            content: `You have a batch of new orders, please deliver them in time. Open the address  to check the details “http://shiptal.com/multiOptimizedRoute?id=${this.orderId}&groupId=${this.groupId}” --Shiptal`
          },
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          } else {
            this.$message({
              message: 'Text is sent successfully.',
              type: 'success'
            });
            this.$emit('confirmDriver')
          }
        }).catch(err => {
        })
      }
    }
  }
</script>
<style lang="scss">
  .el-picker-panel__body {
    button {
      width: auto;
      box-shadow: none !important;
    }
  }
  .edit_dialog {
    button {
      border: 0;
      padding: 0;
      margin-top: 0;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.2s ease;
      display: block;
      box-shadow: none;
      width: auto;
    }
    .edit_content {
      font-size: 14px;
      .el-input {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border: none;
      }
      .el-input>.el-input__inner, .el-select>.el-input__inner {
        height: 40px;
        font-size: 14px;
      }
      .bottom_btn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      .el-button {
        width: 100px;
        width: 100px;
        line-height: 40px;
        height: 40px;
        margin: auto;
      }

    }
  }
</style>