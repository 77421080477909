
<template>
  <div class="editTitle">
    <el-dialog
      title="Edit title"
      class="edit_dialog"
      :visible.sync="editTitleDialog"
      width="400px"
      center
      :close-on-click-modal="false"
      :before-close="editHandleClose">
      <div class="edit_content">
        <el-form ref="editForm" :model="addForm" label-width="40px">
          <el-form-item label="Title">
            <el-input v-model="addForm.title" placeholder=""></el-input>
          </el-form-item>
          <!-- <el-form-item label="Date">
            <el-date-picker
              v-model="addForm.date"
              value-format="MM/dd"
              type="date"
              :clearable="false"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item> -->
        </el-form>
        <span slot="footer">
          <div class="bottom_btn">
            <el-button type="warning" @click="editHandleClose">Cancel</el-button>
            <el-button type="primary" @click="confirmEdit">Confirm</el-button>
          </div>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // @ is an alias to /src
  import moment from "moment"
  export default {
    name: 'EditTitle',
    components: {
    },
    props: {
      editTitleDialog: {
        default: function() {
          return false
        },
        type: Boolean
      }
    },
    data() {
      return {
        addForm: {
          title: '',
          date: moment().format('MM/DD')
        }
      }
    },
    mounted() {

    },
    methods: {
      editHandleClose() {
        this.$refs['editForm'].resetFields()
        this.$emit('editClose')
      },
      confirmEdit() {
        var info = {...this.addForm}
        this.$emit('confirmEdit',info)
      }
    }
  }
</script>
<style lang="scss">
  .el-picker-panel__body {
    button {
      width: auto;
      box-shadow: none !important;
    }
  }
  .edit_dialog {
    .edit_content {
      font-size: 14px;
      .el-input {
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border: none;
      }
      .el-input>.el-input__inner, .el-select>.el-input__inner {
        height: 40px;
        font-size: 14px;
      }
      .bottom_btn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      }
      .el-button {
        width: 100px;
        width: 100px;
        line-height: 40px;
        height: 40px;
        margin: auto;
      }
    }
  }
</style>