<template>
  <div class="listItemWrap" @click="orderAction">
    <div class="basicInfo" v-if="activeMenu !== 'all'" style="overflow: hidden;white-space:nowrap;word-break:hyphenate">
      <span class="deliverStatus" v-text="orderStatus[currentInfo.status]"></span>
      <span class="deliverGoods">
        {{extendObj[currentInfo.order_id].goodsArr ? extendObj[currentInfo.order_id].goodsArr.join(',') :'未知'}}
      </span>
    </div>
    <div class="basicInfo">
      <span>
        <span v-text="sizeObj[currentInfo.good_max_volume]"></span>
      <cusCheckbox :checkedObj="changeable"></cusCheckbox>
      </span>
      <span>
        <span v-text="weightObj[currentInfo.good_max_weight]"></span>
      <cusCheckbox :checkedObj="changeable"></cusCheckbox>
      </span>
    </div>
    <div class="deliverInfo">
      <div class="deliverInfoWrap">
        <div class="deliverTitle">
          From
        </div>
        <div class="deliverDetail" v-text="currentInfo.sender_address">
        </div>
      </div>
      <div class="deliverInfoWrap">
        <div class="deliverTitle">
          To
        </div>
        <div class="deliverDetail" v-text="currentInfo.receiver_address">
        </div>
      </div>
      <p class="deleverTime" v-text="formatdate(currentInfo.created_at)"></p>
      <p class="payStatus" v-text="orderStatus[currentInfo.status]" :class="getStatusClass(currentInfo.status)"></p>
      <div class="orderAction" v-if="activeMenu == 'unpaid'" v-text="getActionText()">
      </div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'orderItem',
    components: {
      cusCheckbox
    },
    props: {
      activeMenu: {
        default: function() {
          return 'all'
        },
        type: String
      },
      changeable: {
        default: function() {
          return {
            checked: false,
            changeable: false
          }
        },
        type: Object
      },
      extendObj: {
        default: function() {
          return {}
        },
        type: Object
      },
      currentInfo: {
        default: function() {
          return {
            goods_list: "flower",
            basicInfo: {
              size: {
                title: "unknown",
                checked: false,
                changeable: false
              },
              weight: {
                title: "unknown",
                checked: false,
                changeable: false
              },
            },
            from: "unknown",
            to: "unknown",
            time: "unknown",
            payStatus: "unknown",
            deliverStatus: "Pick up and delivery"
          }
        },
        type: Object
      }
    },
    data() {
      return {
        weightObj: {},
        sizeObj: {},
      }
    },
    mounted() {
      this.ruleFormat();
    },
    methods: {
      ruleFormat() {
        if(localStorage.getItem("priceRule")) {
          let priceRule = JSON.parse(localStorage.getItem("priceRule"))
          this.weightObj = {
            0: priceRule.weightInfoArr[0].title,
            1: priceRule.weightInfoArr[1].title,
            2: priceRule.weightInfoArr[2].title
          }
          this.sizeObj = {
            0: priceRule.sizeInfoArr[0].title,
            1: priceRule.sizeInfoArr[1].title
          }
        }
      },
      orderAction() {
        this.$router.push({
          path: "/orderDetail",
          query: {
            orderid: this.currentInfo.order_id
          }
        }).catch(err => {
          err
        })
      },
      getActionText() {
        if(this.activeMenu == 'unpaid') {
          return "Pay";
        } else if(this.activeMenu == 'unassign') {
          return "Tip up";
        } else {
          return "";
        }
      },
      getStatusClass(status) {
        switch(status) {
          case 0:
            return "danger";
            break;
          case 9:
            return "danger";
            break;
          case "10":
            return "ignore";
            break;
          default:
            return "normal";
            break;
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .listItemWrap {
    position: relative;
    box-sizing: border-box;
    padding: 0 0.2rem;
    margin-bottom: 0.4rem
  }
  
  .basicInfo {
    height: 1.04rem;
    border-bottom: 0.02rem solid #FFFFFF;
  }
  
  .deleverTime,
  .payStatus {
    font-size: 0.32rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: right;
  }
  
  .payStatus {
    padding: 0.16rem 0 0.32rem 0;
  }
  
  .payStatus.danger {
    color: #FF3232;
  }
  
  .payStatus.ignore {
    color: #D3D3D3;
  }
  
  .payStatus.normal {
    color: #4C4C4C;
  }
  
  .deliverTitle {
    font-size: 0.42rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.5rem;
    min-width: 1rem;
  }
  
  .deliverDetail {
    font-size: 0.36rem;
    min-width: calc(100% - 1rem);
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #005026;
    line-height: 0.56rem;
    margin-bottom: 0.32rem;
    padding-left: 0.22rem;
  }
  
  .deliverInfoWrap {
    display: flex;
    justify-content: left;
    text-align: left;
  }
  
  .deliverInfo {
    margin-top: 0.16rem;
  }
  
  .basicInfo {
    font-size: 0;
    text-align: left;
  }
  
  .basicInfo>span {
    display: inline-block;
    font-size: 0.48rem;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.04rem;
    padding-right: 0.6rem;
    position: relative;
    margin-right: 0.1rem;
  }
  
  .basicInfo>.deliverStatus {
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #94A8FF;
    line-height: 0.48rem;
    background: #EDF5FF;
    border-radius: 0.06rem;
    border: 0.02rem solid #DEEDFF;
    padding: 0;
    vertical-align: bottom;
    margin-top: 0.25rem;
  }
  
  .basicInfo>.deliverGoods {
    font-size: 0.48rem;
    /*font-family: Helvetica;*/
    color: #4C4C4C;
    line-height: 0.58rem;
    padding: 0;
    vertical-align: bottom;
  }
  
  .basicInfo>span>.cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .orderAction {
    width: 2.7rem;
    height: 0.88rem;
    background: #00C85E;
    font-size: 0.36rem;
    /*font-family: Helvetica;*/
    color: #FFFFFF;
    line-height: 0.88rem;
    border-radius: 0.44rem;
    position: absolute;
    left: 0.24rem;
    bottom: 0.32rem;
  }
</style>