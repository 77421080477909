<template>
  <div class="resetPass">
    <div class="header">
      <p class="resetPassTitle">Reset Password</p>
    </div>
    <div class="resetPassWrap">
      <div class="logo">
        <img src="../assets/image/logo.png" />
      </div>
      <div class="formWrap">
        <p class="valueLine">
        	<span>
	      	  <span>User</span>
	        <cusCheckbox :checkedObj="userObj"></cusCheckbox>
	        </span>
	        <span>
	      	  <span>Courier</span>
	        <cusCheckbox :checkedObj="courierObj"></cusCheckbox>
	        </span>
        </p>
        <p class="formTitle">Email</p>
        <p>
          <el-input v-model="Email" placeholder="Email"></el-input>
        </p>
        <p class="formTitle">Code</p>
        <div class="inputAndBtn">
          <div class="inputWrap">
            <el-input v-model="sendCode" placeholder="verification code"></el-input>
          </div>
          <div class="sendCode" :class="codeTimeout ? '' : 'ignore'" @click="sendCodeFn" v-text="codeTimeout ? 'send verification code':'resend later'"></div>
        </div>
        <p class="formTitle">New Password</p>
        <p>
          <el-input v-model="newPassword" placeholder="New Password" show-password></el-input>
        </p>
      </div>
      <div class="actionCommon" @click="resetPassFn">Reset</div>
    </div>
  </div>
</template>

<script>
  import md5 from "js-md5"
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'resetPass',
    components: {
      cusCheckbox
    },
    data() {
      return {
        Email: "",
        newPassword: "",
        sendCode: "",
        codeTimeout: true,
        userObj:{
	        checked: false
	      },
        courierObj:{
	        checked: false
	      }
      }
    },
    mounted() {},
    methods: {
      resetPassFn() {
        this.$message.closeAll();
        if(!(this.newPassword && this.sendCode && this.Email)) {
          this.$message({
            message: "Please complete reset info",
            type: 'error'
          });
          return
        }
        if(!this.userObj.checked && !this.courierObj.checked) {
          this.$message({
            message: "Please check your account type",
            type: 'error'
          });
          return
        }
        let userType;
        if(this.userObj.checked){
        	userType = 2;
        }else{
        	userType = 1;
        }
        this.$http({
          url: "/api/driver/up",
          method: "POST",
          data: {
            password: md5(this.newPassword),
            code: this.sendCode,
            email: this.Email,
            role:userType
          },
        }).then(res => {
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.$message({
              message: "Reset password successed",
              type: 'success'
            });
            this.$router.go(-1)
          }
        })
      },
      sendCodeFn() {
        if(this.codeTimeout && this.Email) {
          this.codeTimeout = false;
          setTimeout(() => {
            this.codeTimeout = true;
          }, 60000)
          this.$http({
            url: "/api/user/verify",
            method: "get",
            params: {
              email: this.Email,
              type: 1
            }
          }).then(res => {
            if(res.data.code) {
              this.codeTimeout = true;
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            } else {
              this.$message({
                message: "Code has sended to your Email.",
                type: 'success'
              });
            }
          })
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .resetPass {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .resetPassTitle {
    font-size: 0.48rem;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #4C4C4C;
    line-height: 1.4rem;
    padding-left: 0.4rem;
    text-align: left;
  }
  
  .resetPassWrap {
    font-size: 0;
    position: relative;
    top: 1.4rem;
    height: calc(100% - 1.4rem);
  }
  
  .logo>img {
    margin: 1.2rem 0;
    width: 2.8rem;
    height: 2.02rem;
  }
  
  .formTitle {
    font-size: 0.42rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 0.58rem;
    text-align: left;
  }
  
  .formWrap>* {
    padding: 0 0.4rem 0.12rem 0.4rem;
    width: 6.7rem;
    margin: auto;
  }
  
  .formWrap .el-input {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  
  .policyWrap {
    position: relative;
    height: 0.74rem;
  }
  
  .cusCheckbox {
    top: 0.1rem;
  }
  
  .readPolicy {
    float: right;
    text-align: left;
    font-size: 0.27rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4c4c4c;
    line-height: 0.34rem;
    width: calc(100% - 0.7rem);
    margin: initial;
  }
  
  .policyLink {
    color: #6690FF;
  }
  
  .resetPassWrap>.actionCommon {
    margin: auto;
    margin-top: 0.8rem;
  }
  
  .resetPassActionWrap {
    font-size: 0.28rem;
    font-family: Helvetica;
    color: #5483FF;
    text-decoration: underline;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .jumpToOther {
    position: absolute;
    right: 0.4rem;
    top: 0.4rem;
    text-decoration: underline;
  }
  
  .inputAndBtn {
    overflow: hidden;
  }
  
  .inputAndBtn .inputWrap {
    width: 55%;
    float: left;
    margin-bottom: 0.2rem;
  }
  
  .sendCode.ignore {
    background: #D3D3D3;
  }
  
  .sendCode {
    float: right;
    width: 44%;
    height: 0.88rem;
    border-radius: 0.2rem;
    background: #00C85E;
    font-size: 0.3rem;
    font-family: Helvetica;
    color: #FFFFFF;
    line-height: 0.88rem;
  }
  
  .valueLine{
    width: 7.1rem;
    height: 1.28rem;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.28rem;
    text-align: left;
    display: flex;
    justify-content: space-around;
  }
  
  .valueLine>span {
    position: relative;
    padding-right: 0.6rem;
  }
  
  .valueLine .cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
</style>