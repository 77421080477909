<template>
  <div class="wallet">
    <div class="header">
      <div class="center_info">
        My Wallet
      </div>
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
    </div>
    <div class="walletContainer">
      <div class="walletInfoWrap">
        <div class="left_info">
          <div class="wallet_icon">
            <img src="../assets/image/wallet.png" />
          </div>
          <p class="wallet_title">Balance</p>
          <p class="wallet_number">{{balance / 100}}</p>
        </div>
        <div class="right_info">
          <div class="bank_in" @click="confirmWindow = true">
            Transfer to bank
          </div>
          <div class="bank_out" v-show="false">
            deposit from bank
          </div>
        </div>
      </div>
      <div class="actionArea">
        <div class="request actionCommon" @click="routeHistoryList">Transaction history</div>
        <div class="import actionCommon" v-show="false">Delivery rates</div>
      </div>
    </div>
    <div class="confirmContainer" v-show="confirmWindow">
      <div class="itemLists">
        <div class="singleFormItem">
          <p class="formItemTitle">Withdraw number</p>
          <div class="inputWrap">
            <el-input type="number" v-model="outNumber" placeholder="Withdraw number"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">Routing number:</p>
          <div class="inputWrap">
            <el-input type="text" v-model="routeNumber" placeholder="Routing number"></el-input>
          </div>
        </div>
        <div class="singleFormItem">
          <p class="formItemTitle">Accounting number:</p>
          <div class="inputWrap">
            <el-input type="text" v-model="AccNumber" placeholder="Accounting number"></el-input>
          </div>
        </div>
      </div>
      <div class="payBtnWrap">
        <div class="actionCommon confirm error" @click="confirmWindow = false">Cancel</div>
        <div class="actionCommon confirm" @click="checkDataAndSubmit">Submit</div>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'wallet',
    components: {},
    data() {
      return {
        id: "",
        balance: 0,
        confirmWindow: false,
        outNumber:"",
        routeNumber:"",
        AccNumber:"",
        submitLock:false
      }
    },
    mounted() {
      let userType = localStorage.getItem("userType")
      if(userType == "driver") {
        this.id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).id : "";
        if(!this.id) {
          this.jumpRoute("loginDriver")
          return;
        }
      } else if(userType == "user") {
        this.id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).user_id : "";
        if(!this.id) {
          this.jumpRoute("loginUser")
          return;
        }
      }
      this.getDriverInfo();
    },
    methods: {
      getDriverInfo() {
        this.$http({
          url: "/api/driver/get",
          method: "GET",
          params: {
            driver_id: this.id
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.balance = res.data.data.total_salary;
          }
        })
      },
      goBack() {
        this.$router.go(-1);
      },
      jumpRoute(path, type) {
        this.$router.push({
          path: "/" + path,
          name: path,
          query: {
            info: type
          }
        }).catch(err => {
          err
        })
      },
      routeHistoryList() {
        if(!this.confirmWindow) {
          this.jumpRoute("salaryWater")
        }
      },
      checkDataAndSubmit(){
        if(this.submitLock){
          return;
        }
        if(this.outNumber && this.routeNumber && this.AccNumber){
          this.submitLock = true;
          this.$http({
            url: "/api/driver/ws",
            method: "POST",
            data: {
              user_id: this.id,
              amount:Number(this.outNumber),
              bank_code:this.routeNumber,
              card:this.AccNumber,
              role : 1
            }
          }).then(res => {
            this.submitLock = false;
            this.$message.closeAll();
            if(res.data.code) {
              this.$message({
                message: res.data.message,
                type: 'error'
              });
            } else {
              this.confirmWindow = false;
              this.$message({
                message: "The Withdraw request has been submitted, please wait patiently",
                type: 'success'
              });
            }
          })
        
        }else{
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please fill withdraw info',
            type: 'warning'
          });
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .wallet {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .walletContainer {
    position: absolute;
    top: 1.4rem;
    width: 100%;
  }
  
  .walletInfoWrap {
    width: 7.18rem;
    height: 4.56rem;
    background: #00C85E;
    border-radius: 0.2rem;
    margin: auto;
    position: relative;
  }
  
  .actionArea>div {
    margin: auto;
    margin-top: 0.72rem;
  }
  
  .left_info {
    width: 2.65rem;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .wallet_icon {
    width: 1rem;
    height: 0.88rem;
    /*background: #fff;*/
    margin: 1.12rem 0 0.32rem 0.8rem;
  }
  
  .wallet_icon>img {
    width: 100%;
    height: 100%;
  }
  
  .wallet_title {
    font-size: 0.48rem;
    color: #FFF;
    line-height: 0.66rem;
  }
  
  .wallet_number {
    font-size: 0.36rem;
    color: rgba(255, 255, 255, 0.68);
  }
  
  .right_info {
    width: 4.5rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .right_info>div {
    width: 3.7rem;
    height: 0.76rem;
    background: #FFF;
    border-radius: 0.38rem;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #009B49;
    line-height: 0.76rem;
    margin: auto;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
  }
  
  .bank_in {
    /*top: 1.12rem;*/
    top: 1.8rem;
  }
  
  .bank_out {
    bottom: 1.12rem;
  }
  
  .confirmContainer {
    position: fixed;
    z-index: 11;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    width: 7.5rem;
    height: 50%;
    max-height: 10.86rem;
    min-height: 6.5rem;
    padding: 0.2rem 0.1rem 0 0.1rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0rem -0.2rem 0.3rem 0rem rgba(45, 37, 37, 0.12);
    border-radius: 0.36rem 0.36rem 0rem 0rem;
    border: 0.02rem solid #E3E3E3;
  }
  
  .confirmTitle {
    text-align: left;
    font-size: 0.42rem;
    font-family: Helvetica;
    line-height: 0.56rem;
    padding: 0 0.4rem;
  }
  
  .itemLists {
    margin-top: 0.2rem;
    height: calc(100% - 1.5rem);
    background: #F7F7F7;
    overflow-y: auto;
  }
  
  .payBtnWrap {
    margin: 0.2rem 0;
    display: flex;
    justify-content: space-around;
  }
  
  .actionCommon.confirm {
    width: 40%;
  }
  
  .confirm.error {
    background: rgba(222, 21, 38, 1);
  }
  .singleFormItem {
    font-size: 0;
  }
  
  .formItemTitle {
    padding: 0.2rem;
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 0.5rem;
    text-align: left;
  }
  .inputWrap{
    margin-bottom: 0.2rem;
  }
</style>