<template>
  <div class="infoShow">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="infoShowTitle">{{infoTitle}}</p>
    </div>
    <div class="contentWrap">
      <iframe :src="infoUrl" width="100%" height="100%" frameborder="no" border="0" marginwidth="0" marginheight="0"></iframe>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'infoShow',
    components: {},
    data() {
      return {
        infoTitle:"",
        userType:"",
        infoContent:"",
        infoUrl:""
      }
    },
    mounted() {
      this.userType = this.$route.query.type;
      let info = this.$route.query.info;
      if(info == "protocol"){
        if(this.userType == "user"){
          this.infoTitle = "User protocol"
          this.infoUrl = "/protocol_user.pdf"
        }else if(this.userType == "driver"){
          this.infoTitle = "Driver protocol"
          this.infoUrl = "/protocol_driver.pdf"
        }
      }else{
        this.infoTitle = "Privacy Policy"
        if(this.userType == "user"){
          this.infoUrl = "/privacy_user.pdf"
        }else if(this.userType == "driver"){
          this.infoUrl = "/privacy_driver.pdf"
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
    }
  }
</script>
<style>
  .infoShow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .infoShowTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  .contentWrap {
    width: 7.5rem;
    height: calc(100% - 1.4rem);
    position: absolute;
    top: 1.4rem;
    left: 0;
    right: 0;
    margin: auto;
  }
  .infoContent{
    padding: 0.4rem;
    text-align: left;
    text-indent: 0.5rem;
  }
</style>