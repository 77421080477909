<template>
  <div>
    <div class="container">
      <el-button type="primary">{{ upload_file || "Upload excel file" }}</el-button>
      <input
        ref="uploadInput"
        type="file"
        accept=".xls,.xlsx,.csv"
        class="upload_file"
        @change="readExcel($event)"
      />
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import XLSX from "xlsx"
  export default {
    name: 'uploadExcel',
    components: {
    },
    props: {
      pickupTimeOptions: {
        default: function() {
          return []
        },
        type: Array
      }
    },
    data() {
      return {
        upload_file: ''
      }
    },
    mounted() {
    },
    methods: {
      readExcel(e) {
        // 读取表格文件
        let that = this;
        const files = e.target.files;
        if (files.length <= 0) {
          e.target.value=null
          return false;
        } else if (!/.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
          this.$message({
            message: "Upload format is incorrect, please upload XLS or xlsx format",
            type: "warning"
          });
          e.target.value=null
          return false;
        } else {
          // 更新获取文件名
          that.upload_file = 'Upload again';
        }
        const fileReader = new FileReader();
        fileReader.onload = ev => {
          try {
            const data = ev.target.result;
            const workbook = XLSX.read(data, {
              type: "binary"
            });
            const wsname = workbook.SheetNames[0]; //取第一张表
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); //生成json表格内容
            // 从解析出来的数据中提取相应的数据
            var expectArr = ['ADDRESS','ROOM','PHONE','EMAIL','COMMENT','THIRDID',
            'PACKAGE','LIST']
            var lists = []
            var noAddress = true
            ws.forEach((row,rowIndex)=>{
              var rowObj = {}
              rowObj.comment = ''
              rowObj.packageList = ''
              var keyArr = Object.keys(row)
              keyArr.forEach((rowKey,rowKeyIndex)=>{
                // 去空格
                rowKey = rowKey+''
                if (rowKey && rowKey.trim()) {
                  // 将key转大写
                  var excelStr = rowKey.toUpperCase()
                  // 变量expectArr 和key进行比较 使用包含关系 
                  for (var i = 0;i<expectArr.length; i++) {
                      var needStr = expectArr[i]
                      var reg = new RegExp(needStr)
                      if (reg.test(excelStr)){
                          // 相同的话返回expectArr的下标
                          // 通过expectarr下标判断是哪一项
                          // 通过index确定哪一项对应哪一列
                          if (i == 0) {
                              rowObj.address = row[rowKey]
                              noAddress = false
                          } else if (i == 1) {
                              rowObj.room = row[rowKey]
                          } else if (i == 2) {
                              rowObj.phone = row[rowKey]
                          } else if (i == 3) {
                              rowObj.email = row[rowKey]
                          } else if (i == 4) {
                              // rowObj.comment = row[rowKey]
                              rowObj.comment += '\n'+row[rowKey]
                          } else if (i == 5) {
                              // rowObj.packageList = row[rowKey]
                            rowObj.third_id = row[rowKey]
                          } else if (i >= 6) {
                            const oldPackage = row[rowKey]
                            const reg = /[,，；;/|]/g
                            let newPackage = ''
                            if (reg.test(oldPackage)) {
                              newPackage = oldPackage.replace(reg, '\n')
                            } else {
                              newPackage = '\n'+ oldPackage
                            }
                            rowObj.packageList += newPackage
                              // rowObj.packageList = row[rowKey]
                          }
                          break
                      }
                  }
                }
              })
              lists.push(rowObj)
            })
            if (lists && lists.length) {
              if (noAddress) {
                e.target.value=null
                this.$message({
                  message: "Address is necessary. Please add address information in Excel. You can click the template instructions to view it",
                  type: "warning"
                });
                return false
              }
              this.handleList(lists)
              e.target.value=null
            } else {
              this.$message({
                message: "Upload file is empty",
                type: "warning"
              });
              e.target.value=null
              return false
            }
            that.$refs.uploadInput.value = ''
          } catch (e) {
            e.target.value=null
            return false;
          }
        };
        fileReader.readAsBinaryString(files[0]);
        this.$emit('closeAgainDialog')
      },
      handleList(lists) {
        // 删除空白行
        var delBlankList = []
        lists.forEach((item,index) => {
          if (item.address){
            item.address = item.address+''
            item.address = item.address.trim()
          }
          if (item.packageList) {
            item.packageList = item.packageList+''
            item.packageList = item.packageList.trim()
          }
          if (item.packageList || item.address) {
            delBlankList.push(item)
          }
        })
        // 合并packagelist
        var newLists = []
        delBlankList.forEach((item,index) => {
          if (item.address) {
            item.packages_list = []
            if (item.packageList) {
              item.packages_list = item.packageList.split('\n')
            }
            newLists.push(item)
          }
          if (item.packageList && item.packageList.trim() && !item.address) {
            // console.log(item.packageList)
            var aArr = item.packageList.split('\n')
            var bArr = newLists[newLists.length - 1].packages_list
            var cArr = aArr.concat(bArr)
            newLists[newLists.length - 1].packages_list = cArr
            newLists[newLists.length - 1].packageList = cArr.join('\n')
          }
        })
        newLists.forEach((item,index) => {
          item.size = 0
          item.weight = 0
          item.pickupTime = this.pickupTimeOptions[0].label
          item.startIndex = index
          item.newIndex = index
          item.warnIndex = 0
          item.showRow = 0
          item.comment = item.comment?item.comment:''
          item.phone = item.phone?item.phone:''
          item.room = item.room?item.room:''
          item.email = item.email?item.email:''
          item.third_id = item.third_id?item.third_id:''
        })
        this.$emit('handleList',newLists)
      },
    }
  }
</script>
<style scoped="scoped">
</style>