<template>
  <div>
    <el-dialog
      :title="tipOnly ? 'Set tips' : 'Set tips & pay'"
      class="edit_dialog add_a_stop_dialog "
      :visible.sync="dialogShow"
      :width="tipOnly?'350px':'92%'"
      center
      :close-on-click-modal="false"
      :before-close="handleClose">
      <div class="add_content">
        <el-table
          :data="groupData"
          empty-text="No data"
          :height="250"
          size="min"
          border>
          <el-table-column
            align="center"
            :width="tipOnly?'':'120'"
            prop="groupName"
            label="group name">
          </el-table-column>
          <el-table-column prop="drops" column-key="drops" v-if="!tipOnly"
           label="drops"  width="80" align="center">
          </el-table-column>
          <el-table-column
            column-key="base"
            v-if="!tipOnly"
            align="center"
            width="100"
            prop="base"
            label="base$">
          </el-table-column>
          <el-table-column label="tips for group" width="140" align="center">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.tip" @change="(val) => handleChangeTip(val, scope.row)" :min="1" :max="100"></el-input-number>
            </template>
          </el-table-column>
           <el-table-column column-key="averageCost" v-if="!tipOnly"
            align="center"
            prop="averageCost"
            label="average cost">
          </el-table-column>
        </el-table>
        <div :class="['sum-wrapper', isPhone ? 'phone' : '']">
          <div class="select-payment-method" v-if="!tipOnly">
            <div class="label">Payment card:</div>
            <el-select v-model="curPayMethod" size="mini" :popper-append-to-body="false" placeholder="Select card" no-data-text="No data" value-key="id">
              <el-option
                v-for="item in payMethods"
                :key="item.id"
                :label="item.card.last4"
                :value="item">
                <span class="brand">{{item.card.brand}}</span>
                <span class="num">****{{item.card.last4}}</span>
              </el-option>
            </el-select>
          </div>
          <div class="overall" v-if="!tipOnly">
            <p><b>Overall:</b> {{groupData.length}} groups, {{dropsTotal}} drops, ${{(costTotal/100).toFixed(2)}} total</p>
            <p><b>Overall average cost:</b> ${{(avecostTotal/100).toFixed(2)}}</p>
          </div>
          <div class="overall" v-else>
            <p><b>Summary:</b> ${{(tipTotal/100).toFixed(2)}}</p>
          </div>
        </div>
      </div>
      <div class="bottom_btn">
        <el-button type="warning" @click="handleClose">Cancel</el-button>
        <el-button v-if="!tipOnly" type="primary" @click="createPayParams" :disabled="payBtnDisabled">
          <div v-if="submitLock" class="spinner"></div>
          <div v-else>Pay</div>
        </el-button>
        <el-button v-else type="primary" @click="createPayParams" :disabled="payBtnDisabled">
          <div v-if="submitLock" class="spinner"></div>
          <div v-else>Confirm</div>
        </el-button>
      </div>
      <div class="creditInfo" v-if="showCreditInfo">
        <div class="listContainer">
          <div class="sr-root">
            <div class="sr-main">
              <form id="payment-form" class="sr-payment-form">
                <div class="sr-combo-inputs-row">
                  <div class="sr-input sr-card-element" id="card-element"></div>
                </div>
                <div class="sr-field-error" id="card-errors" role="alert"></div>
              </form>
            </div>
          </div>
        </div>
        <div class="payBtnWrap">
          <div class="pay-btn cancel-btn" @click="showCreditInfo = false">Cancel</div>
          <div class="pay-btn pay" @click="pay">
            <div v-if="payLoading" class="spinner"></div>
            <div v-else>Pay</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {stripePay} from '../mixin/stripePay.js'

  const groupBy = require('lodash/groupBy');
  export default {
    name: 'creditCardAdd',
    mixins: [stripePay],
    data() {
      return {
        groupData: [],
        submitLock: false,
        payLoading: false,
        showCreditInfo: false,
        curPayMethod: null,
        basePrice: 0,
      }
    },
    props: {
      dialogShow: {
        default: function() {
          return false
        },
        type: Boolean
      },
      tableData: {
        type: Array,
        default: () => {
          return [];
        }
      },
      tipOnly: {
        type: Boolean,
        default: false,
      },
      order_id: {
        type: [String, Number],
        default: '',
      }
    },
    watch: {
      async dialogShow(val) {
        if (!val) {
          this.groupData = [];
          return;
        }
        this.getAllPaymentMothodsByCustomer();

        // 未分组的地址，groupId groupName替换成default
        // var list = JSON.parse(JSON.stringify(this.tableData));
        // list = list.slice(1,list.length)
        const tableDataCopy = JSON.parse(JSON.stringify(this.tableData))
        tableDataCopy.forEach((l,index) => {
          if (l.groupId === 'ungrouped') {
            l.groupId = 'default';
            l.groupName = 'default';
          }
        })
        const map = groupBy(tableDataCopy, 'groupId');
        this.groupData = Object.keys(map).map(v => {
          const drops = map[v].length || 0;
          const base = map[v].length * this.basePrice || 0;
          // 以美分计算 以美元展示
          const averageCost = ((base / 100 + map[v].length * 1) / drops).toFixed(2) - 0;
          return {
            groupId: map[v][0].groupId,
            groupName: map[v][0].groupName,
            drops,
            base: `${drops} * ${(this.basePrice / 100).toFixed(2)}`,
            tip: drops * 1,
            averageCost, 
          }
        })
      },
    },
    computed: {
      dropsTotal() {
        return this.groupData.reduce((pre, cur) => {
          return pre + cur.drops;
        }, 0);
      },
      costTotal() {
        return (this.dropsTotal * this.basePrice + this.groupData.reduce((pre, cur) => {
          return pre + cur.tip * 100;
        }, 0));
      },
      avecostTotal() {
        if (!this.costTotal || !this.dropsTotal) return 0;
        return (this.costTotal / this.dropsTotal).toFixed(2);
      },
      tipTotal() {
        return this.groupData.reduce((pre, cur) => {
          return pre + cur.tip * 100;
        }, 0);
      },
      orderId() {
        // inputSubmit 下单时路由获取id
        // myMultiOrder props传入
        return this.$route.query.id || this.order_id;
      },
      payBtnDisabled() {
        return !this.groupData.length || !this.curPayMethod;
      },
    },
    created() {
      this.getBasePrice();
    },
    methods: {
      // 获取基础价格
      getBasePrice() {
        this.$http({url: "/api/user/get/price", method: "GET", params: {}}).then(res => {
          if (res.data.code) {
            this.$message({message: res.data.message, type: 'error'});
          } else {
            this.basePrice = res.data.data.base ;
          }
        })
      },
      async getAllPaymentMothodsByCustomer() {
        this.payMethods = await this.getPayMethods();
        if (this.payMethods && this.payMethods.length) {
          this.curPayMethod = this.payMethods[0];
        } else {
          this.curPayMethod = null;
        }
      },
      handleClose() {
        this.$emit('update:dialogShow', false);
        this.showCreditInfo = false;
      },
      handleChangeTip(val, row) {
        const averageCost = ((row.drops * (this.basePrice/100) + val) / row.drops).toFixed(2) - 0;
        this.$set(row, 'averageCost', averageCost);
      },
      async createPayParams() {
        const customer = await this.getCustomer();
        var type = this.tipOnly?2:1
        var groupOiList = []
        this.groupData.forEach(item=>{
          groupOiList.push({
            group_id: item.groupId,
            tip: item.tip * 100,
            item: item.drops * this.basePrice,
            driver_id: ''
          })
        })
        const params = {
          order_id: this.orderId,
          type: type,
          customer_id: customer,
          group_list: groupOiList,
        }
        this.createPay(params);
      },
    }
  }
</script>
<style scoped="scoped" lang="scss">
/deep/.add_a_stop_dialog {

  >.el-dialog {
    max-width: 700px;
  }

  .el-input-number {
    width: 100%;
  }
  .el-dialog__footer {
    padding: 20px;
  }

  .el-input {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    border: none;
  }

  .el-input > .el-input__inner, .el-select > .el-input__inner {
    height: 40px;
    font-size: 14px;
  }

  .bottom_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0 20px;

    .el-button {
      display: inline-block;
      width: 100px;
      height: 40px;
      padding: 0;
      margin: 0;
      line-height: 40px;
    }

    .el-button:nth-child(2) {
      margin-left: 30px;
    }

    .spinner:before, .spinner:after {
      background: teal!important;
    }

    button:hover, button:focus {
      .spinner:before, .spinner:after {
        background: #339999!important;
      }
    }

  }

  .sum-wrapper {
    padding: 5px 20px;
    text-align: left;
    border: 1px solid #EBEEF5;
    border-top: none;
    display: flex;
    background: #f6f6f6;

    .overall {
      flex: 1;
      text-align: right;
      
      p {
        line-height: 25px;
      }

    }
   
    .select-payment-method {
      flex: 1;
      display: flex;
      align-items: center;
      
      .label {
        margin-right: 15px;
        font-weight: 600;
        white-space: nowrap;
      }

      .brand {
        display: inline-block;
        width: 80px;
        margin-right: 15px;
      }
    }

    &.phone {
      display: block;
      
      .overall {
        margin-top: 15px;
        text-align: left;
      }

    }

  }
}

.creditInfo {
  position: fixed;
  z-index: 11;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 200px;
  border-radius: 4px;
  padding: 45px;
  background: #FFFFFF;
  box-shadow: 0 0  10px 0 rgba(45, 37, 37, 0.22);

  .sr-main {
    height: 43px;
  }

  .payBtnWrap {
    margin: 16px auto 0;
    width: 400px;
    display: flex;
    justify-content: space-between;
  }

  .spinner {
    margin: 13px auto;
  }

  .pay-btn {
    width: 190px;
    height: 46px;
    line-height: 46px;
    background: #0a721b;
    border-radius: 4px;
    color: white;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    text-align: center;
    font-size: 16px;

    &:focus {
      box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(50, 151, 211, 0.3);
      outline: none;
      z-index: 9;
    }

    &:active {
      transform: translateY(0px) scale(0.98);
      filter: brightness(0.9);
    }
  }

  .cancel-btn {
    background: #fff;
    border: 1px solid #0a721b;
    color: #0a721b;
  }

}


</style>