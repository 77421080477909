<!--
 * @Author: your name
 * @Date: 2021-04-13 17:45:40
 * @LastEditTime: 2021-04-26 13:55:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \shiptal-frontend\src\views\deliverer\personalPage.vue
-->
<template>
  <div class="personal-page">
    <div class="header">
      <div class="center_info">
        Personal
      </div>
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
    </div>
    <div class="content">
      <div class="user_avtor">
        <div class="user_pic"><img src="./../../assets/image/logo.png" alt=""></div>
        <div class="user_name">{{userName}}</div>
      </div>
      <el-card class="box-card person_sta">
        <div class="box_top">
          <div class="box_item">
            <span>{{personInfo.order_count}}</span>
            <span>Total orders</span>
          </div>
          <div class="box_item">
            <span>$ {{personInfo.total_salary | moneyFilter}}</span>
            <span>Total salary</span>
          </div>
          <!-- <div class="box_item">
            <span>$ {{personInfo.withdrawal_salary | moneyFilter}}</span>
            <span>Withdrawable</span>
          </div> -->
        </div>
        <div class="box_bottom">
          <div class="request actionCommon dis_withdraw"
          >Withdraw deposit</div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'personalPage',
    components: {},
    data() {
      return {
        userName: '',
        id: '',
        personInfo: {
          order_count: 0,
          total_salary: 0,
          withdrawal_salary: 0
        }
      }
    },
    created() {
      this.id = localStorage.getItem("loginInfo") ? JSON.parse(localStorage.getItem("loginInfo")).id : "";
      this.userName = localStorage.getItem("loginName") ? localStorage.getItem("loginName") : "";
      if(!this.id) {
        this.jumpRoute("loginDriver")
        return;
      }
      this.getPersonTotal()
    },
    mounted() {
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      getPersonTotal() {
        this.$http({
          url: "/api/driver/self",
          method: "GET",
          params: {
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.personInfo = res.data.data
            this.personInfo.total_salary = res.data.data.cost+res.data.data.tip
          }
        })
      }
    },
    filters: {
      moneyFilter(val) {
        if (val) {
          return val/100
        } else {
          return val
        }
      }
    }
  }
</script>
<style lang="scss">
  .personal-page {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #f4f5f7;
    .header {
      color: #fff;
//       background: #30E8BF;  /* fallback for old browsers */
// background: -webkit-linear-gradient(to right, #FF8235, #30E8BF);  /* Chrome 10-25, Safari 5.1-6 */
// background: linear-gradient(to right, #FF8235, #30E8BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background: #6190E8;  /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #A7BFE8, #6190E8);  /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #A7BFE8, #6190E8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
    .content {
      position: absolute;
      top: 1.38rem;
      width: 100%;
      box-sizing: border-box;
      .user_avtor {
        position: absolute;
        width: 100%;
        height: 3.5rem;
        padding-top: 0.1rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        background: #6190E8;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #A7BFE8, #6190E8);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #A7BFE8, #6190E8); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        .user_pic {
          width: 1.2rem;
          height: 1.2rem;
          background: #fff;
          border-radius: 50%;
          margin-left: 0.2rem;
          img {
            width: 0.7rem;
            margin: auto;
            margin-top: 0.35rem;
          }
        }
        .user_name {
          margin-left: 0.4rem;
          color: #fff;
          margin-top: 0.4rem;
        }
      }
      .person_sta {
        height: 3.5rem;
        border-radius: 15px;
        margin: 0.2rem;
        position: absolute;
        width: calc(100% - 0.4rem);
        top: 1.4rem;
        padding: 0.2rem;
        box-sizing: border-box;
        .el-card__body {
          padding: 0;
        }
        .dis_withdraw {
          background: #ccc;
        }
        .box_top {
          width: 100%;
          display: flex;
          flex-direction: row;
          .box_item {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 2rem;
            justify-content: space-around;
            padding: 0.3rem 0;
            box-sizing: border-box;
            span {
              text-align: center;
              flex: 1;
              &:nth-child(1) {
                font-size: 0.4rem;
                font-weight: 600;
              }
              &:nth-child(2) {
                color: #888;
                font-size: 0.28rem;
              }
            }
          }
        }
      }
    }
  }
</style>