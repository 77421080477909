<template>
  <div class="orderDetail">
    <div class="header">
      <div class="user_icon" @click="goBack" v-if="!preview"><i class="el-icon-arrow-left"></i></div>
      <p class="summaryTitle" v-text="orderStatus[orderInfo.status]"></p>
    </div>
    <div class="moreOperation">
      <div class="orderPanel">
        <div class="orderInfoContainer">
          <p>
            <span>status:</span>
            <span v-if="orderInfo.status == 0 && !preview" class="notPaid" @click="dealData">Pay</span>
            <span v-text="orderStatus[orderInfo.status]" class="specspan" :class="getStatusClass(orderInfo.status)"></span>
          </p>
          <p v-if="!preview">
            <span>Delivery ID:</span>
            <span v-text="orderInfo.order_id" class="specspan orderid"></span>
          </p>
          <p>
            <span>Submitted time:</span>
            <span v-text="orderInfo.order_time ? formatdate(orderInfo.order_time*1000) : ''" class="specspan"></span>
          </p>
        </div>
        <div class="distanceInfo">
          <span>Distance:</span>
          <span v-text="orderInfo.distance ? orderInfo.distance.toFixed(3) + 'miles' : ''"></span>
        </div>
        <div class="feeArea">
          <p>
            <span>Basic delivery fee</span>
            <span v-text="orderInfo.cost ? '$' + orderInfo.cost/100 : '$0'" class="specspan"></span>
          </p>
          <p>
            <span>Tip</span>
            <span v-text="orderInfo.tip ? '$' + orderInfo.tip/100 : '$0'" class="specspan"></span>
          </p>
          <p>
            <span>Tax</span>
            <span v-text="orderInfo.tax ? '$' + orderInfo.tax/100 : '$0'" class="specspan"></span>
          </p>
          <p class="finalPay">
            <span>Total cost</span>
            <span v-text="orderInfo.tax ? '$' + (orderInfo.cost + orderInfo.tip + orderInfo.tax)/100 : '$0'" class="specspan"></span>
          </p>
        </div>
        <div class="actionCommon" v-if="!preview && (orderInfo.status == 1 || orderInfo.status == 11)" @click="addMoreTip">Add more tip</div>
      </div>
      <div class="DriverInfoContainer" v-if="driver_info && !preview">
        <div class="infoTitle">Driver:</div>
        <p>
          <span>Name</span>
          <span v-text="getDriverName()" class="specspan"></span>
        </p>
        <p>
          <span>Number</span>
          <span v-text="orderInfo.driver_id" class="specspan driverid"></span>
        </p>
        <!--<p>
          <span>Score</span>
          <span v-text="orderInfo.driver_id" class="specspan"></span>
        </p>-->
        <div class="actionWrap">
          <a :href="'tel:' + driver_info.phone">Call</a>
          <a :href="'sms:' + driver_info.phone">Text</a>
        </div>
      </div>
      <div class="fromtoInfoContainer">
        <div class="fromtoInfo">
          <div class="infoTitle">From</div>
          <p v-text="orderInfo.sender_address"></p>
          <p v-text="orderInfo.sender_name + ' ' + orderInfo.sender_phone" v-if="!preview"></p>
          <p v-if="!preview">
            Email
            <span v-text="orderInfo.sender_email" class="innerInfo"></span>
          </p>
          <p>
            Comment
            <span v-text="orderInfo.remarks_sender" class="innerInfo"></span>
          </p>
          <p>
            Pickup date
            <span v-text="getPickTime(orderInfo.pick_time)" class="innerInfo"></span>
          </p>
        </div>
        <div class="fromtoInfo">
          <div class="infoTitle">To</div>
          <p v-text="orderInfo.receiver_address"></p>
          <p v-text="orderInfo.receiver_name + ' ' + orderInfo.receiver_phone" v-if="!preview"></p>
          <p v-if="!preview">
            Email
            <span v-text="orderInfo.receiver_email" class="innerInfo"></span>
          </p>
          <p>
            Comment
            <span v-text="orderInfo.remarks_receiver" class="innerInfo"></span>
          </p>
          <p>
            Pickup date
            <span class="innerInfo">Same Day</span>
          </p>
        </div>
      </div>
      <div class="goodsInfoContainer">
        <div class="basicInfo">
          <span>
            <span v-text="sizeObj[orderInfo.good_max_volume]"></span>
          <cusCheckbox :checkedObj="changeable"></cusCheckbox>
          </span>
          <span>
            <span v-text="weightObj[orderInfo.good_max_weight]"></span>
          <cusCheckbox :checkedObj="changeable"></cusCheckbox>
          </span>
        </div>
        <div class="valueLine" v-if="orderInfo.good_max_value">
          <span>
            <span>Value > $300 ?</span>
          <cusCheckbox :checkedObj="valueObj"></cusCheckbox>
          </span>
        </div>
        <div class="ItemsInfo" v-if="extendObj[orderInfo.order_id]">
          Item list (total: {{extendObj[orderInfo.order_id].goodsArr ? extendObj[orderInfo.order_id].goodsArr.length : 0}} packages)
          <template v-for="(item,key) in extendObj[orderInfo.order_id].goodsArr">
            <p v-text="item"></p>
          </template>
        </div>
      </div>
      <div class="updateInfo" v-if="updateList.length">
        <p class="title">Update:</p>
        <p v-for="(item,key) in updateList" class="singleUpdateInfo">
          {{formatdate(item.happen_time*1000)}}
          <span v-html="getcontent(item)" class="contentspan"></span>
        </p>
      </div>
      <div class="commentInfo">
        <span>Comment:</span>
        <span v-text="orderInfo.recv_comment || '(no comment)'" class="danger"></span>
        <!--<div class="singleFormItem">
          <p class="formItemTitle">Comment</p>
          <div class="inputWrap">
            <el-input type="textarea" :rows="4" resize="none" :autosize="true" v-model="addComment"></el-input>
          </div>
        </div>
        <div class="actionCommon">Continue</div>-->
      </div>
    </div>
    <div class="creditInfo" v-if="showCreditInfo">
      <div class="tipContainer" v-show="addTip">
        <p class="tipTitle">Tips($):</p>
        <el-input-number v-model="extraTip" :step="0.1" :min="0.5"></el-input-number>
        <div class="actionCommon tip" @click="paymoreTip">Confirm</div>
      </div>
      <div class="listContainer" v-show="!addTip">
        <div class="sr-root">
          <div class="sr-main">
            <form id="payment-form" class="sr-payment-form">
              <div class="sr-combo-inputs-row">
                <div class="sr-input sr-card-element" id="card-element"></div>
              </div>
              <div class="sr-field-error" id="card-errors" role="alert"></div>
            </form>
          </div>
        </div>

      </div>
      <div class="payBtnWrap" v-show="!addTip">
        <div class="actionCommon pay later" @click="jumpRoute('moreOrder')">Later</div>
        <div class="actionCommon pay" @click="payOrder">Pay</div>
      </div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'orderDetail',
    components: {
      cusCheckbox
    },
    data() {
      return {
        extraTip: 0,
        addTip: false,
        addTipbtn: false,
        pageTitleObj: {},
        pageTitle: "",
        valueObj: {
          checked: true,
          changeable: true
        },
        changeable: {
          checked: true,
          changeable: true
        },
        addComment: "",
        updateArr: [],
        feeInfo: {},
        finalPay: 21.751,
        order_id: "",
        orderInfo: {},
        driver_info: undefined,
        extendObj: {},
        sizeObj: {},
        weightObj: {},
        showCreditInfo: false,
        updateList: [],
        submitLock: false,
        preview:false
      }
    },
    mounted() {
      this.getPriceRule()
      this.order_id = this.$route.query.orderid;
      if(!this.order_id) {
        this.jumpRoute("myOrder")
        return;
      }
      if(this.$route.query.type != undefined){
        this.preview = true;
        this.previewData = {
          type:this.$route.query.type,
          order:this.$route.query.order
        }
      }
      this.ruleFormat();
      this.getUpdateList();
      this.getOrderInfo()
      this.extendObj[this.$route.params.info] = {}
      //this.orderInfo.status = "toPick"
    },
    methods: {
      getDriverName() {
        if(this.driver_info) {
          if(this.driver_info.middle_name) {
            return this.driver_info.first_name + " " + this.driver_info.middle_name + " " + this.driver_info.last_name;
          } else {
            return this.driver_info.first_name + " " + this.driver_info.last_name;
          }
        }
      },
      getcontent(item) {
        if(item.content.indexOf('https://') > -1) {
          return `<img src='${item.content}' style='height:2rem' />`
        } else {
          return item.content;
        }
      },
      getUpdateList() {
        let paramsData = {
          order_id: this.order_id,
        };
        if(this.preview){
          paramsData.type = this.previewData.type;
          paramsData.order = this.previewData.order;
        }
        this.$http({
          url: "/api/order/dynamic",
          method: "GET",
          params: paramsData
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.updateList = res.data.data.dy;
          }
        })

      },
      getPickTime(time) {
        let picktime = this.formatdate(time * 1000);
        if(picktime.indexOf("21:0") > -1) {
          return picktime.split(" ")[0] + " PM"
        } else {
          return picktime.split(" ")[0] + " AM"
        }
      },
      updateOrder() {
        if(this.submitLock) {
          return;
        }
        this.submitLock = true;
        this.$http({
          url: "/api/order/update",
          method: "POST",
          data: {
            order_id: this.order_id,
            tip: this.orderInfo.tip + this.extraTip * 100
          }
        }).then(res => {
          this.submitLock = false;
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: "add tip error",
              type: 'error'
            });
          } else {
            this.jumpRoute("moreOrder")
          }
        })

      },
      addMoreTip() {
        this.showCreditInfo = true;
        this.addTip = true;
      },
      paymoreTip() {
        this.addTip = false;
        let orderData = {
          item: 0,
          tip: this.extraTip * 100, //this.orderInfo.tip,
          order_id: this.order_id
        };
        this.payThisOrder(orderData)
      },
      ruleFormat() {
        if(localStorage.getItem("priceRule")) {
          let priceRule = JSON.parse(localStorage.getItem("priceRule"))
          this.weightObj = {
            0: priceRule.weightInfoArr[0].title,
            1: priceRule.weightInfoArr[1].title,
            2: priceRule.weightInfoArr[2].title
          }
          this.sizeObj = {
            0: priceRule.sizeInfoArr[0].title,
            1: priceRule.sizeInfoArr[1].title
          }
        }
      },
      payOrder() {
        if(this.submitLock) {
          return;
        }
        this.submitLock = true;
        let _this = this;
        let creditInfo = this.creditInfo
        creditInfo.stripe.confirmCardPayment(creditInfo.client_secret, {
          payment_method: {
            card: creditInfo.card
          }
        }).then(function(result) {
          _this.submitLock = false;
          _this.$message.closeAll();
          if(result.error) {
            // Show error to your customer
            _this.$message({
              message: result.error,
              type: 'error'
            });
          } else {
            if(result.paymentIntent.status == "succeeded") {
              _this.$message({
                message: "Payment successful!",
                type: 'success'
              })
              if(_this.orderInfo.status == 0) {
                _this.jumpRoute("moreOrder")
              } else {
                _this.updateOrder()
              }
            }
            // The payment has been processed!
            //creditInfo.stripe.retrievePaymentIntent(creditInfo.client_secret).then(function(result){
            //  console.log(result.paymentIntent)
            //})
          }
        });
      },
      jumpRoute(path) {
        this.$router.push({
          path: "/" + path,
          name,
          params: {
            info: "null"
          }
        }).catch(err => {
          err
        })
      },
      dealData() {

        this.showCreditInfo = true;
        this.addTip = false;
        let orderData = {
          // TODO
          item: this.orderInfo.cost + this.orderInfo.tax,
          tip: this.orderInfo.tip,
          order_id: this.order_id
        };
        this.payThisOrder(orderData)
      },
      payThisOrder(orderData) {
        if(this.submitLock) {
          return;
        }
        this.submitLock = true;
        this.$http({
          url: "/api/pay/create",
          method: "POST",
          data: orderData
        }).then(res => {
          this.$message.closeAll();
          this.submitLock = false;
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {

            let payData = (res.data.data);
            let stripe = Stripe(payData.publishable_key);
            let client_secret = payData.client_secret;
            let elements = stripe.elements();
            let style = {
              base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4"
                }
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
              }
            };
            let card = elements.create("card", {
              style: style
            });
            card.mount("#card-element");
            this.creditInfo = {
              stripe,
              card,
              client_secret
            }
          }
        })
      },
      getOrderInfo() {
        let paramsData = {
          order_id: this.order_id,
        };
        if(this.preview){
          paramsData.type = this.previewData.type;
          paramsData.order = this.previewData.order;
        }
        this.$http({
          url: "/api/order/detail",
          method: "GET",
          params: paramsData
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code) {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          } else {
            this.dealResult(res.data.data);
          }
        })
      },
      dealResult(list) {
        let extendTemp = {};
        let extendList = list.order_extend;
        let goodsArr = [];
        for(let j = 0; j < extendList.goods_list.length; j++) {
          goodsArr.push(extendList.goods_list[j].goods_name)
        }
        extendList.goodsArr = goodsArr;
        extendTemp[extendList.order_id] = extendList;

        this.extendObj = extendTemp;
        this.orderInfo = list.order_info;
        this.driver_info = list.driver_info;
      },
      goBack() {
        this.$router.go(-1);
      },
      getStatusClass(status) {
        if(status > 6 || status == 0) {
          return "red"
        } else {
          return "green"
        }
      }
    }
  }
</script>
<style scoped="scoped">
  .orderDetail {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .summaryTitle {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #000000;
    line-height: 1.4rem;
  }
  
  .moreOperation {
    width: 7.5rem;
    height: calc(100% - 1.4rem);
    margin: auto;
    position: relative;
    top: 1.4rem;
    box-sizing: border-box;
    padding: 0.4rem 0.2rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .fromtoInfoContainer {
    background: #F8F8F8;
    border-radius: 0.2rem;
    padding: 0 0.2rem;
  }
  
  .fromtoInfo {
    font-size: 0.42rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D3D3D3;
    line-height: 0.56rem;
    text-align: left;
    margin-bottom: 0.8rem;
  }
  
  .infoTitle {
    width: 1.12rem;
    height: 0.58rem;
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.58rem;
    margin-bottom: 0.24rem;
  }
  
  .fromtoInfo>p {
    margin-bottom: 0.24rem;
  }
  
  .innerInfo {
    display: block;
  }
  
  .goodsInfoContainer {
    padding: 0.1rem 0.2rem;
    background: #F8F8F8;
    border-radius: 0.2rem;
  }
  
  .basicInfo {
    font-size: 0;
    text-align: left;
  }
  
  .basicInfo>span {
    display: inline-block;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 1.04rem;
    padding-right: 0.6rem;
    position: relative;
    margin-right: 0.1rem;
  }
  
  .basicInfo>span>.cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .valueLine {
    width: 7.1rem;
    height: 0.66rem;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 0.66rem;
    text-align: left;
  }
  
  .valueLine>span {
    position: relative;
    padding-right: 0.6rem;
  }
  
  .valueLine .cusCheckbox {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.06rem;
  }
  
  .distanceInfo,
  .ItemsInfo,
  .commentInfo,
  .driverInfo {
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #4C4C4C;
    line-height: 0.56rem;
    text-align: left;
  }
  
  .distanceInfo {
    margin: 0.2rem 0;
  }
  
  .distanceInfo>span,
  .commentInfo>span {
    margin-right: 0.6rem;
  }
  
  .ItemsInfo,
  .commentInfo,
  .driverInfo {
    margin: 0.3rem 0;
  }
  
  .commentInfo {
    background: #F8F8F8;
    border-radius: 0.2rem;
    padding: 0.2rem;
    padding-top: 0.4rem;
  }
  
  .danger {
    color: #FF3232;
  }
  
  .orderPanel {
    background: #F8F8F8;
    border-radius: 0.2rem;
    padding: 0.2rem;
    margin-bottom: 0.4rem;
  }
  
  .orderInfoContainer {
    font-size: 0.42rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 1rem;
    text-align: left;
  }
  
  .feeArea {
    background: #EDEDED;
    font-size: 0.42rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 1.1rem;
    text-align: left;
    padding: 0 0.2rem;
  }
  
  .finalPay {
    font-size: 0.42rem;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #4C4C4C;
    line-height: 0.56rem;
    padding: 0.4rem 0 0.4rem 0;
  }
  
  .DriverInfoContainer {
    background: #F8F8F8;
    border-radius: 0.2rem;
    padding: 0.4rem 0.2rem;
    font-size: 0.42rem;
    font-family: Helvetica;
    color: #4C4C4C;
    line-height: 0.88rem;
    text-align: left;
    margin-bottom: 0.4rem;
  }
  
  .specspan {
    float: right;
  }
  
  .notPaid {
    float: right;
    width: 2.7rem;
    height: 0.88rem;
    background: #00C85E;
    border-radius: 0.44rem;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #FFFFFF;
    line-height: 0.88rem;
    text-align: center;
    vertical-align: baseline;
    margin-left: 0.3rem;
  }
  
  .orderInfoContainer .specspan.green {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #00C85E;
  }
  
  .orderInfoContainer .specspan.red {
    font-size: 0.48rem;
    font-family: Helvetica;
    color: #FF3232;
    line-height: 0.88rem;
  }
  
  .orderInfoContainer .specspan {
    font-size: 0.36rem;
  }
  
  .specspan.orderid,
  .specspan.driverid {
    font-size: 0.32rem;
    float: left;
    display: inline-block;
  }
  
  .finalAction {
    height: 1rem;
    font-size: 0.42rem;
    font-family: AppleColorEmoji;
    color: #4C4C4C;
    line-height: 0.56rem;
    margin-top: 0.4rem;
    text-align: left;
    position: relative;
  }
  
  .submitAction {
    position: absolute;
    right: 0;
    top: 0;
    width: 2.7rem;
    height: 0.88rem;
    border-radius: 0.44rem;
    background: #00C85E;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #FFFFFF;
    text-align: center;
    line-height: 0.88rem;
  }
  
  .singleFormItem {
    font-size: 0;
  }
  
  .formItemTitle {
    padding: 0.2rem 0;
    padding-top: 0.4rem;
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4C4C4C;
    line-height: 0.5rem;
    text-align: left;
  }
  
  .orderPanel .actionCommon {
    margin: 0.4rem 0;
    text-align: center;
  }
  
  .commentInfo .actionCommon {
    margin-bottom: 0.4rem;
    text-align: center;
  }
  
  .updateInfo {
    text-align: left;
    background: #F8F8F8;
    border-radius: 0.2rem;
    margin: 0.4rem 0;
    padding: 0.2rem;
  }
  
  .updateInfo .title {
    font-size: 0.48rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #005026;
    line-height: 0.56rem;
    padding: 0.2rem 0;
  }
  
  .singleUpdateInfo {
    font-size: 0.36rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #005026;
    line-height: 0.56rem;
  }
  
  .contentspan {
    display: block;
    color: #000000;
    width: 90%;
    margin: auto;
  }
  
  .actionWrap {
    width: 100%;
    margin-top: 0.4rem;
    display: flex;
    justify-content: space-between;
  }
  
  .actionWrap>a {
    width: 2.7rem;
    height: 0.88rem;
    text-align: center;
    background: #00C85E;
    font-size: 0.36rem;
    font-family: Helvetica;
    color: #FFFFFF;
    line-height: 0.88rem;
    border-radius: 0.44rem;
    text-decoration: none;
  }
  
  .creditInfo {
    position: fixed;
    z-index: 11;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    width: 7.5rem;
    height: 30%;
    max-height: 10.86rem;
    min-height: 4rem;
    padding: 0.8rem 0.1rem 0 0.1rem;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0rem -0.2rem 0.3rem 0rem rgba(45, 37, 37, 0.12);
    border-radius: 0.36rem 0.36rem 0rem 0rem;
    border: 0.02rem solid #E3E3E3;
  }
  
  .singleInfo {
    font-size: 0;
    padding-bottom: 0.6rem;
  }
  
  .singleInfoTitle {
    width: calc(100% - 5rem);
    height: 0.92rem;
    font-size: 0.42rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #D3D3D3;
    line-height: 0.46rem;
    text-align: left;
  }
  
  .singleInfoTitle.singleLine {
    line-height: 0.92rem;
  }
  
  .singleInfoTitle,
  .singleInfoInput {
    display: inline-block;
    vertical-align: middle;
  }
  
  .infoMust {
    color: #FE1C1C;
  }
  
  .creditInfo .actionCommon {
    margin-top: 0.4rem;
  }
  
  .payBtnWrap {
    display: flex;
    justify-content: space-around;
  }
  
  .later.pay {
    background: #FFCCAA;
  }
  
  .actionCommon.pay {
    width: 40%;
  }
  
  .actionCommon.tip {
    width: 40%;
    margin: auto;
    margin-top: 0.4rem;
  }
  
  .listContainer {
    height: calc(100% - 2rem);
    overflow-y: auto;
  }
  
  .tipTitle {
    font-size: 0.42rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 1rem;
  }
</style>