<template>
  <div>
    <el-dialog
      title="Add a stop"
      class="edit_dialog add_a_stop_dialog"
      :visible.sync="addAStopDialog"
      width="600px"
      center
      :close-on-click-modal="false"
      :before-close="addHandleClose">
      <div class="add_content">
        <el-form ref="form" :model="addForm" label-width="100px">
          <el-form-item label="Address">
            <div class="input_item el-input-address">
              <input type="text" id="receiverAddress" class="autocompleteFiled" placeholder=""
                v-model="addForm.address"/>
            </div>
          </el-form-item>
          <el-form-item label="Room#">
            <el-input v-model="addForm.room" placeholder="suite/floor/room/block# etc"></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Phone">
                <el-input v-model="addForm.phone" placeholder="1234567899"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Email">
                <el-input v-model="addForm.email" placeholder="email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Comment">
            <el-input v-model="addForm.comment" placeholder="e.g. gate code#, parking info"></el-input>
          </el-form-item>
          <el-form-item label="Packages list">
            <el-input :autosize="{ minRows: 2, maxRows: 6}" type="textarea" placeholder="Package A1-A10
One package per row"
             v-model="addForm.packageList"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <div class="bottom_btn">
          <el-button type="warning" @click="addHandleClose">Cancel</el-button>
          <el-button type="primary" @click="confirmAdd">Confirm</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'pcMultiAdd',
    components: {},
    props: {
      addAStopDialog: {
        default: function() {
          return false
        },
        type: Boolean
      }
    },
    data() {
      return {
        addForm: {
          address: '',
          phone: '',
          room: '',
          email: '',
          comment: '',
          size: 0,
          weight: 0,
          pickupTime: '',
          warnIndex: 0,
          groupId: 'ungrouped',
          groupName: 'ungrouped',
          groupBgIndex: -1,
          groupColor: '',
          newIndex:0,
          startIndex: 0
        },
      }
    },
    mounted() {
      // 新增弹框使用
    },
    methods: {
      addAddressSearch() {
        let _this = this;
        this.loader.load().then(() => {
          let receiverAddressEle = document.getElementById('receiverAddress');
          let autoreceiverAddress = new google.maps.places.Autocomplete(receiverAddressEle);
          autoreceiverAddress.addListener("place_changed",()=>{
            let receiverAddress = autoreceiverAddress.getPlace();
            if(!receiverAddress.geometry){
              this.$message.closeAll();
              this.$message({
                showClose: false,
                message: 'Please enter the specific address',
                type: 'warning'
              });
            }else{
              _this.addForm.address = receiverAddressEle.value;
              _this.addForm.latitude = receiverAddress.geometry.location.lat()
              _this.addForm.long = receiverAddress.geometry.location.lng()
            }
          })
        })
      },
      addHandleClose() {
        this.addForm = {
          address: '',
          phone: '',
          room: '',
          email: '',
          comment: '',
          size: 0,
          weight: 0,
          pickupTime: '',
          warnIndex: 0,
          groupId: 'ungrouped',
          groupName: 'ungrouped',
          groupBgIndex: -1,
          groupColor: '',
          newIndex:0,
          startIndex: 0
        }
        this.$emit('addHandleClose')
      },
      confirmAdd() {
        var pacList = []
        if (this.addForm.packageList) {
          pacList = this.addForm.packageList.split('\n')
        }
        var pacListArr = []
        pacList.forEach(item => {
          var str = item.replace(/\s+/g,"");   
          if (str) {
            pacListArr.push(str)
          }
        })
        this.addForm.packages_list = pacListArr
        if (!this.addForm.address) {
          this.addForm.latitude = ''
          this.addForm.long = ''
        }
        if (!this.addForm.latitude) {
          this.$message.closeAll();
          this.$message({
            showClose: false,
            message: 'Please select the address from the address list',
            type: 'warning'
          });
          return false
        }
        this.$emit('confirmAdd',this.addForm)
        this.addForm = {
          address: '',
          phone: '',
          room: '',
          email: '',
          comment: '',
          size: 0,
          weight: 0,
          pickupTime: '',
          warnIndex: 0,
          groupId: 'ungrouped',
          groupName: 'ungrouped',
          groupBgIndex: -1,
          groupColor: '',
          newIndex:0,
          startIndex: 0
        }
      }
    }
  }
</script>
<style scoped="scoped">

</style>