<template>
  <div class="deliverSummary">
    <div class="header">
      <div class="user_icon" @click="goBack"><i class="el-icon-arrow-left"></i></div>
      <p class="summaryTitle">Delivery summary</p>
    </div>
    <div class="moreOperation">
    	<div class="fromtoInfo">
    	  <div class="infoTitle">From</div>
    	  <p v-text="senderAddress.value"></p>
    	  <p v-text="senderInfo.Name.value + ' ' + senderInfo.Phone.value"></p>
    	  <p>
    	    Email:
    	    <span v-text="senderInfo.Email.value" class="innerInfo"></span>
    	  </p>
    	  <p>
          Comment:
          <span v-text="senderInfo.Comment.value" class="innerInfo"></span>
        </p>
    	  <p>
          Pickup date:
          <span v-text="getPickupDate()" class="innerInfo"></span>
        </p>
    	</div>
    	<div class="fromtoInfo">
        <div class="infoTitle">To</div>
        <p v-text="receiverAddress.value"></p>
        <p v-text="receiverInfo.Name.value + ' ' + receiverInfo.Phone.value"></p>
        <p>
          Email:
          <span v-text="receiverInfo.Email.value" class="innerInfo"></span>
        </p>
        <p>
          Comment:
          <span v-text="receiverInfo.Comment.value" class="innerInfo"></span>
        </p>
        <p>
          Deliver date:
          <span class="innerInfo">same day</span>
        </p>
      </div>
      <div class="distanceInfo">
      	<span>Distance:</span>
      	<span v-text="distance + 'miles'"></span>
      </div>
      <div class="basicInfo">
        <span>
          <span v-text="sizeInfo.title"></span>
          <cusCheckbox :checkedObj="sizeInfo"></cusCheckbox>
        </span>
        <span>
          <span v-text="weightInfo.title"></span>
          <cusCheckbox :checkedObj="weightInfo"></cusCheckbox>
        </span>
      </div>
      <div class="valueLine" v-if="valueObj.checked">
        <span>
          <span>Value > $300 ?</span>
          <cusCheckbox :checkedObj="valueObj"></cusCheckbox>
        </span>
      </div>
      <div class="ItemsInfo">
        Item list (total: {{packageInfo.formObj.count}} packages)
        <template v-for="(item,key) in packageInfo.formObj.items">
          <p v-text="item"></p>
        </template>
      </div>
      <div class="commentInfo">
        <span>Comment:</span>
        <span v-text="packageInfo.formObj.Comment" class="danger"></span>
      </div>
      <div class="driverInfo">
        Designated drivers:
        <p v-text="item.name || item.phone" v-for="item in selectedDrivers"></p>
      </div>
      <div class="feeArea">
      	<p>
      	  <span>Basic delivery fee:</span>
      	  <span v-text="'$' + (feeInfo.basic/100)" class="specspan"></span>
      	</p>
      	<p class="tipinput">
          <span>Tip($):</span>
          <el-input-number v-model="feeInfo.tip" class="specspan" :step="0.1" :min="0" ></el-input-number>
          <!--<span v-text="'$' + feeInfo.tip" class="specspan"></span>-->
        </p>
        <p>
          <span>Tax:</span>
          <span v-text="'$' + (feeInfo.tax/100)" class="specspan"></span>
        </p>
        <p>
          <span>Total:</span>
          <span v-text="'$' + (getTotal())" class="specspan"></span>
        </p>
      </div>
      <div class="finalAction">
      	<!--<p v-text="'$' + (getTotal())"></p>-->
      	<div class="submitAction" @click="startSubmit">
      		Submit
      	</div>
      </div>
    </div>
    <div class="creditInfo" v-if="showCreditInfo">
    	<div class="listContainer">
    	  <div class="sr-root">
          <div class="sr-main">
            <form id="payment-form" class="sr-payment-form">
              <div class="sr-combo-inputs-row">
                <div class="sr-input sr-card-element" id="card-element"></div>
              </div>
              <div class="sr-field-error" id="card-errors" role="alert"></div>
            </form>
          </div>
        </div>
    	  <!--<div class="singleInfo" v-for="(item,key) in creditInfo">
          <div class="singleInfoTitle" :class="item.singleLine ? 'singleLine' : ''">
            <span class="infoMust" v-if="item.must">*</span>
            <span v-text="item.title"></span>
          </div>
          <div class="singleInfoInput">
            <el-input v-model="item.value"></el-input>
          </div>
        </div>-->
        
    	</div>
    	<div class="payBtnWrap">
    	  <div class="actionCommon pay later" @click="jumpRoute('moreOrder')">Later</div>
        <div class="actionCommon pay" @click="payOrder">Pay</div>
    	</div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'deliverSummary',
    components: {cusCheckbox},
    data() {
      return {
        senderAddress:{
          title:"Address",
          must:true,
          value:"",
          position:undefined
        },
        receiverAddress:{
          title:"Address",
          must:true,
          value:"",
          position:undefined
        },
        senderInfo:{
          Address:{},
          Name:{},
          Phone:{},
          Email:{},
          Comment:{},
        },
        dateInfo:{},
        receiverInfo:{
          Address:{},
          Name:{},
          Phone:{},
          Email:{},
          Comment:{},
        },
        distance:"",
        sizeInfo:{
          title:"",
          checked:true,
          changeable:true
        },
        weightInfo:{
          title:"",
          checked:true,
          changeable:true
        },
        valueObj:{
          checked:true,
          changeable:true
        },
        packageInfo:{
          formObj:{
            count:5,
            items:["2块披萨","3瓶啤酒"],
            Comment:"易碎品"
          },
          driverObj:{},
        },
        packageInfoOri:{},
        selectedDrivers:[],
        feeInfo:{
          basic:0,
          tip:0,
          tax:0
        },
        finalPay:0,
        creditInfo:{},
        showCreditInfo:false,
        priceRule:{
        		tax:0,
        		volume:{},
        		weight:{}
        },
        good_max_value:0,
        good_max_volume:0,
        good_max_weight:0,
        currentAddedVaule:"",
        submitLock:false,
      }
    },
    mounted() {
      this.initPageInfo()
      this.calcPrice()
    },
    methods: {
	    	getTotal(){
	    		return (this.feeInfo.basic + this.feeInfo.tax + this.feeInfo.tip*100)/100
	    	},
      payThisOrder(){
        if(this.submitLock){
          return;
        }
        this.submitLock = true;
        this.showCreditInfo = true;
        // TODO
        let orderData = {
          item: this.feeInfo.basic + this.feeInfo.tax,
          tip: this.feeInfo.tip * 100,
          order_id: this.currentAddedVaule
        };
        this.$http({
          url: "/api/pay/create",
          method: "POST",
          data:orderData
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code){
            this.$message({
              message: res.data.message,
              type: 'error'
            });
            this.submitLock = false;
          }else{
            
            let payData = (res.data.data);
            let stripe = Stripe(payData.publishable_key);
            let client_secret = payData.client_secret;
            let elements = stripe.elements();
            let style = {base: {color: "#32325d",fontFamily: '"Helvetica Neue", Helvetica, sans-serif',fontSmoothing: "antialiased",fontSize: "16px","::placeholder": {color: "#aab7c4"}},invalid: {color: "#fa755a",iconColor: "#fa755a"}};
            let card = elements.create("card", { style: style });
            card.mount("#card-element");
            this.creditInfo = {
              stripe,
              card,
              client_secret
            }
            this.submitLock = false;
          }
        })
      },
      payOrder(){
        if(this.submitLock){
          return;
        }
        this.submitLock = true;
        let _this = this;
        let creditInfo = this.creditInfo
        creditInfo.stripe.confirmCardPayment(creditInfo.client_secret, {
          payment_method: {
            card: creditInfo.card
          }
        }).then(function(result) {
          _this.submitLock = false;
          _this.$message.closeAll();
          if (result.error) {
            // Show error to your customer
           _this.$message({
            message: result.error,
            type: 'error'
          });
          } else {
            if(result.paymentIntent.status == "succeeded"){
            		_this.$message({
	              message: "Payment successful!",
	              type: 'success'
	            })
              _this.jumpRoute("moreOrder")
            }
            // The payment has been processed!
            //creditInfo.stripe.retrievePaymentIntent(creditInfo.client_secret).then(function(result){
            //  console.log(result.paymentIntent)
            //})
          }
        });
      },
      initPageInfo(){
        this.senderAddress = JSON.parse(localStorage.getItem("senderAddress"));
        this.receiverAddress = JSON.parse(localStorage.getItem("receiverAddress"));
        this.senderInfo = JSON.parse(localStorage.getItem("senderInfo"));
        this.receiverInfo = JSON.parse(localStorage.getItem("receiverInfo"));
        this.dateInfo = JSON.parse(localStorage.getItem("dateInfo"));
        this.specTime = JSON.parse(localStorage.getItem("specTimeInfo"));
        let packageInfo = JSON.parse(localStorage.getItem("packageInfo"));
        this.packageInfoOri = packageInfo;
        console.log(packageInfo)
        this.sizeInfo.title = packageInfo.checkedSizeInfo;
        this.weightInfo.title = packageInfo.checkedWeightInfo;
        this.valueObj.checked = packageInfo.packageInfo.valueObj.checked;
        this.packageInfo = packageInfo.packageInfo;
        this.packageInfo.formObj.items = this.packageInfo.formObj.items.split(",");
        this.selectedDrivers = localStorage.getItem("selectedDrivers") ? JSON.parse(localStorage.getItem("selectedDrivers")) :[];
        this.distance = this.getDistance(this.senderAddress.position,this.receiverAddress.position) + "miles";
      		for (let i = 0; i < this.packageInfo.sizeInfo.length; i++) {
        		if(this.packageInfo.sizeInfo[i].checked){
        			this.good_max_volume = i;
        			break;
        		}
        }
      		for (let i = 0; i < this.packageInfo.weightInfo.length; i++) {
        		if(this.packageInfo.weightInfo[i].checked){
        			this.good_max_weight = i;
        			break;
        		}
        }
      },
      getPickupDate(){
        return `${this.dateInfo.days} ${this.dateInfo.month}/${this.dateInfo.day}/${this.dateInfo.year} ${this.specTime}`
      },
      calcPrice(){
      		this.$http({
	        url: "/api/order/price",
	        method: "GET",
	        params:{
	        		latitude:Number(this.senderAddress.position.lat),
	        		longitude:Number(this.senderAddress.position.lng),
	        		receiver_latitude:Number(this.receiverAddress.position.lat),
	        		receiver_longitude:Number(this.receiverAddress.position.lng),
	        		good_max_weight:Number(this.good_max_weight),
	        		good_max_volume:Number(this.good_max_volume)
	        }
	      }).then(res => {
	        this.$message.closeAll();
	        if(res.data.code){
	          this.$message({
	            message: res.data.message,
	            type: 'error'
	          });
	        }else{
	        		this.feeInfo.basic = res.data.data.price;
	        		this.feeInfo.tax = res.data.data.tax;
	        		this.distance = res.data.data.distance.toFixed(3);
	        }
	      })
      },
      goBack(){
        this.$router.go(-1);
      },
      startSubmit(){
        if(this.submitLock){
          return;
        }
        this.submitLock = true
        let sumbitData = this.formatData();
        this.$http({
          url: "/api/order/add",
          method: "POST",
          data: sumbitData
        }).then(res => {
          this.submitLock = false;
          this.$message.closeAll();
          if(res.data.code){
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }else{
            // "senderAddress","senderInfo"暂时不删除发货地址
            let clearInfo = ["receiverAddress","specTimeInfo","packageInfo","selectedDriverIDArr","dateInfo","receiverInfo","selectedDrivers"]
            for (let i = 0; i < clearInfo.length; i++) {
              	localStorage.removeItem(clearInfo[i])
            }
            this.jumpRoute("moreOrder");
            return;
            //暂时不进行支付
            this.currentAddedVaule = res.data.data.order_id;
            
            this.payThisOrder()
          }
        })
        //this.jumpRoute("moreOrder");
      },
      formatData(){
        let pickTime = ""
        let goods_list = [];
        if(this.specTime == "AM"){
          pickTime = `${this.dateInfo.month}/${this.dateInfo.day}/${this.dateInfo.year} 12:00`;
        }else if(this.specTime == "PM"){
          pickTime = `${this.dateInfo.month}/${this.dateInfo.day}/${this.dateInfo.year} 21:00`;
        }
        this.packageInfo.formObj.items.map(function(item){
          goods_list.push({
            goods_name:item
          })
        })
        let  drivers = [];
        for (let i = 0; i < this.selectedDrivers.length; i++) {
        	drivers.push(this.selectedDrivers[i].driver_id)
        }
        console.log(goods_list);
        let tempData = {
          sender_email:this.senderInfo.Email.value,
          sender_address:this.senderAddress.value,
          sender_name:this.senderInfo.Name.value,
          sender_phone:this.senderInfo.Phone.value,
          sender_room:this.senderInfo.Room.value,
          remarks_sender:this.senderInfo.Comment.value,
          order_time:Math.floor((new Date()).getTime()/1000),
          pick_time:Math.floor((new Date(pickTime)).getTime()/1000), // TODO
          expected_time:Math.floor((new Date()).getTime()/1000),
          real_recv_time:Math.floor((new Date()).getTime()/1000),
          real_get_time:Math.floor((new Date()).getTime()/1000),
          real_receiver_address:"",
          delivery_method:1,  // TODO
          receiver_address:this.receiverAddress.value,
          receiver_name:this.receiverInfo.Name.value,
          receiver_phone:this.receiverInfo.Phone.value,
          receiver_room:this.receiverInfo.Room.value,
          receiver_email:this.receiverInfo.Email.value,
          remarks_receiver:this.receiverInfo.Comment.value,
          sender_id:JSON.parse(localStorage.getItem("loginInfo")).user_id,
          recv_comment:this.packageInfo.formObj.Comment,
          cost:this.feeInfo.basic,
          tip:this.feeInfo.tip * 100,
          status:0,
          route:"", 
          session_id:"",
          payment_account:"",
          payment_card:"",
          longitude:this.senderAddress.position.lng,
          latitude:this.senderAddress.position.lat,
          receiver_longitude:this.receiverAddress.position.lng,
          receiver_latitude:this.receiverAddress.position.lat,
          goods_list:goods_list,
          comment_list:[],
          message_list:[],
          driver_path_info_list:[],
          sender_list:[],
          receiver_list:[],
          driver_id:"",
          driver_id_list:drivers.join(","),
          good_max_value:this.good_max_value,
          good_max_volume:this.good_max_volume,
          good_max_weight:this.good_max_weight
        }
        return tempData;
        
      },
      jumpRoute(path){
        this.$router.push({
          path: "/" + path,
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      }
    }
  }
</script>
<style scoped="scoped">
.deliverSummary{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.summaryTitle{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #000000;
  line-height: 1.4rem;
}
.moreOperation{
  width: 7.5rem;
  height: calc(100% - 1.4rem);
  margin: auto;
  position: relative;
  top: 1.4rem;
  box-sizing: border-box;
  padding: 0.4rem 0.2rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.fromtoInfo{
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3D3D3;
  line-height: 0.56rem;
  text-align: left;
  margin-bottom: 0.8rem;
}
.infoTitle{
  width: 1.12rem;
  height: 0.58rem;
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #4C4C4C;
  line-height: 0.58rem;
  margin-bottom: 0.24rem;
}
.fromtoInfo>p{
  margin-bottom: 0.24rem;
}
.innerInfo{
  display: block;
}
.basicInfo{
  font-size: 0;
  text-align: left;
}
.basicInfo>span{
  display: inline-block;
  font-size: 0.48rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4C4C4C;
  line-height: 1.04rem;
  padding-right: 0.6rem;
  position: relative;
  margin-right: 0.1rem;
}
.basicInfo>span>.cusCheckbox{
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.06rem;
}
.valueLine{
  width: 7.1rem;
  height: 0.66rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4C4C4C;
  line-height: 0.66rem;
  text-align: left;
}
.valueLine>span{
  position: relative;
  padding-right: 0.6rem;
}
.valueLine .cusCheckbox{
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.06rem;
}
.distanceInfo,.ItemsInfo,.commentInfo,.driverInfo{
  font-size: 0.48rem;
  font-family: Helvetica;
  color: #4C4C4C;
  line-height: 0.56rem;
  text-align: left;
}
.distanceInfo>span,.commentInfo>span{
  margin-right: 0.6rem;
}
.ItemsInfo,.commentInfo,.driverInfo{
  margin: 0.6rem 0;
}
.danger{
  color: #FF3232;
}
.feeArea{
  height: 4.4rem;
  background: #F7F7F7;
  font-size: 0.42rem;
  font-family: Helvetica;
  color: #4C4C4C;
  line-height: 1.1rem;
  text-align: left;
}
.specspan{
  float: right;
}
.finalAction{
  height: 1rem;
  font-size: 0.42rem;
  color: #4C4C4C;
  line-height: 0.56rem;
  margin-top: 0.4rem;
  text-align: left;
  position: relative;
}
.finalAction p{
	padding: 0.2rem 0;
	line-height: 0.5rem;
}
.submitAction{
  position: absolute;
  right: 0;
  top: 0;
  width: 2.7rem;
  height: 0.88rem;
  border-radius: 0.44rem;
  background:#00C85E;
  font-size: 0.36rem;
  font-family: Helvetica;
  color: #FFFFFF;
  text-align: center;
  line-height: 0.88rem;
}
.creditInfo{
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  width: 7.5rem;
  height: 30%;
  max-height: 10.86rem;
  padding: 0.8rem 0.1rem 0 0.1rem;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0rem -0.2rem 0.3rem 0rem rgba(45, 37, 37, 0.12);
  border-radius: 0.36rem 0.36rem 0rem 0rem;
  border: 0.02rem solid #E3E3E3;
}
.singleInfo{
  font-size: 0;
  padding-bottom: 0.6rem;
}
.singleInfoTitle{
  width: calc(100% - 5rem);
  height: 0.92rem;
  
  
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3D3D3;
  line-height: 0.46rem;
  text-align: left;
}
.singleInfoTitle.singleLine{
  line-height: 0.92rem;
}
.singleInfoTitle,.singleInfoInput{
  display: inline-block;
  vertical-align: middle;
}
.infoMust{
  color: #FE1C1C;
}
.creditInfo .actionCommon{
  margin-top: 0.4rem;
}
.payBtnWrap{
  display: flex;
  justify-content: space-around;
}
.later.pay{
  background: #FFCCAA;
}
.actionCommon.pay{
  width: 40%;
}
.listContainer{
  height: calc(100% - 2rem);
  min-height: 0.5rem;
  overflow-y: auto;
}
</style>