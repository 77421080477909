<template>
  <div class="chooseDeliveryman">
    <div class="header">
      <div class="searchContainer">
        <!--<el-input v-model="driverName" placeholder="Input driver's id"></el-input>-->
        <el-autocomplete
				  v-model="driverName"
				  :fetch-suggestions="getSpecDriver"
				  placeholder="Input driver's id"
				  @select="handleSelect"
				></el-autocomplete>
        <el-button @click="pushItemToUsed">
          Choose
        </el-button>
      </div>
    </div>
    <div class="listWrap">
      <div class="driverList">
        <div v-for="item in driverList" class="singleList">
          <p class="driverName" v-text="getListcontent(item)">
          </p>
          <cusCheckbox :checkedObj="item"></cusCheckbox>
          
        </div>
      </div>
      <p class="recentlyTitle">Recently used</p>
        <div class="recentlyList">
          <div v-for="item in recentlyList" class="singleList">
            <p class="driverName" v-text="getListcontent(item)">
            </p>
            <cusCheckbox :checkedObj="item"></cusCheckbox>
            
          </div>
        </div>
        <div class="actionCommon" @click="dirverListDeal">Continue</div>
      </div>
    </div>
  </div>
</template>

<script>
  import cusCheckbox from "@/components/cusCheckbox.vue"
  // @ is an alias to /src
  export default {
    name: 'chooseDeliveryman',
    components: {cusCheckbox},
    data() {
      return {
        driverName: "",
        driverList:[],
        recentlyList:[],
        tempIDArr:[],
        selectDriver:{}
      }
    },
    mounted() {
      if(localStorage.getItem("selectedDriverIDArr")){
        this.tempIDArr = JSON.parse(localStorage.getItem("selectedDriverIDArr"))        
      }
      this.getDriverList();
      this.getDriverUsedList();
    },
    methods: {
    		getSpecDriver(queryString, cb){
    			if(!queryString){
    				cb([])
    				return;
    			}
        this.$http({
          url: "/api/driver/get",
          method: "GET",
          params: {
          		id:queryString
          }
        }).then(res => {
          if(res.data.code){
          		console.error(res.data.message)
          		cb([])
          }else{
          	console.log([{value:`${res.data.data.id}-${res.data.data.phone}`,source:res.data.data}])
          		cb([{value:`${res.data.data.id}-(${res.data.data.phone})`,source:res.data.data}])
          }
        })
      },
      pushItemToUsed(){
      		let tempId =  this.selectDriver.id;
      		if(!tempId){
      		  return;
      		}
      		let find = false;
      		this.recentlyList.map(function(item){
      			if(tempId == item.id){
      				item.checked = true;
      				find = true;
      			}
      		})
      		if(!find){
      			this.selectDriver.checked = true;
      			this.recentlyList.push(this.selectDriver)      			
      		}
      },
    		handleSelect(item){
    			this.selectDriver = item.source;
    		},
      dirverListDeal(){
        let checkedDriver = [];
        let checkedDriverIDArr = []
        this.driverList.map(function(item){
          if(checkedDriverIDArr.indexOf(item.id) < 0 && item.checked){
            console.log(62)
            checkedDriverIDArr.push(item.id)
            checkedDriver.push(item);
          }
        })
        this.recentlyList.map(function(item){
          if(checkedDriverIDArr.indexOf(item.id) < 0 && item.checked){
            console.log(69)
            checkedDriverIDArr.push(item.id)
            checkedDriver.push(item);
          }
        })
        localStorage.setItem("selectedDrivers",JSON.stringify(checkedDriver));
        localStorage.setItem("selectedDriverIDArr",JSON.stringify(checkedDriverIDArr));
        
        this.jumpRoute('deliverSummary')
      },
      getListcontent(item){
        return `${item.id}-${item.first_name} ${item.last_name}(${item.phone})`
      },
      getDriverList(){
        this.$http({
          url: "/api/driver/list",
          method: "GET",
          params: {}
        }).then(res => {
          if(res.data.code){
            this.$message.closeAll();
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }else{
            console.log(res.data.data.list)
            this.driverList = res.data.data.list.map((item)=>{
              if(this.tempIDArr.indexOf(item.id)>-1){
                item.checked = true
              }else{
                item.checked = false
              }
              return item
            });
          }
        })
      },
      getDriverUsedList(){
        this.$http({
          url: "/api/order/often/driver",
          method: "GET",
          params: {
            sender_id:JSON.parse(localStorage.getItem("loginInfo")).user_id,
          }
        }).then(res => {
          this.$message.closeAll();
          if(res.data.code){
            this.$message({
              message: res.data.message,
              type: 'error'
            });
            
          }else{
            this.recentlyList = res.data.data.map((item)=>{
              if(this.tempIDArr.indexOf(item.id)>-1){
                item.checked = true
              }else{
                item.checked = false
              }
              return item
            });
          }
        })
      },
      changeChecked(item){
        item.checked = !item.checked;
      },
      jumpRoute(path){
        this.$router.push({
          path: "/" + path,
          name,
          params:{
            info: "null"
          }
        }).catch(err => {err})
      }
    }
  }
</script>
<style scoped="scoped">
.chooseDeliveryman{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.searchContainer{
  height: 0.9rem;
  font-size: 0;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.searchContainer>.el-button{
  width: 2.2rem;
  height: 0.88rem;
  border-radius: 0.44rem;
  background: #00C85E;
  font-size: 0.36rem;
  font-family: Helvetica;
  color: #FFFFFF;
  margin-left: 0.16rem;
}
.listWrap{
  position: relative;
  top: 1.4rem;
  height: calc(100% - 1.4rem);
}
.listWrap>div{
  width: 6.7rem;
  position: relative;
  left: 0;
  right: 0;
  margin: auto;
}
.driverList{
  border-bottom: 0.02rem solid #F0F0F0;
}
.driverList,.recentlyList{
  overflow-x: hidden;
  overflow-y: auto;
  height: 35%;
}
.recentlyTitle{
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #D3D3D3;
  line-height: 0.5rem;
  text-align: left;
  padding: 0.2rem 0 0.2rem 0.4rem;
  width: 7rem;
  margin: auto;
}
.driverName{
  text-align: left;
  font-size: 0.42rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 0.58rem;
  padding: 0.3rem 0;
  width: calc(100% - 1rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.singleList{
  position: relative;
}
.cusCheckbox{
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>